import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {useEffect, useRef} from "react";
import {cn} from "@/utils/cn";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuItemCheck,
    DropdownTrigger
} from "@/components/ui/dropdown/dropdown";
import {flexRender, Header, HeaderGroup, RowData} from "@tanstack/react-table";
import {ExtendedColumnDef} from "@/components/ui/table/table";


const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type SearchBarDropdownProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dropdownVariants> & {
    headerGroups: HeaderGroup<RowData>[];
    searchColumn: string;
    setSearchColumn: (column: string) => void;
}

const MenuItemContent = ({
    header,
    searchColumn,
}: {
    header: Header<any, any>;
    searchColumn: string,
}) => {
    return (
        <span className={"flex items-center w-full gap-2"}>
            <span className={"flex-1 text-start text-Base-white text-sm"}>
                {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                )}
            </span>
            {
                header.column.columnDef.header === searchColumn ? <DropdownMenuItemCheck/> : null
            }
        </span>
    );
}

const SearchBarDropdown = React.forwardRef<HTMLDivElement, SearchBarDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            headerGroups,
            searchColumn,
            setSearchColumn,
            ...props
        },
        ref
    ) => {
        const [isOpen, setOpen] = React.useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const handleSelect = (event: any) => {
            setSearchColumn(event.target.value);
        }

        const toggleDropdown = () => {
            setOpen(!isOpen);
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <DropdownMenu ref={dropdownRef} className={cn("", { variant, size, className })} {...props}>
                <DropdownTrigger
                    aria-expanded={isOpen}
                    onClick={toggleDropdown}
                    isOpen={isOpen}
                >
                    <span className={"overflow-hidden text-Grey-300 text-ellipsis text-sm leading-[18px]"}>
                        {searchColumn}
                    </span>
                </DropdownTrigger>

                {isOpen ? (
                    <DropdownMenuContent className={"flex items-start absolute overflow-y-auto w-[320px] h-[176px] left-0 top-full mt-[3px] rounded-lg border-[1px] border-Grey-800 bg-Grey-900 shadow-lg"}>
                        <div className={"flex flex-col items-start py-1 flex-1"}>
                            {headerGroups.map((headerGroup) => (
                                headerGroup.headers
                                    .filter((header) => (header.column.columnDef as ExtendedColumnDef<any>).searchable)
                                    .map((header, i) => (
                                        <DropdownMenuItem
                                            key={"dropdown-menuitem-" + header.column.columnDef.header}
                                            value={header.column.columnDef.header as string}
                                            onClick={handleSelect}
                                        >
                                            <MenuItemContent header={header} searchColumn={searchColumn}/>
                                        </DropdownMenuItem>
                                    ))
                            ))}
                        </div>
                    </DropdownMenuContent>
                    ) : null
                }
            </DropdownMenu>
        );
    }
);

export default SearchBarDropdown;