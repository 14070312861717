import * as React from "react";

import {Range} from "react-date-range";

import {useSalesChart} from "@/features/sales/api/get-sales-chart";
import {TimeSeriesLineChart} from "@/components/ui/chart/recharts/line/line";
import {FormatType, formatValue} from "@/utils/formatter";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {ChartSupplementaryFields, NoDataGraphic} from "@/components/ui/chart/recharts/recharts";
import {colors} from "@/styles/colors";
import {useBrand} from "@/app/providers/brand";
import {lineMock} from "@/components/ui/chart/mock";
import {salesLine} from "@/content/chart/no-data";


export const SalesChart = ({
    dateRange,
    hidePeriod = false,
    height = "18.5rem",
}: {
    dateRange: Range[];
    hidePeriod?: boolean;
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const salesChartQuery = useSalesChart({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (salesChartQuery.isLoading) {
        return <div>Sales Chart Loading...</div>;
    }

    if (!salesChartQuery.data) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "sales",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "units",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Integer,
        },
    ];

    if (salesChartQuery.data.length === 0) return (
        <TimeSeriesLineChart
            title={"Sales Insight"}
            labels={labels}
            series={lineMock}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
            noDataGraphic={<NoDataGraphic content={salesLine} />}
        />
    );

    // rename data fields if applicable
    const series = salesChartQuery.data;

    const totalSales = series.reduce((sum, serie) => sum + serie.sales, 0);
    const averageSales = totalSales / series.length;

    return (
        // <MultipleAxisTimeValueLineChart
        //     title={"Sales Summary"}
        //     labels={[
        //         { name: "Sales", position: "left" },
        //         { name: "Units", position: "right" },
        //     ]}
        //     series={[
        //         salesChartQuery.data.map(row => [row.date, row.sales]),
        //         salesChartQuery.data.map(row => [row.date, row.units]),
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <TimeSeriesLineChart
            title={"Sales Insight"}
            labels={labels}
            series={series}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
            chartSupplementaryFields={(
                <ChartSupplementaryFields
                    mainKPI={formatValue(totalSales, FormatType.Currency)}
                    subtitle={"Average Sales"}
                    value={formatValue(averageSales, FormatType.Currency)}
                />
            )}
        />
    );
}