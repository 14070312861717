import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";


export const getAdvertisingAccess = ({
    brand,
}: {
    brand: string;
}): Promise<"noAdAccess" | "fullDataAccess"> => {
    return api.get(`/advertising/access`, {
        params: {
            brand,
        }
    });
}

export const getAdvertisingAccessQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["advertising", "access", brand],
        queryFn: () => getAdvertisingAccess({ brand }),
    })
}

type UseAdvertisingAccessQueryOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getAdvertisingAccessQueryOptions>;
}

export const useAdvertisingAccess = ({
    brand,
    queryConfig,
}: UseAdvertisingAccessQueryOptions) => {
    return useQuery({
        ...getAdvertisingAccessQueryOptions({ brand }),
        ...queryConfig,
    })
}