import * as React from "react";
import {useEffect, useState} from "react";

import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import {activePosProps, RechartsProps} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {ChartTitle, CustomTooltip, legendBox, numberInThousandsF,} from "@/components/ui/chart/recharts/recharts";
import LineInsightsIcon from "@/assets/icons/graph/line-insights.svg";
import {FormatType, formatValue} from "@/utils/formatter";


const LineTooltipHighlightCursor = (props: any) => {
    const { points, top, height, payload } = props;

    const x = points[0].x;
    const rectWidth = 27;
    const topMargin = 17 * 4;
    const yOffset = -5;

    return (
        <>
            <defs>
                <linearGradient id={"tooltipCursorGradientBackground"} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="rgba(0, 0, 0, 0.00)"/>
                    <stop offset="100%" stopColor="rgba(140, 242, 34, 0.10)"/>
                </linearGradient>
            </defs>

            <line
                x1={x}
                y1={top}
                x2={x}
                y2={top + height}
                stroke={colors.primary["500"]}
                strokeWidth={1}
                strokeDasharray="3 3"
                opacity={0.2}
            />
            <rect
                x={x - rectWidth / 2}
                y={top}
                width={rectWidth}
                height={height}
                fill={"url(#tooltipCursorGradientBackground)"}
            />
            <text
                x={x}
                y={height + topMargin + yOffset}
                textAnchor={"middle"}
                fill={colors.base.white}
                fontSize={12}
            >
                {formatValue(new Date(payload[0].payload.date), FormatType.ShortDate)}
            </text>
        </>
    );
}

const CustomActiveDot = (props: any) => {
    const { cx, cy, dataKey, fill, setActiveDotPos } = props;

    useEffect(() => {
        setActiveDotPos((prev: activePosProps) => ({
            ...prev,
            [dataKey]: { x: Math.ceil(cx), y: Math.ceil(cy) }
        }))
    }, []);

    return (
        <g>
            <circle
                cx={cx}
                cy={cy}
                r={4}
                stroke={fill}
                strokeWidth={2}
                fill={colors.grey["950"]}
            />
        </g>
    );
}

const TimeSeriesLineChart = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & RechartsProps>(
    (
        {
            className,
            children,
            title,
            labels,
            series,
            noDataGraphic,
            period,
            chartSupplementaryFields,
        },
        ref
    ) => {
        const [activeDotPos, setActiveDotPos] = useState<activePosProps>();

        return (
            <div className={"relative flex flex-col h-full justify-between flex-1 p-7 bg-Grey-950 rounded-[14px]"}>
                <div className={"flex flex-col gap-y-5"}>
                    <ChartTitle icon={LineInsightsIcon} title={title} period={period} />
                    {
                        chartSupplementaryFields ?? (
                            <div className={"h-5"}></div>
                        )
                    }
                </div>
                {noDataGraphic}
                <ResponsiveContainer
                    width={"100%"}
                    height={266}
                    className={noDataGraphic ? "blur pointer-events-none select-none" : ""}
                >
                    <LineChart
                        data={series}
                        margin={{top: 17, right: -15, left: -10, bottom: 10}}
                    >
                        <CartesianGrid
                            horizontal={true}
                            vertical={false}
                            strokeDasharray={"3 3"}
                            stroke={colors.grey["900"]}
                        />
                        <XAxis
                            dataKey={"date"}
                            axisLine={false}
                            tickLine={false}
                            tickMargin={24}
                            tickFormatter={(date) => formatValue(new Date(date), FormatType.ShortDate)}
                            tick={{
                                fill: colors.grey["400"],
                                fontSize: 12,
                            }}
                        />
                        {labels?.map((label, index) => (
                            label.yAxisId ? (
                                <YAxis
                                    key={`yAxis-${index}`}
                                    yAxisId={label.yAxisId}
                                    orientation={label.yAxisId.includes("right") ? "right" : "left"}
                                    axisLine={false}
                                    tickLine={false}
                                    tickMargin={10}
                                    tickFormatter={(value) => numberInThousandsF(value)}
                                    tick={{
                                        fill: colors.grey["400"],
                                        fontSize: 12,
                                    }}
                                    hide={label.yAxisHide}
                                />
                            ) : null
                        ))}
                        <Legend
                            align={"right"}
                            verticalAlign={"top"}
                            iconType={"circle"}
                            iconSize={5}
                            formatter={(label: string) => legendBox(label)}
                            wrapperStyle={{
                                marginTop: -39,
                                marginRight: 15,
                            }}
                        />
                        <Tooltip
                            content={
                                <CustomTooltip
                                    labels={labels}
                                    activePos={activeDotPos}
                                />
                            }
                            wrapperStyle={{ outline: "none", padding: 0, margin: 0, height: "100%" }}
                            contentStyle={{ padding: 0, margin: 0 }}
                            labelStyle={{ padding: 0, margin: 0 }}
                            position={{ x: Object.values(activeDotPos || {})[0]?.x, y: 0 }}
                            cursor={<LineTooltipHighlightCursor />}
                        />
                        {labels?.map((label, index) => {
                            return (
                                <Line
                                    key={`line-${index}`}
                                    {...(label.yAxisId && { yAxisId: label.yAxisId })}
                                    type="monotone"
                                    stroke={label.color}
                                    dataKey={label.name}
                                    name={label.name}
                                    dot={false}
                                    activeDot={<CustomActiveDot setActiveDotPos={setActiveDotPos} />}
                                />
                            );
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
);

export {
    TimeSeriesLineChart
};
