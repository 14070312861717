import * as React from "react";
import {useEffect, useState} from "react";

import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import {activePosProps, RechartsProps} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {
    ChartTitle,
    CustomTooltip,
    legendBox,
    NoDataGraphic,
    numberInThousandsF
} from "@/components/ui/chart/recharts/recharts";
import {FormatType, formatValue} from "@/utils/formatter";
import BarSummaryIcon from "@/assets/icons/graph/bar-summary.svg";


const getPath = ({
    x,
    y,
    width,
    height,
    radius,
}: {
    x: number;
    y: number;
    width: number;
    height: number;
    radius: number;
}) => {
    return (`
        M${x},${y + radius}
        C${x},${y + radius} ${x},${y} ${x + radius},${y} 
        H${x + width - radius} 
        C${x + width - radius},${y} ${x + width},${y} ${x + width},${y + radius} 
        V${y + height}
        H${x}
        V${y + radius}
        Z
    `);
};

const getOverlayPath = ({
    x,
    y,
    width,
    height,
    radius,
}: {
    x: number;
    y: number;
    width: number;
    height: number;
    radius: number;
}) => {
    const offsetX = radius - radius * Math.sqrt(0.75);
    const startX = x + offsetX;
    const endX = x + width - offsetX;
    const startY = y + radius / 2;

    // Control point should be on the tangent line at 30° angle
    const controlScale = 0.552285;
    const controlOffsetX = + (controlScale * radius * 0.5); // cos(60°) = 0.5
    const controlY = startY - (controlScale * radius * 0.866); // sin(60°) = 0.866

    return (`
        M${startX},${startY}
        C${startX},${startY} ${startX + controlOffsetX},${controlY} ${x + radius},${y} 
        H${x + width - radius} 
        C${x + width - radius},${y} ${endX - controlOffsetX},${controlY} ${endX},${y + radius / 2} 
        H${startX}
        Z
    `);
};

const BarGroupIndicator = (props: any) => {
    const {fill, x, y, width, height, radius, isActive, index} = props;

    const lineOffset = 2;

    return (
        <g>
            {
                index === 0 && (
                    <line
                        x1={x - lineOffset}
                        y1={y + height}
                        x2={x}
                        y2={y + height}
                        strokeWidth={1}
                        stroke={isActive ? colors.grey["100"] : colors.grey["900"]}
                    />
                )
            }
            <line
                x1={x - lineOffset}
                y1={y + height}
                x2={x + width + lineOffset}
                y2={y + height}
                strokeWidth={1}
                stroke={isActive ? colors.grey["100"] : colors.grey["900"]}
            />
        </g>
    );
}


const CustomBar = (props: any) => {
    const {fill, x, y, width, height, dataKey, isActive, index, setActiveBarPos} = props;

    const radius = 4;
    const stripeSpace = 3;

    useEffect(() => {
        if (isActive) {
            setActiveBarPos((prev: activePosProps) => ({
                ...prev,
                [dataKey]: { x: Math.ceil(x), y: Math.ceil(y) + (height > 20 ? 20 : 0), offsetX: index === 0 ? 0 : width }
            }))
        }
    }, []);

    return (
        <g>
            <defs>
                {/* Bar Fill */}
                <linearGradient id={"barGraphPrimaryGradient"} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="3%" stopColor={colors.primary["500"]} stopOpacity="1"/>
                    <stop offset="15%" stopColor={colors.primary["500"]} stopOpacity="0.88"/>
                    <stop offset="100%" stopColor={colors.primary["500"]} stopOpacity="0.0"/>
                </linearGradient>
                <linearGradient id={"barGraphSecondaryGradient"} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="3%" stopColor={colors.secondary["500"]} stopOpacity="1"/>
                    <stop offset="15%" stopColor={colors.secondary["500"]} stopOpacity="0.88"/>
                    <stop offset="100%" stopColor={colors.secondary["500"]} stopOpacity="0.0"/>
                </linearGradient>
                {/* Active Bar Pattern Fill */}
                <pattern
                    id={"activeBarPrimaryPattern"}
                    width={stripeSpace}
                    height={stripeSpace}
                    patternUnits={"userSpaceOnUse"}
                    patternTransform={"rotate(30)"}
                >
                    {/* Background color */}
                    <rect x="0" y="0" width="100%" height="100%" fill={colors.primary["500"]}/>
                    {/* Pattern Stripes */}
                    <path d={`M 0 0 L 0 ${stripeSpace}`} stroke={colors.base.black} opacity={0.2} strokeWidth={2}/>
                </pattern>
                <pattern
                    id={"activeBarSecondaryPattern"}
                    width={stripeSpace}
                    height={stripeSpace}
                    patternUnits={"userSpaceOnUse"}
                    patternTransform={"rotate(30)"}
                >
                    {/* Background color */}
                    <rect x="0" y="0" width="100%" height="100%" fill={colors.secondary["500"]}/>
                    {/* Pattern Stripes */}
                    <path d={`M 0 0 L 0 ${stripeSpace}`} stroke={colors.base.black} opacity={0.2} strokeWidth={2}/>
                </pattern>
            </defs>

            {
                height > 0 && (
                    <>
                        {/* Main Bar */}
                        <path
                            d={getPath({x, y, width, height, radius})}
                            stroke="none"
                            {...isActive ?
                                {
                                    fill: fill === colors.primary["500"] ? "url(#activeBarPrimaryPattern)" : "url(#activeBarSecondaryPattern)",
                                } : {
                                    fill: fill === colors.primary["500"] ? "url(#barGraphPrimaryGradient)" : "url(#barGraphSecondaryGradient)",
                                    opacity: 0.4,
                                }
                            }
                        />
                        {/* Top Overlay */}
                        <path
                            d={getOverlayPath({x, y, width, height, radius})}
                            stroke="none"
                            fill={fill}
                        />
                    </>
                )
            }

            {/* Bar Group Indicator */}
            <BarGroupIndicator {...props} />
        </g>
    );
}

export const MonthlySummaryBarChart = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & RechartsProps>(
    (
        {
            className,
            children,
            title,
            labels,
            series,
            noDataGraphic,
            period,
            chartSupplementaryFields,
        },
        ref
    ) => {
        const [activeBarPos, setActiveBarPos] = useState<activePosProps>();

        return (
            <div className={"relative flex flex-col h-full justify-between flex-1 p-7 bg-Grey-950 rounded-[14px]"}>
                <div className={"flex flex-col gap-y-5"}>
                    <ChartTitle icon={BarSummaryIcon} title={title} period={period}/>
                    {
                        chartSupplementaryFields ?? (
                            <div className={"h-5"}></div>
                        )
                    }
                </div>
                {noDataGraphic}
                <ResponsiveContainer
                    width={"100%"}
                    height={266}
                    className={noDataGraphic ? "blur pointer-events-none select-none" : ""}
                >
                    <BarChart
                        data={series}
                        margin={{top: 17, right: -15, left: -15, bottom: 10}}
                    >
                        <CartesianGrid
                            horizontal={true}
                            vertical={false}
                            strokeDasharray={"3 3"}
                            stroke={colors.grey["900"]}
                        />
                        <XAxis
                            dataKey={"date"}
                            axisLine={false}
                            tickLine={false}
                            tickMargin={24}
                            tickFormatter={(date) => formatValue(new Date(date), FormatType.ShortMonth)}
                            tick={{
                                fill: colors.grey["400"],
                                fontSize: 12,
                            }}
                        />
                        {labels?.map((label, index) => (
                            label.yAxisId ? (
                                <YAxis
                                    key={`yAxis-${index}`}
                                    yAxisId={label.yAxisId}
                                    orientation={label.yAxisId.includes("right") ? "right" : "left"}
                                    axisLine={false}
                                    tickLine={false}
                                    tickMargin={10}
                                    tickFormatter={(value) => numberInThousandsF(value)}
                                    tick={{
                                        fill: colors.grey["400"],
                                        fontSize: 12,
                                    }}
                                    hide={label.yAxisHide}
                                />
                            ) : null
                        ))}
                        <Legend
                            align={"right"}
                            verticalAlign={"top"}
                            iconType={"circle"}
                            iconSize={5}
                            formatter={(label: string) => legendBox(label)}
                            wrapperStyle={{
                                marginTop: -39,
                                marginRight: 15,
                            }}
                        />
                        <Tooltip
                            content={<CustomTooltip labels={labels} activePos={activeBarPos}/>}
                            cursor={false}
                        />
                        {labels?.map((label, index) => {
                            return (
                                <Bar
                                    animationDuration={30}
                                    key={`line-${index}`}
                                    {...(label.yAxisId && {yAxisId: label.yAxisId})}
                                    {...(label.stackId && {stackId: label.stackId})}
                                    type="monotone"
                                    fill={label.color}
                                    dataKey={label.name}
                                    name={label.name}
                                    shape={<CustomBar index={index}/>}
                                    activeBar={<CustomBar index={index} isActive={true}
                                                          setActiveBarPos={setActiveBarPos}/>}
                                />
                            );
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
);