import {queryOptions, useQuery} from "@tanstack/react-query";

import {CardResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getSales = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<CardResponse> => {
    return api.get(`/sales`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getSalesQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["sales", brand, period, startDate, endDate],
        queryFn: () => getSales({ brand, period, startDate, endDate }),
    })
}

type UseSalesOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getSalesQueryOptions>;
}

export const useSales = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig
}: UseSalesOptions) => {
    return useQuery({
        ...getSalesQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}