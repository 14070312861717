import React from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

import {cva, VariantProps} from "class-variance-authority";

import {cn} from "@/utils/cn";
import {colors} from "@/styles/colors";
import {DropdownArrowIconDown} from "@/components/ui/dropdown/dropdown";
import {toastInfo} from "@/utils/error-maps";
import {adBlockAccess, purchaseBlockAccess} from "@/content/page/block-access";


const navigationMenuVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: "",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

type navigationProp = {
    name: string,
    to: string,
    noAccess?: boolean,
}

type MenuProps = navigationProp & {
    subTabs?: navigationProp[]
}

export type NavigationMenuProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof navigationMenuVariants> & {
    menu: MenuProps[]
};

const iconMapper = ({
    tabName,
    isActive,
    isDisabled,
}: {
    tabName: string;
    isActive: boolean;
    isDisabled?: boolean;
}) => {
    switch (tabName) {
        case "Dashboard":
            return <DashboardIcon isActive={isActive} />
        case "Sales":
            return <SalesIcon isActive={isActive} />
        case "Products":
            return <ProductsIcon isActive={isActive} />
        case "Advertising":
            return <AdvertisingIcon isActive={isActive} isDisabled={isDisabled} />
        case "Analytics":
            return <AnalyticsIcon isActive={isActive} />
        case "Settings":
            return <SettingsIcon isActive={isActive} />
        case "Support":
            return <SupportIcon isActive={isActive} />
        default:
            return null;
    }
}

const TabNavigator = ({tab} : {tab: MenuProps}) => {
    return (
        <NavLink
            key={tab.name}
            to={tab.noAccess ? "#": tab.to}
            onClick={(e) => {
                if (tab.noAccess) {
                    e.preventDefault();
                    toastInfo(adBlockAccess);
                }
            }}
            end
            className={({ isActive}) => cn(
                "flex flex-col items-start self-stretch gap-2.5",
                isActive && "py-1.5",
            )}
        >
            {({isActive}) => (
                <div className={cn(
                    "flex flex-col items-start gap-2.5 self-stretch px-3.5 py-2.5 rounded-[10px]",
                    tab.noAccess
                        ? "py-3 bg-Grey-900 cursor-not-allowed"
                        : isActive && "py-3 bg-Secondary-500",
                )}>
                    <div className={"flex h-7 items-center gap-3 self-stretch"}>
                        {iconMapper({tabName: tab.name, isActive, isDisabled: tab.noAccess ?? false})}
                        <span className={cn(
                            "text-Grey-400 text-base leading-[20px]",
                            tab.noAccess
                            ? "text-Grey-100"
                            : isActive && "text-Grey-950",
                        )}>
                            {tab.name}
                        </span>
                    </div>
                </div>
            )}
        </NavLink>
    );
}

const SubTabNavigator = ({tab}: {tab: MenuProps}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const initialActive = tab.subTabs?.some((subTab) =>
        location.pathname.includes(subTab.to.replace('./', '/app/'))
    );

    const [isOpen, setIsOpen] = React.useState<boolean>(initialActive || false);

    const toggleSubTabs = (event: React.MouseEvent) => {
        if (tab.subTabs) {
            event.preventDefault();
            setIsOpen(!isOpen);
        } else {
            navigate(tab.to);
        }
    }

    return (
        <div className={"flex flex-col items-start self-stretch gap-[18px] px-3.5 py-2.5 rounded-[10px]"}>
            <NavLink
                key={tab.name}
                to={tab.to}
                end
                className={cn(
                    "flex justify-between items-center self-stretch h-7",
                    isOpen && "",
                )}
                onClick={toggleSubTabs}
            >
                <div className={"flex items-center gap-3"}>
                    {iconMapper({tabName: tab.name, isActive: isOpen})}
                    <span className={"text-Grey-400 text-base leading-[20px]"}>{tab.name}</span>
                </div>
                <div className={"flex items-center p-2"}>
                    <DropdownArrowIconDown isActive={isOpen} />
                </div>
            </NavLink>
            {tab.subTabs && isOpen && (
                <div className={"flex flex-col items-start gap-3.5"}>
                    {tab.subTabs.map((subTab, index) => (
                        <NavLink
                            key={subTab.name}
                            to={subTab.noAccess ? "#": subTab.to}
                            onClick={(e) => {
                                if (subTab.noAccess) {
                                    e.preventDefault();
                                    toastInfo(purchaseBlockAccess);
                                }
                            }}
                            end
                            className={({ isActive }) => cn(
                                "flex items-center gap-2.5 w-[208px] h-5 px-[18px] rounded-[10px]",
                                subTab.noAccess
                                ? "h-11 py-3 bg-Grey-900 cursor-not-allowed"
                                : isActive && "h-11 py-3 bg-Secondary-500",
                            )}
                        >
                            {({isActive}) => (
                                <span className={cn(
                                    "text-Grey-400 text-base leading-[20px]",
                                    subTab.noAccess
                                    ? "text-Grey-100"
                                    : isActive && "text-Grey-950",
                                )}>
                                    {subTab.name}
                                </span>
                            )}
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
}

const NavBar = React.forwardRef<HTMLDivElement, NavigationMenuProps>(
    (
        {
            className,
            children,
            title,
            menu,
            ...props
        },
        ref
    ) => {
        return (
            <nav
                className={cn("flex flex-col items-start self-stretch gap-3", className)}
                {...props}
                ref={ref}
            >
                {title && (
                    <div className={"flex items-center self-stretch px-[14px] gap-2.5"}>
                        <span className={"text-Grey-500 text-sm"}>
                            {title}
                        </span>
                    </div>
                )}
                {/* Render menu */}
                <div className={"flex flex-col items-start self-stretch"}>
                    {menu.map((tab) => {
                        return (
                            tab.subTabs ?
                                <SubTabNavigator tab={tab} key={tab.name} /> :
                                <TabNavigator tab={tab} key={tab.name} />
                        );
                    })}
                </div>
            </nav>
        );
    }
);
NavBar.displayName = "NavBar";

type SideBarIconProps = React.HTMLAttributes<HTMLOrSVGElement> & {
    isActive: boolean;
    isDisabled?: boolean;
};

const DashboardIcon = React.forwardRef<HTMLOrSVGElement, SideBarIconProps>(
    (
        {
            className,
            isActive,
            ...props
        },
        ref
    ) => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.9172 3.5C15.6285 3.5 14.5839 4.54467 14.5839 5.83334V11.0833C14.5839 12.372 15.6285 13.4167 16.9172 13.4167H22.1672C23.4559 13.4167 24.5005 12.372 24.5005 11.0833V5.83333C24.5005 4.54467 23.4559 3.5 22.1672 3.5H16.9172ZM3.5 5.83335C3.5 4.54468 4.54467 3.50001 5.83333 3.50001H11.0833C12.372 3.50001 13.4167 4.54468 13.4167 5.83335V11.0833C13.4167 12.372 12.372 13.4167 11.0833 13.4167H5.83334C4.54467 13.4167 3.5 12.372 3.5 11.0833V5.83335ZM9.91669 8.45833C9.91669 9.26375 9.26377 9.91667 8.45835 9.91667C7.65294 9.91667 7.00002 9.26375 7.00002 8.45833C7.00002 7.65292 7.65294 7 8.45835 7C9.26377 7 9.91669 7.65292 9.91669 8.45833ZM16.9173 14.583C15.6287 14.583 14.584 15.6277 14.584 16.9164V22.1664C14.584 23.455 15.6287 24.4997 16.9173 24.4997H22.1673C23.456 24.4997 24.5007 23.455 24.5007 22.1664V16.9164C24.5007 15.6277 23.456 14.583 22.1673 14.583H16.9173ZM19.9546 17.9125C19.7268 17.6847 19.3575 17.6847 19.1297 17.9125L17.913 19.1292C17.6852 19.357 17.6852 19.7264 17.913 19.9542L19.1297 21.1709C19.3575 21.3987 19.7268 21.3987 19.9546 21.1709L21.1713 19.9542C21.3991 19.7264 21.3991 19.357 21.1713 19.1292L19.9546 17.9125ZM3.5 16.9164C3.5 15.6277 4.54467 14.583 5.83333 14.583H11.0833C12.372 14.583 13.4167 15.6277 13.4167 16.9164V22.1664C13.4167 23.455 12.372 24.4997 11.0833 24.4997H5.83334C4.54467 24.4997 3.5 23.455 3.5 22.1664V16.9164ZM8.15555 18.4684C8.29026 18.235 8.62705 18.235 8.76177 18.4684L9.92368 20.4809C10.0584 20.7142 9.89001 21.0059 9.62058 21.0059H7.29674C7.02731 21.0059 6.85892 20.7142 6.99363 20.4809L8.15555 18.4684Z"
                  fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
        </svg>
    )
);

const SalesIcon = React.forwardRef<HTMLOrSVGElement, SideBarIconProps>(
    (
        {
            className,
            isActive,
            ...props
        },
        ref
    ) => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.6673 2.33301C10.3787 2.33301 9.33398 3.37768 9.33398 4.66634V10.1497C9.33398 11.6316 10.5354 12.833 12.0173 12.833H19.484C20.9659 12.833 22.1673 11.6316 22.1673 10.1497V4.66634C22.1673 3.37768 21.1226 2.33301 19.834 2.33301H11.6673ZM14.584 5.25C14.1007 5.25 13.709 5.64175 13.709 6.125C13.709 6.60825 14.1007 7 14.584 7H16.9173C17.4006 7 17.7923 6.60825 17.7923 6.125C17.7923 5.64175 17.4006 5.25 16.9173 5.25H14.584Z"
                  fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
            <path
                d="M16.3342 14.5833H11.6666C10.4685 14.5833 9.38095 14 8.16699 14C4.42198 14 1.65832 17.9254 1.22606 19.4759C1.16216 19.7052 1.23956 19.9382 1.37503 20.1338L5.17746 25.6262C5.70839 26.3931 6.87933 26.2608 7.22574 25.3948L7.58366 24.5C8.05986 23.5477 10.3783 23.9043 12.6647 24.2561C14.3494 24.5153 16.0168 24.7718 16.917 24.5C18.1945 24.1143 24.0906 18.0599 26.4435 15.6053C27.0102 15.014 27.0581 14.1047 26.5666 13.4495C26.0538 12.7658 25.1101 12.5749 24.3718 13.0055L19.7738 15.6877C19.8129 15.8969 19.8332 16.1128 19.8332 16.3333C19.8332 18.2663 18.2662 19.8333 16.3333 19.8333H11.6666C11.1833 19.8333 10.7916 19.4416 10.7916 18.9583C10.7916 18.4751 11.1833 18.0833 11.6666 18.0833H16.3333C17.2997 18.0833 18.0832 17.2998 18.0832 16.3333C18.0832 15.3664 17.3003 14.5833 16.3342 14.5833Z"
                fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
        </svg>
    )
);

const ProductsIcon = React.forwardRef<HTMLOrSVGElement, SideBarIconProps>(
    (
        {
            className,
            isActive,
            ...props
        },
        ref
    ) => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.09984 3.5C8.58437 3.5 8.1665 3.91787 8.1665 4.43333V10.5C8.1665 12.1108 9.47234 13.4167 11.0832 13.4167H16.3332C17.944 13.4167 19.2498 12.1108 19.2498 10.5V4.43333C19.2498 3.91787 18.832 3.5 18.3165 3.5H9.09984ZM12.5415 5.83301C12.0583 5.83301 11.6665 6.22476 11.6665 6.70801C11.6665 7.19126 12.0583 7.58301 12.5415 7.58301H14.8748C15.3581 7.58301 15.7498 7.19126 15.7498 6.70801C15.7498 6.22476 15.3581 5.83301 14.8748 5.83301H12.5415Z"
                  fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.26634 14.583C2.75088 14.583 2.33301 15.0009 2.33301 15.5163V22.1663C2.33301 23.7772 3.63884 25.083 5.24967 25.083H10.4997C12.1105 25.083 13.4163 23.7772 13.4163 22.1663V15.5163C13.4163 15.0009 12.9985 14.583 12.483 14.583H3.26634ZM6.70801 16.916C6.22476 16.916 5.83301 17.3078 5.83301 17.791C5.83301 18.2743 6.22476 18.666 6.70801 18.666H9.04134C9.52459 18.666 9.91634 18.2743 9.91634 17.791C9.91634 17.3078 9.52459 16.916 9.04134 16.916H6.70801Z"
                  fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.5163 14.583C15.0009 14.583 14.583 15.0009 14.583 15.5163V22.1663C14.583 23.7772 15.8888 25.083 17.4997 25.083H22.7497C24.3605 25.083 25.6663 23.7772 25.6663 22.1663V15.5163C25.6663 15.0009 25.2485 14.583 24.733 14.583H15.5163ZM18.958 16.916C18.4748 16.916 18.083 17.3078 18.083 17.791C18.083 18.2743 18.4748 18.666 18.958 18.666H21.2913C21.7746 18.666 22.1663 18.2743 22.1663 17.791C22.1663 17.3078 21.7746 16.916 21.2913 16.916H18.958Z"
                  fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
        </svg>
    )
);

const AdvertisingIcon = React.forwardRef<HTMLOrSVGElement, SideBarIconProps>(
    (
        {
            className,
            isActive,
            isDisabled,
            ...props
        },
        ref
    ) => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.334 13.9997H25.6673M14.0007 4.66634V2.33301M4.66732 13.9997H2.33398M14.0007 23.333V25.6663M20.6003 7.40001L22.2502 5.7501M7.40097 7.40001L5.75105 5.7501M7.40097 20.5993L5.75105 22.2493M20.6003 20.5993L22.2502 22.2493"
                stroke={
                    isDisabled
                    ? colors.grey["100"]
                    : isActive ? colors.grey["950"] : colors.grey["500"]
                }
                strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.91699 8.16699C8.95049 8.16699 8.16699 8.9505 8.16699 9.91699V18.0837C8.16699 19.0502 8.9505 19.8337 9.91699 19.8337H18.0837C19.0502 19.8337 19.8337 19.0502 19.8337 18.0837V9.91699C19.8337 8.95049 19.0502 8.16699 18.0837 8.16699H9.91699ZM12.9394 11.5049C12.4562 11.5049 12.0644 11.8967 12.0644 12.3799C12.0644 12.8632 12.4562 13.2549 12.9394 13.2549H15.0606C15.5439 13.2549 15.9356 12.8632 15.9356 12.3799C15.9356 11.8967 15.5439 11.5049 15.0606 11.5049H12.9394Z"
                  fill={
                      isDisabled
                      ? colors.grey["100"]
                      : isActive ? colors.grey["950"] : colors.grey["500"]
                  }
            />
        </svg>
    )
);

const AnalyticsIcon = React.forwardRef<HTMLOrSVGElement, SideBarIconProps>(
    (
        {
            className,
            isActive,
            ...props
        },
        ref
    ) => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.8332 25.667L15.9443 18.667H12.0554L8.1665 25.667" stroke="#727373" strokeWidth="1.75"
                  strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.9165 4.66699H25.0832V17.5003C25.0832 19.4333 23.5162 21.0003 21.5832 21.0003H6.4165C4.4835 21.0003 2.9165 19.4333 2.9165 17.5003V4.66699ZM8.3999 12.0166C8.88315 12.0166 9.2749 12.4084 9.2749 12.8916V15.8083C9.2749 16.2915 8.88315 16.6833 8.3999 16.6833C7.91665 16.6833 7.5249 16.2915 7.5249 15.8083V12.8916C7.5249 12.4084 7.91665 12.0166 8.3999 12.0166ZM13.0082 9.3916C13.0082 8.90835 12.6165 8.5166 12.1332 8.5166C11.65 8.5166 11.2582 8.90835 11.2582 9.3916V15.8083C11.2582 16.2915 11.65 16.6833 12.1332 16.6833C12.6165 16.6833 13.0082 16.2915 13.0082 15.8083V9.3916ZM15.8666 10.2666C16.3498 10.2666 16.7416 10.6584 16.7416 11.1416V15.8083C16.7416 16.2915 16.3498 16.6833 15.8666 16.6833C15.3833 16.6833 14.9916 16.2915 14.9916 15.8083V11.1416C14.9916 10.6584 15.3833 10.2666 15.8666 10.2666ZM20.4749 12.8916C20.4749 12.4084 20.0832 12.0166 19.5999 12.0166C19.1167 12.0166 18.7249 12.4084 18.7249 12.8916V15.8083C18.7249 16.2915 19.1167 16.6833 19.5999 16.6833C20.0832 16.6833 20.4749 16.2915 20.4749 15.8083V12.8916Z"
                  fill="#727373"/>
            <path d="M2.625 4.375H14M25.375 4.375H14M14 4.375V2.625" stroke="#727373" strokeWidth="1.75"
                  strokeLinecap="round"/>
        </svg>
    )
);

const SettingsIcon = React.forwardRef<HTMLOrSVGElement, SideBarIconProps>(
    (
        {
            className,
            isActive,
            ...props
        },
        ref
    ) => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.0197 10.9048C21.0043 10.9048 20.1803 9.47952 21.1824 7.73136C21.7614 6.71809 21.4163 5.42646 20.403 4.84745L18.4767 3.74511C17.597 3.22178 16.4613 3.53355 15.9379 4.4132L15.8155 4.62476C14.8133 6.37292 13.1654 6.37292 12.1521 4.62476L12.0296 4.4132C11.5286 3.53355 10.3928 3.22178 9.51318 3.74511L7.58686 4.84745C6.5736 5.42646 6.22842 6.72923 6.80743 7.74249C7.8207 9.47952 6.99672 10.9048 4.98133 10.9048C3.82331 10.9048 2.86572 11.8512 2.86572 13.0204V14.9801C2.86572 16.1381 3.81218 17.0957 4.98133 17.0957C6.99672 17.0957 7.8207 18.521 6.80743 20.2691C6.22842 21.2824 6.5736 22.574 7.58686 23.153L9.51318 24.2554C10.3928 24.7787 11.5286 24.4669 12.0519 23.5873L12.1744 23.3757C13.1765 21.6276 14.8245 21.6276 15.8377 23.3757L15.9602 23.5873C16.4835 24.4669 17.6193 24.7787 18.4989 24.2554L20.4253 23.153C21.4385 22.574 21.7837 21.2712 21.2047 20.2691C20.1914 18.521 21.0154 17.0957 23.0308 17.0957C24.1888 17.0957 25.1464 16.1492 25.1464 14.9801V13.0204C25.1353 11.8624 24.1888 10.9048 23.0197 10.9048ZM14.0005 17.619C12.0074 17.619 10.3817 15.9934 10.3817 14.0002C10.3817 12.0071 12.0074 10.3814 14.0005 10.3814C15.9936 10.3814 17.6193 12.0071 17.6193 14.0002C17.6193 15.9934 15.9936 17.619 14.0005 17.619Z"
                fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
        </svg>
    )
);

const SupportIcon = React.forwardRef<HTMLOrSVGElement, SideBarIconProps>(
    (
        {
            className,
            isActive,
            ...props
        },
        ref
    ) => (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.6667 2.37169V9.77436C10.6471 10.1347 9.91664 11.1071 9.91664 12.25C9.91664 13.393 10.6471 14.3653 11.6667 14.7256V15.1663C11.6667 17.0993 13.2337 18.6663 15.1667 18.6663H16.6249C16.8909 19.0206 17.3145 19.2497 17.7917 19.2497C18.5971 19.2497 19.25 18.5968 19.25 17.7913C19.25 16.9859 18.5971 16.333 17.7917 16.333C17.3145 16.333 16.8909 16.5621 16.6249 16.9163H15.1667C14.2002 16.9163 13.4167 16.1328 13.4167 15.1663V14.7256C14.4362 14.3653 15.1666 13.3929 15.1666 12.25C15.1666 11.1071 14.4362 10.1347 13.4167 9.77438V2.35448C19.271 2.64934 20.663 5.91684 21.61 8.13966C21.8027 8.59199 21.977 9.00105 22.1667 9.33301C22.4221 9.78008 22.6975 10.2265 22.9664 10.6624C23.7615 11.9514 24.5 13.1485 24.5 13.9997C24.5 15.1387 22.1667 15.7497 22.1667 15.7497V18.083C22.1667 20.3382 20.3385 22.1663 18.0833 22.1663V24.7913C18.0833 25.2746 17.6916 25.6663 17.2083 25.6663H7.84199C7.37183 25.6663 6.98571 25.2948 6.96764 24.825L6.84395 21.6092C6.78421 20.0559 5.9737 18.5747 5.18758 17.1381C4.89452 16.6025 4.60485 16.0731 4.35873 15.5485C3.87002 14.5068 3.5 13.064 3.5 11.083C3.5 5.63753 7.56678 2.73373 11.6667 2.37169ZM11.6667 2.37169V2.33301H13.4167V2.35448C13.1354 2.34032 12.8439 2.33301 12.5417 2.33301C12.2508 2.33301 11.9586 2.3459 11.6667 2.37169Z"
                  fill={isActive ? colors.grey["950"] : colors.grey["500"]}/>
        </svg>
    )
);

export default NavBar;