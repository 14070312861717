import * as React from "react";
import {HTMLAttributes} from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "@/styles/custom-multi-carousel.css";

import {ProductDetailLinkData, TopProductDetailLinkData} from "@/types";
import {StockCard} from "@/features/inventory/components/stock-card";
import TopSellingIcon from "@/assets/icons/carousel.svg";
import {ChartTitle} from "@/components/ui/chart/recharts/recharts";
import {CarouselMediumCard, CarouselSmallCard} from "@/components/ui/card/carousel/carousel-card";


const CarouselHeader = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { title: string; period?: string; }>(
    (
        {
            className,
            title,
            period,
            children,
            ...props
        },
        ref
    ) => {
        return (
            <div
                className={"flex justify-between items-center self-stretch pl-7 pr-[52px] py-[19px] rounded-[14px] bg-Grey-950"}>
                <ChartTitle
                    icon={TopSellingIcon}
                    title={title}
                    period={period}
                />
                {children}
            </div>
        );
    }
);



const CustomDot = (props: any) => {
    const { active, onClick } = props;

    return (
        <div
            onClick={onClick}
            className={`w-2 h-2 rounded-[40px] ${active ? "bg-Base-white": "bg-Grey-700"} cursor-pointer`}
        />
    );
}

const TopSellingCarousel = ({
    series,
}: {
    series: TopProductDetailLinkData[];
}) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
        <>
            <CarouselHeader title={"Top Selling ASINs"}>
                <StockCard />
            </CarouselHeader>
            <div className={"flex flex-col relative self-stretch gap-[1px]"}>
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={false}
                    partialVisible={false}
                    transitionDuration={200}
                    containerClass={"absolute"}
                    sliderClass={"gap-[1px]"}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    renderDotsOutside={true}
                    customDot={<CustomDot />}
                >
                {series.map((serie, i) => {
                        return (
                            <CarouselSmallCard key={i} serie={serie} />
                        );
                    })}
                </Carousel>
                <div className={"w-full h-8 rounded-[14px] bg-Grey-950"}/>
            </div>
        </>
    );
}

const ComponentCarousel = ({
                               series,
                               period,
                           }: {
    series: ProductDetailLinkData[];
    period: string;
}) => {
    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
        <>
            <CarouselHeader title={"ASIN"} period={period}/>
            <div className={"flex flex-col relative self-stretch gap-[1px]"}>
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    renderDotsOutside={true}
                    customDot={<CustomDot />}
                    infinite={false}
                    partialVisible={false}
                    transitionDuration={200}
                    containerClass={"flex items-start self-stretch"}
                    sliderClass={"gap-[1px]"}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                    {series.map((serie, i) => {
                        const itemsInRow = 2;
                        if (i % itemsInRow !== 0) return null; // Skip odd indices

                        return (
                            <div key={i / itemsInRow}
                                 className={"flex flex-col items-start self-stretch gap-[1px] rounded-[14px]"}>
                                {series.slice(i, i + itemsInRow)
                                    .map((data, j) => (
                                        <CarouselMediumCard key={j} serie={series[i + j]}/>
                                    ))}
                            </div>
                        );
                    })}
                </Carousel>
                <div className={"w-full h-8 rounded-[14px] bg-Grey-950"}/>
            </div>
        </>
    );
}


export {
    TopSellingCarousel,
    ComponentCarousel,
}