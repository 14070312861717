import {FormatType} from "@/utils/formatter";

export type TimeValueLineData = [string, number];

export type CategoryGraphData = [string, number]

export type dataPosProps = {
    x: number;
    y: number;
    offsetX?: number;
    offsetY?: number;
}

export type activePosProps = {
    [key: string]: dataPosProps;
}

export type multipleAxisLabel = {
    name: string;
    yAxisId?: string;
    color?: string;
    format?: FormatType;
    tooltipReversed?: boolean;
    yAxisHide?: boolean;
    stackId?: string;
    position?: "left" | "right" | "top" | "bottom";
    offset?: number;
};

export type RechartsProps = {
    title: string;
    series: any[];
    noDataGraphic?: React.ReactNode;
    timeRange?: string;
    labels?: multipleAxisLabel[];
    period?: string;
    chartSupplementaryFields?: React.ReactNode;
    width?: string;
    height?: string;
}

export const lineHeightFn = (height: number) => ({height: height, offsetFn: (fontSize: number) => (height - fontSize) / 2});

export const logScaleOn = (value: number) => {
    return value == 0 ? 0 : Math.log(value) / Math.log(2);
}

export const logScaleOff = (value: number) => {
    return Math.round(Math.pow(2, value));
}

export const PieMainKPICalculateOffsetX = (len: number, minLen = 2, maxLen = 6, minOffsetX = -30, maxOffsetX = 0) => {
    len = Math.max(minLen, Math.min(maxLen, len));

    return (len - minLen) / (maxLen - minLen) * (maxOffsetX - minOffsetX) + minOffsetX;
}