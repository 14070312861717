import * as React from "react";

import {cva, VariantProps} from "class-variance-authority";

import {
    Card, CardBody, CardContent, CardHeader,
    CardTrend,
} from "@/components/ui/card/card";
import {CardResponse} from "@/types";
import {formatValue} from "@/utils/formatter";
import {SingleItemCardInfo} from "@/components/ui/card/config";
import CategoryRankIcon from "@/assets/icons/card/category-rank.svg";

const simpleCardVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                extraSmall: '',
                small: '',
                medium: '',
                large: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'medium',
        },
    },
);

export type SimpleCardProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof simpleCardVariants> & {
    data: CardResponse;
};

const SimpleCard = React.forwardRef<HTMLDivElement, SimpleCardProps>(
    (
        {
            className,
            variant,
            size,
            children,
            data,
            ...props
        },
        ref,
    ) => {
        return (
            <div className={"flex items-start self-stretch gap-[1px]"}>
                {data.summary.map((summary, summary_i) => (
                    <Card key={summary_i} variant={"single"} wrapperVariant={"single"}>
                        <CardHeader variant={"single"}>
                            {SingleItemCardInfo[summary.subtitle]?.icon}
                            <span className={"text-Grey-300 text-base"}>
                                {summary.subtitle}
                            </span>
                        </CardHeader>
                        <CardBody wrapperVariant={"single"}>
                            <CardContent variant={"single"} gap={"trend"}>
                                <span className={"text-Base-white text-[34px] font-bold leading-[36px] tracking-[-0.68px]"}>
                                    {formatValue(summary.data[0].value, SingleItemCardInfo[summary.subtitle]?.format)}
                                </span>
                                <CardTrend dataEntry={summary.data[0]}/>
                            </CardContent>
                        </CardBody>
                    </Card>
                ))}
            </div>
        )
    },
);

type categoryBodyProps = {
    name: string;
    category: string;
    rank: string;
}

const CategoryBody = ({
    info
}: {
    info: categoryBodyProps;
}) => {
    return (
        <div className={"flex items-center gap-2"}>
            {/* field name */}
            <div className={"flex items-center gap-1"}>
                <span className={"text-Grey-300 text-base leading-[20px]"}>
                    {info.name}
                </span>
            </div>
            <div className={"w-2 h-0 border-[1.5px] border-Grey-300"}/>
            {/* category name */}
            <span className={"text-Grey-200 text-base font-bold leading-[20px]"}>
                {info.category}
            </span>
            {/* category ranking */}
            <div className={"flex items-center gap-[1px]"}>
                <img src={CategoryRankIcon} alt={"CategoryRankIcon"}/>
                <span className={"text-Green-700 text-sm font-bold"}>
                    {info.rank}
                </span>
            </div>
        </div>
    );
}

const InfoCategoryCard = ({
    infos,
}: {
    infos: categoryBodyProps[]
}) => {
    return (
        <div className={"flex items-start self-stretch gap-[1px]"}>
            <div className={"flex flex-col items-start gap-4 flex-1 pl-7 pr-8 py-7 rounded-[14px] bg-Grey-950"}>
                {infos.map(info => (
                    <CategoryBody key={`category ${info.name}`} info={info} />
                ))}
            </div>
        </div>
    );
}

type KPIBodyProps = {
    name: string;
    kpis: {
        name: string;
        value: string;
    }[];
}

const KPIBody = ({
    info,
}: {
    info: KPIBodyProps,
}) => {
    return (
        <div className={"flex flex-col items-start flex-1 rounded-2xl"}>
            <div className={"flex flex-col items-start self-stretch gap-3.5 px-7 py-[22px] rounded-t-[14px]"}>
                <span className={"text-Base-white text-lg font-bold leading-[24px]"}>
                    {info.name}
                </span>
            </div>
            <div className={"self-stretch h-0 border-[1.5px] border-Alpha-black20"}/>
            <div className={"flex flex-col items-start self-stretch gap-4 pt-[18px] pb-[22px]"}>
                {info.kpis.map(kpi => (
                    <div key={`kpi field ${kpi.name}`} className={"flex flex-col justify-center items-start self-stretch min-w-[140px] gap-0.5 px-7"}>
                        <span className={"text-Grey-400 text-sm"}>
                            {kpi.name}
                        </span>
                        <span className={"text-Base-white text-sm font-bold leading-normal"}>
                            {kpi.value}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

const InfoKPICard = ({
                         infos,
                     }: {
    infos: KPIBodyProps[]
}) => {
    return (
        <div className={"flex items-start self-stretch rounded-2xl bg-Grey-950"}>
            {infos.map(info => (
                <KPIBody key={`kpi ${info.name}`} info={info} />
            ))}
        </div>
    );
}

export {
    SimpleCard,
    InfoCategoryCard,
    InfoKPICard,
};