import { cva, type VariantProps} from "class-variance-authority";
import * as React from "react";
import {cn} from "@/utils/cn";

const inputVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "w-full bg-Grey-900 focus:outline-none focus:ring-2",
            },
            size: {
                default: "",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        }
    }
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
    VariantProps<typeof inputVariants> & {
    onChange: (value: any) => void;
    debounce?: number;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className,
            variant,
            size,
            ...props
        },
        ref
    ) => {
        return (
            <input ref={ref} className={cn(inputVariants({ variant, size, className }))} {...props} />
        );
    }
);

export default Input;