import {queryOptions, useQuery} from "@tanstack/react-query";

import {ProductDetailLinkResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getComponentItems = ({
    brand,
    component_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    component_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<ProductDetailLinkResponse> => {
    return api.get(`/products/${component_item}/items`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getComponentItemsQueryOptions = ({
    brand,
    component_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    component_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["component", "items", brand, component_item, period, startDate, endDate],
        queryFn: () => getComponentItems({ brand, component_item, period, startDate, endDate }),
    })
}

type UseProductItemsOptions = {
    brand: string;
    component_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getComponentItemsQueryOptions>;
}

export const useComponentItems = ({
    brand,
    component_item,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseProductItemsOptions) => {
    return useQuery({
        ...getComponentItemsQueryOptions({ brand, component_item, period, startDate, endDate }),
        ...queryConfig
    })
}