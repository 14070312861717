import * as React from "react";
import {useMemo} from "react";

import {Range} from "react-date-range";

import {useAdvertisingItems} from "@/features/advertising/api/get-advertising-items";
import {FormatType, formatValue} from "@/utils/formatter";
import {Table} from "@/components/ui/table/tanStack/pagination-table/pagination-table";
import {ExtendedColumnDef} from "@/components/ui/table/table";
import {AdListData} from "@/types";
import {
    CellCurrency, CellFloat, CellHyperlink, CellImage,
    CellInteger,
    CellPercent,
    CellText,
    CellViewDetail
} from "@/components/ui/table/tanStack/cell-render";
import {useBrand} from "@/app/providers/brand";
import {AdvertisingColumnDef} from "@/components/ui/table/tanStack/column-def";


export const AdvertisingItems = ({
    dateRange,
    hidePeriod,
}: {
    dateRange: Range[];
    hidePeriod?: boolean;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const advertisingItemsQuery = useAdvertisingItems({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    const columns: ExtendedColumnDef<AdListData>[] = useMemo(() => AdvertisingColumnDef, []);

    if (advertisingItemsQuery.isLoading) {
        return <div>Advertising Items Loading...</div>;
    }

    if (!advertisingItemsQuery.data) return null;

    // const initialFilters: CustomFilterMeta = {
    //     asin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     vendor_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
    //     impressions: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     clicks: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     roas: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     acos: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     total_br_sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     total_ad_sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     total_ad_spend: { value: null, matchMode: FilterMatchMode.CUSTOM },
    // }
    //
    // const visMapper: ColumnVisMapperProps = {
    //     amz_link: ColumnVisMeta.Amz_link,
    //     asin: ColumnVisMeta.Merge_toAmzLink,
    //     detail_link: ColumnVisMeta.Detail_link,
    // }

    return (
        // <div>
        //     <PaginationTable
        //         data={advertisingItemsQuery.data.data.map(item => ({
        //             ...item,
        //             impressions: numberF(item.impressions),
        //             clicks: numberF(item.clicks),
        //             roas: numberF(item.roas),
        //             acos: percentF(item.acos),
        //             total_ad_spend: currencyF(item.total_ad_spend),
        //             total_ad_sales: currencyF(item.total_ad_sales),
        //             total_br_sales: currencyF(item.total_br_sales),
        //         }))}
        //         columns={advertisingItemsQuery.data.columns}
        //         initialFilters={initialFilters}
        //         visMapper={visMapper}
        //         dateRange={dateRange}
        //     />
        // </div>
        <Table
            title={"Amazon Listings"}
            data={advertisingItemsQuery.data.data}
            columns={columns}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
            defaultSearchColumn={"Listing Title"}
            defaultSortColumn={{id: "ad_sales", desc: true}}
        />
    );
}