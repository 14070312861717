import {queryOptions, useQuery} from "@tanstack/react-query";

import {ComponentInfoResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getComponentInfo = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}): Promise<ComponentInfoResponse> => {
    return api.get(`/products/${component_item}/info`, {
        params: {
            brand,
        }
    })
}

export const getComponentInfoQueryOptions = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}) => {
    return queryOptions({
        queryKey: ["component", "info", brand, component_item],
        queryFn: () => getComponentInfo({ brand, component_item }),
    })
}

type UseComponentInfoOptions = {
    brand: string;
    component_item: string;
    queryConfig?: QueryConfig<typeof getComponentInfoQueryOptions>;
}

export const useComponentInfo = ({
    brand,
    component_item,
    queryConfig,
}: UseComponentInfoOptions) => {
    return useQuery({
        ...getComponentInfoQueryOptions({ brand, component_item }),
        ...queryConfig,
    })
}