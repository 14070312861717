import * as React from "react";

import {
    CellCurrency, CellFloat, CellHyperlink, CellImage,
    CellInteger,
    CellPercent, CellStatus,
    CellText,
    CellViewDetail
} from "@/components/ui/table/tanStack/cell-render";
import {ExtendedColumnDef} from "@/components/ui/table/table";
import {AdListData, OpenPOListData, ProductDetailLinkData, ProductsListData} from "@/types";


// number for dynamic adjusting width
export const DYNAMIC_TABLE_COLUMN_WIDTH = 107;

// TanStack table ColumnDef
const IDColumnDefComponentBased: ExtendedColumnDef<any>[] = [
    {
        accessorFn: row => `${row.vendor_sku}`,
        id: "vendor_sku",
        cell: info => <CellText text={info.getValue()} />,
        header: "SKU",
        size: 150,
        searchable: true,
    },
    {
        accessorFn: row => `${row.upc}`,
        id: "upc",
        cell: info => <CellText text={info.getValue()} />,
        header: "UPC",
        size: 150,
        searchable: true,
    },
    {
        accessorFn: row => `${row.item_name}`,
        id: "item_name",
        cell: info => <CellText text={info.getValue()} />,
        header: "Item Name",
        size: DYNAMIC_TABLE_COLUMN_WIDTH,
        searchable: true,
    },
];

const IDColumnDefASINBased: ExtendedColumnDef<any>[] = [
    {
        accessorFn: row => `${row.status} ${row.img}`,
        id: "img",
        cell: info => <CellImage url={info.row.original.img} status={info.row.original.status} />,
        header: "Image",
        size: 100,
    },
    {
        accessorFn: row => `${row.asin} ${row.vendor_sku}`,
        id: "asin",
        cell: info => (
            <span className={"flex flex-col"}>
                        <CellHyperlink url={info.row.original.amazon_link} prefix_text={info.row.original.asin} />
                        <CellText text={info.row.original.vendor_sku} truncateWidth={150} />
                    </span>
        ),
        header: "ASIN • SKU",
        searchable: true,
        size: 150,
    },
    {
        accessorFn: row => `${row.listing_title}`,
        id: "listing_title",
        cell: info => <CellText text={info.getValue()} />,
        header: "Listing Title",
        size: DYNAMIC_TABLE_COLUMN_WIDTH,
        searchable: true,
    },
];

const ViewLinkDef: ExtendedColumnDef<any> = {
    accessorFn: row => row.view_link,
    id: "view_link",
    cell: info => {
        return (
            (!info.row.original.status || info.row.original.status === "Active") && (
                <CellViewDetail url={info.getValue()} />
            )
        );
    },
    header: "",
    size: 80,
};

const OpenPoColumnDef: ExtendedColumnDef<OpenPOListData>[] = [
    {
        accessorFn: row => `${row.po_number}`,
        id: "po_number",
        cell: info => <CellText text={info.getValue()} />,
        header: "PO Number",
        size: DYNAMIC_TABLE_COLUMN_WIDTH,
        searchable: true,
    },
    {
        accessorFn: row => `${row.po_date}`,
        id: "po_date",
        cell: info => <CellText text={info.getValue()} />,
        header: "PO Date",
        size: 200,
        searchable: true,
    },
    {
        accessorFn: row => `${row.po_status}`,
        id: "po_status",
        cell: info => <CellStatus text={info.getValue()} status={info.getValue() === "Partially Received"} />,
        header: "Status",
        size: 280,
        searchable: true,
    },
    {
        accessorFn: row => row.quantity,
        id: "quantity",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "Units",
        size: 150,
        filterable: true,
    },
    {
        accessorFn: row => row.amount,
        id: "amount",
        cell: info => <CellCurrency total={info.getValue()} />,
        header: "Amount",
        size: 150,
        filterable: true,
    },
];

const SalesColumnDef: ExtendedColumnDef<ProductsListData>[] = [
    ...IDColumnDefComponentBased as ExtendedColumnDef<ProductsListData>[],
    {
        accessorFn: row => row.units,
            id: "units",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "Units",
            size: 150,
        filterable: true,
    },
    {
        accessorFn: row => row.sales,
            id: "sales",
        cell: info => <CellCurrency total={info.getValue()} />,
        header: "Sales",
            size: 150,
        filterable: true,
    },
    {
        accessorFn: row => row.revenue_share,
            id: "revenue_share",
        cell: info => <CellPercent value={info.getValue()} />,
        header: "Revenue %",
            size: 150,
        filterable: true,
    },
    {
        accessorFn: row => row.amazon_listing_count,
            id: "listing_count",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "ASINs",
            size: 100,
        filterable: true,
    },
    ViewLinkDef,
];

const ProductsColumnDef: ExtendedColumnDef<ProductDetailLinkData>[] = [
    ...IDColumnDefASINBased as ExtendedColumnDef<ProductDetailLinkData>[],
    {
        accessorFn: row => `${row.category_rank} ${row.category}`,
        id: "category_rank",
        cell: info => (
            <span className={"flex flex-col"}>
                            <CellText text={`#${info.row.original.category_rank}`}/>
                            <CellText text={info.row.original.category}/>
                        </span>
        ),
        header: "Sales Rank",
        size: 180,
        searchable: true,
    },
    {
        accessorFn: row => row.units,
        id: "units",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "Units",
        size: 100,
        filterable: true,
    },
    {
        accessorFn: row => row.sales,
        id: "sales",
        cell: info => <CellCurrency total={info.getValue()} average={info.row.original.avg_price} />,
        header: "Sales",
        size: 150,
        filterable: true,
    },
    {
        accessorFn: row => row.inventory_available,
        id: "inventory_available",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "Available",
        size: 100,
        filterable: true,
    },
    {
        accessorFn: row => row.inventory_inbound,
        id: "inventory_inbound",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "Inbound",
        size: 100,
        filterable: true,
    },
    ViewLinkDef,
];

const AdvertisingColumnDef: ExtendedColumnDef<AdListData>[] = [
    ...IDColumnDefASINBased as ExtendedColumnDef<AdListData>[],
    {
        accessorFn: row => row.impressions,
        id: "impressions",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "Impressions",
        size: 100,
        filterable: true,
    },
    {
        accessorFn: row => row.clicks,
        id: "clicks",
        cell: info => <CellInteger value={info.getValue()} />,
        header: "Clicks",
        size: 90,
        filterable: true,
    },
    {
        accessorFn: row => row.roas,
        id: "roas",
        cell: info => <CellFloat value={info.getValue()} />,
        header: "ROAS",
        size: 80,
        filterable: true,
    },
    {
        accessorFn: row => row.acos,
        id: "acos",
        cell: info => <CellPercent value={info.getValue()} />,
        header: "ACOS",
        size: 100,
        filterable: true,
    },
    {
        accessorFn: row => row.ad_spend,
        id: "ad_spend",
        cell: info => <CellCurrency total={info.getValue()} />,
        header: "Ad Spend",
        size: 100,
        filterable: true,
    },
    {
        accessorFn: row => row.ad_sales,
        id: "ad_sales",
        cell: info => <CellCurrency total={info.getValue()} />,
        header: "Ad Sales",
        size: 110,
        filterable: true,
    },
    {
        accessorFn: row => row.total_sales,
        id: "total_sales",
        cell: info => <CellCurrency total={info.getValue()} />,
        header: "Total Sales",
        size: 130,
        filterable: true,
    },
    ViewLinkDef,
];

const PurchaseColumnDefFn = (dynamicColumns: ExtendedColumnDef<any>[]): ExtendedColumnDef<any>[] => [
    ...IDColumnDefComponentBased,
    ...dynamicColumns,
    ViewLinkDef,
];


export {
    OpenPoColumnDef,
    SalesColumnDef,
    ProductsColumnDef,
    AdvertisingColumnDef,
    PurchaseColumnDefFn,
}