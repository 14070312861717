import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {CardResponse} from "@/types";

export const getStockCard = ({
    brand,
}: {
    brand: string;
}): Promise<CardResponse> => {
    return api.get(`/inventory/instock-rate-revenue-loss`, {
        params: {
            brand,
        },
    });
}

export const getStockCardQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["inventory", "stock-card", brand],
        queryFn: () => getStockCard({ brand }),
    })
}

type UseStockCardOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getStockCardQueryOptions>;
}

export const useStockCard = ({
    brand,
    queryConfig,
}: UseStockCardOptions) => {
    return useQuery({
        ...getStockCardQueryOptions({ brand }),
        ...queryConfig,
    })
}