import {queryOptions, useQuery} from "@tanstack/react-query";

import {AssemblyChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getAssemblyChart = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<AssemblyChartResponse[]> => {
    return api.get(`/assembly/${assembly_item}/chart`, {
        params: {
            brand,
            period,
            startDate,
            endDate
        }
    })
}

export const getAssemblyChartQueryOptions = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", "chart", brand, assembly_item, period, startDate, endDate],
        queryFn: () => getAssemblyChart({ brand, assembly_item, period, startDate, endDate }),
    })
}

type UseAssemblyChartOptions = {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getAssemblyChartQueryOptions>;
}

export const useAssemblyChart = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseAssemblyChartOptions) => {
    return useQuery({
        ...getAssemblyChartQueryOptions({ brand, assembly_item, period, startDate, endDate }),
        ...queryConfig,
    })
}