export type noDataProps = {
    title: string;
    description: string;
    action: string;
}

// Sales data is guarantee to be shown for listed brand
const dashboardSalesLine: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const dashboardAdsLine: noDataProps = {
    title: "Oops!",
    description: "No advertising data for the current period",
    action: "",
}

const dashboardPurchaseForecast: noDataProps = {
    title: "Almost there!",
    description: "Your forecast is still being prepared",
    action: "",
}

const dashboardAdsPie: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const dashboardCustomerBar: noDataProps = {
    title: "Grant Rigle Access",
    description: "We are not able to show any data unless you grant us the permissions",
    action: "Grant permission",
}

const dashboardAdsFunnel: noDataProps = {
    title: "Oops!",
    description: "No advertising data for the current period",
    action: "",
}

const salesLine: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const salesBar: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const adsLine: noDataProps = {
    title: "Oops!",
    description: "No advertising data for the selected period",
    action: "",
}

const adsBar: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const adsFunnel: noDataProps = {
    title: "Oops!",
    description: "No advertising data for the selected period",
    action: "",
}

const purchaseForecast: noDataProps = {
    title: "Almost there!",
    description: "Your forecast is still being prepared",
    action: "",
}

const componentSalesLine: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const componentPurchaseForecast: noDataProps = {
    title: "Almost there!",
    description: "Your forecast is still being prepared",
    action: "",
}

const assemblyCustomerBar: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const assemblySalesLine: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const assemblyAdsLine: noDataProps = {
    title: "Oops!",
    description: "No advertising data for the selected period",
    action: "",
}

const assemblyAdsBar: noDataProps = {
    title: "",
    description: "",
    action: "",
}

const assemblyAdsFunnel: noDataProps = {
    title: "Oops!",
    description: "No advertising data for the selected period",
    action: "",
}


export {
    dashboardSalesLine,
    dashboardAdsLine,
    dashboardPurchaseForecast,
    dashboardAdsPie,
    dashboardCustomerBar,
    dashboardAdsFunnel,
    salesLine,
    salesBar,
    adsLine,
    adsBar,
    adsFunnel,
    purchaseForecast,
    componentSalesLine,
    componentPurchaseForecast,
    assemblyCustomerBar,
    assemblySalesLine,
    assemblyAdsLine,
    assemblyAdsBar,
    assemblyAdsFunnel,
}