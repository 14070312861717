import {ProductDetailLinkResponse} from "@/types";
import {api} from "@/lib/api-client";
import {queryOptions, useQuery} from "@tanstack/react-query";
import {QueryConfig} from "@/lib/react-query";

export const getProductsItems = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<ProductDetailLinkResponse> => {
    return api.get(`/products/items`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getProductsItemsQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["products", "items", brand, period, startDate, endDate],
        queryFn: () => getProductsItems({ brand, period, startDate, endDate }),
    })
}

type UseProductsItemsOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getProductsItemsQueryOptions>;
}

export const UseProductsItems = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig
}: UseProductsItemsOptions) => {
    return useQuery({
        ...getProductsItemsQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}