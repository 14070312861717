import {queryOptions, useQuery} from "@tanstack/react-query";

import {PurchaseChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getPurchaseChart = ({
    brand,
    month,
}: {
    brand: string;
    month?: number;
}): Promise<PurchaseChartResponse> => {
    return api.get(`/purchase/chart`, {
        params: {
            brand,
            month,
        },
    });
}

export const getPurchaseChartQueryOptions = ({
    brand,
    month,
}: {
    brand: string;
    month?: number;
}) => {
    return queryOptions({
        queryKey: ["purchase", "chart", brand, month],
        queryFn: () => getPurchaseChart({ brand, month }),
    })
}

type UsePurchaseChartOptions = {
    brand: string;
    month?: number;
    queryConfig?: QueryConfig<typeof getPurchaseChartQueryOptions>;
}

export const usePurchaseChart = ({
    brand,
    month,
    queryConfig
}: UsePurchaseChartOptions) => {
    return useQuery({
        ...getPurchaseChartQueryOptions({ brand, month }),
        ...queryConfig
    })
}