import * as React from "react";

import {useAdvertising} from "@/features/advertising/api/get-advertising";
import SummaryCard from "@/components/ui/card/summary-card/summary-card";
import {useBrand} from "@/app/providers/brand";

export const AdvertisingCard = () => {
    const { brand } = useBrand();

    const advertisingQuery = useAdvertising({
        brand: brand,
    })

    if (advertisingQuery.isLoading) {
        return <div>Advertisement Card Loading...</div>
    }

    if (!advertisingQuery.data) return null;

    return (
        <SummaryCard
            data={advertisingQuery.data}
        />
    );
}