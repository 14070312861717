import React, {useEffect, useRef, useState} from "react";
import {flexRender, Header} from "@tanstack/react-table";
import {FormatType, formatValue} from "@/utils/formatter";
import Input from "@/components/ui/input/input";

const ControlHandle = ({
    leftMargin,
    isLeft,
    isActive,
    isOverlap,
    value,
}: {
    leftMargin: number;
    isLeft: boolean;
    isActive: boolean;
    isOverlap: boolean;
    value: number;
}) => {
    return (
        <div
            className={`absolute w-6 h-6 z-10 -ml-3 rounded-full border-[2px] ${isActive ? "border-Primary-500" : "border-Grey-600"} bg-Grey-700 shadow-md`}
            style={{left: `${leftMargin}%`}}
        >
            <span
                className={`absolute ${isLeft ? "left-0": "right-0"} w-full ${isOverlap ? isLeft ? "-translate-x-full" : "translate-x-full" : ""} translate-y-[24px] text-Base-white text-center text-sm`}
            >
                {formatValue(value, FormatType.NumberSuffix)}
            </span>
        </div>
    );
}

const RangeSlider = ({
                         min,
                         max,
                         value,
                         step = 1,
                         onChange,
                         header,
                         isActive,
                         setActive,
                     }: {
    min: number;
    max: number;
    value: { min: number, max: number } | null;
    step?: number;
    onChange: ({min, max}: { min: number, max: number }) => void;
    header: Header<any, any>;
    isActive: boolean;
    setActive: (value: boolean) => void;
}) => {
    const [minValue, setMinValue] = useState(value ? value.min : min);
    const [maxValue, setMaxValue] = useState(value ? value.max : max);

    useEffect(() => {
        if (value) {
            setMinValue(value.min);
            setMaxValue(value.max);
        }
    }, [value]);

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof event === "object") {
            event.preventDefault();
            const newMinVal = Math.min(+event.target.value, maxValue - step);
            if (!value) {
                setMinValue(newMinVal);
            }
            onChange({ min: newMinVal, max: maxValue });
        }
    }

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof event === "object") {
            event.preventDefault();
            const newMaxVal = Math.max(+event.target.value, minValue + step);
            if (!value) {
                setMaxValue(newMaxVal);
            }
            onChange({ min: minValue, max: newMaxVal });
        }
    }

    const sliderRef = useRef<HTMLDivElement>(null);

    const minPos = ((minValue - min) / (max - min)) * 100;
    const maxPos = ((maxValue - min) / (max - min)) * 100;

    return (
        <div
            className={"flex flex-col items-start self-stretch gap-y-3.5"}
        >
            <span className={"self-stretch text-Grey-300 text-sm leading-[14px]"}>
                {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                )}
            </span>
            <div
                className={`w-full h-[50px] relative ${isActive ? "cursor-default" : "cursor-pointer"}`}
                ref={sliderRef}
                onClick={() => setActive(true)}
            >
                {/* hidden input slider */}
                <div className={`w-full absolute ${isActive ? "": "-z-10"}`}>
                    <Input
                        className={`input`}
                        type={"range"}
                        value={minValue}
                        min={min}
                        max={max}
                        step={step}
                        onChange={handleMinChange}
                    />
                    <Input
                        className={`input`}
                        type={"range"}
                        value={maxValue}
                        min={min}
                        max={max}
                        step={step}
                        onChange={handleMaxChange}
                    />
                </div>

                {/* Visual graphic slider */}
                <div className={"w-[calc(100%-24px)] ml-3 absolute"}>
                    {/* Left control handle */}
                    <ControlHandle
                        leftMargin={minPos}
                        isLeft={true}
                        isActive={isActive}
                        isOverlap={Math.abs(minPos - maxPos) < 15}
                        value={minValue}
                    />
                    {/* Progress bar */}
                    <div
                        className={`absolute top-1/2 w-full h-2 translate-y-full shrink-0 rounded-[9999px] ${isActive ? "bg-Grey-100" : "bg-Grey-700"}`}>
                        <div className={`absolute h-full rounded-[9999px] ${isActive ? "bg-Primary-500" : "bg-Grey-600"}`}
                             style={{left: `${minPos}%`, right: `${100 - maxPos}%`}}
                        />
                    </div>
                    {/* Right control handle */}
                    <ControlHandle
                        leftMargin={maxPos}
                        isLeft={false}
                        isActive={isActive}
                        isOverlap={Math.abs(minPos - maxPos) < 15}
                        value={maxValue}
                    />
                </div>
            </div>
        </div>
    );
}

export default RangeSlider;