import * as React from "react";
import RigleLogo from "@/assets/rigle-logo-auth.svg";

export function AuthLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className={"inline-flex items-center w-screen h-screen p-1.5 bg-Grey-950"}>
            <div className={"flex flex-col justify-center items-start self-stretch relative flex-1 p-8 gap-[140px]"}>
                <div className={"flex items-center absolute top-[32px] left-[44px] gap-[13px]"}>
                    <span
                        className={"text-xl leading-normal font-black italic tracking-[-0.209px] uppercase bg-gradient-to-bl from-Primary-600 to-Secondary-500 bg-clip-text text-transparent select-none"}>
                        accelerate
                    </span>
                </div>
                <div className={"flex justify-center self-stretch px-8"}>
                    <div className={"flex flex-col items-center self-stretch gap-8 min-w-72 max-w-[360px]"}>
                        {children}
                    </div>
                </div>
            </div>
            <div
                className={"flex justify-center items-center self-stretch w-[714px] rounded-[14px] bg-gradient-to-bl from-Primary-600 to-Secondary-500"}>
                <img className={"select-none"} src={RigleLogo} alt={"RigleLogo"}/>
            </div>
        </div>
    );
}
