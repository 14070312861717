
export enum FormatType {
    Currency,
    Number,
    Integer,
    Float,
    Percent,
    NumberSuffix,

    DateForQuery,
    LongDate,
    ShortDate,
    ShortMonth,

    StrAllCaps,
    StrProper,
}

export const formatValue = (value: any, format: FormatType, options: Intl.NumberFormatOptions = {}) => {
    if (typeof value === 'number') {
        switch (format) {
            case FormatType.Currency:
                return currencyF(value);
            case FormatType.Number:
                return numberF(value);
            case FormatType.Integer:
                return integerF(value);
            case FormatType.Float:
                return floatF(value);
            case FormatType.Percent:
                return percentF(value);
            case FormatType.NumberSuffix:
                return numberSuffixF(value);
            default:
                return value.toString();
        }
    } else if (typeof value === 'string') {
        switch (format) {
            case FormatType.StrAllCaps:
                return strAllCapsF(value);
            case FormatType.StrProper:
                return strProperF(value);
            default:
                return value.toString();
        }
    } else if (value instanceof Date) {
        switch (format) {
            case FormatType.DateForQuery:
                return ISODateF(value);
            case FormatType.LongDate:
                return dateF(value, { year: "numeric", month: "short", day: "numeric" });
            case FormatType.ShortDate:
                return dateF(value, { month: "numeric", day: "numeric" });
            case FormatType.ShortMonth:
                return dateF(value, { month: "short" });
            default:
                return value.toString();
        }
    } else {
        console.log('Unsupported format for ' + value);
        return "";
    }
}

export const currencyF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { style = 'currency', currency = 'USD', minimumFractionDigits = 2, maximumFractionDigits = 2 } = options;

    return new Intl.NumberFormat('en-US', {
        style,
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}

export const numberF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { minimumFractionDigits = 0, maximumFractionDigits = 0 } = options;

    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}

export const integerF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { minimumFractionDigits = 0, maximumFractionDigits = 0 } = options;

    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}

export const floatF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { minimumFractionDigits = 2, maximumFractionDigits = 2 } = options;

    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}

export const percentF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { style = 'percent', minimumFractionDigits = 2, maximumFractionDigits = 2 } = options;

    return new Intl.NumberFormat('en-US', {
        style,
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}

export const numberSuffixF = (value: number, options: Intl.NumberFormatOptions = {}) => {
    const thresholds = [
        { val: 1_000_000_000, suffix: 'B' },
        { val: 1_000_000, suffix: 'M' },
        { val: 1_000, suffix: 'K' },
    ];

    for (const { val, suffix } of thresholds) {
        if (value >= val) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 }).format(value / val) + suffix;
        }
    }

    return new Intl.NumberFormat('en-US', {}).format(value);
}

export const ISODateF = (value: Date | undefined, options: Intl.DateTimeFormatOptions = {}) => {
    return value?.toISOString().split('T')[0] ?? "";
}

export const dateF = (value: Date | undefined, options: Intl.DateTimeFormatOptions = {}) => {
    const { year, month, day } = options;

    return new Intl.DateTimeFormat('en-US', {
        timeZone: 'Etc/UTC',
        month: month,
        day: day,
        year: year,
    }).format(value);
}

export const strAllCapsF = (value: string) => {
    return value.toLocaleUpperCase('en-US');
}

export const strProperF = (value: string) => {
    return value.toLocaleLowerCase('en-US')
        .replace(/\b\w/g, (char) => char.toLocaleUpperCase('en-US'));
}