import {useAssemblyOrganicBar} from "@/features/assembly/api/get-assembly-organic-bar";
import {MonthlySummaryBarChart} from "@/components/ui/chart/recharts/bar/bar";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {FormatType} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";

export const AssemblyOrganicBar = ({
    assembly_item,
    height = "20.8rem",
}: {
    assembly_item: string;
    height?: string;
}) => {
    const { brand } = useBrand();

    const assemblyOrganicBarQuery = useAssemblyOrganicBar({
        brand,
        assembly_item,
    });

    if (assemblyOrganicBarQuery.isLoading) {
        return <div>Assembly Organic Bar Loading...</div>;
    }

    if (!assemblyOrganicBarQuery.data) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "organic sales",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
            stackId: "a",
        },
        {
            name: "ad sales",
            yAxisId: "left",
            color: colors.secondary["500"],
            format: FormatType.Currency,
            stackId: "a",
        },
    ];

    const series = assemblyOrganicBarQuery.data.map(({ organic, ad_sales, ...rest }) => ({
        ...rest,
        "organic sales": organic,
        "ad sales": ad_sales,
    }));

    return (
        // <MultipleAxisTimeValueBarChart
        //     title={"Organic Summary"}
        //     labels={[
        //         { name: "Organic", position: "left" },
        //         { name: "Ad Sales", position: "right" },
        //     ]}
        //     series={[
        //         assemblyOrganicBarQuery.data.map((row) => [row.date, row.organic]),
        //         assemblyOrganicBarQuery.data.map((row) => [row.date, row.ad_sales]),
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        //     stacked={true}
        // />
        <MonthlySummaryBarChart
            title={"Organic & Ad Summary"}
            labels={labels}
            series={series}
        />
    );
}