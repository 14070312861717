import * as React from "react";
import {useEffect, useRef} from "react";
import {FormatType, formatValue} from "@/utils/formatter";
import {colors} from "@/styles/colors";
import {cn} from "@/utils/cn";
import {dataPosProps, multipleAxisLabel} from "@/components/ui/chart/charts";
import Button from "@/components/ui/button/button";
import GrantPermissionIcon from "@/assets/icons/button/grant-permission.svg";
import {noDataProps} from "@/content/chart/no-data";

export const numberInThousandsF = (value: number): string => {
    if (value >= 1000) {
        return `${value / 1000}k`;
    }
    return '' + value;
}

export const legendBox = (label: string) => {
    if (!label) return label;

    return (
        <span className={"justify-center text-Grey-300 text-sm text-center leading-[20px]"}>
            {label === "roas" ? formatValue(label, FormatType.StrAllCaps) : formatValue(label, FormatType.StrProper)}
        </span>
    );
}

type CustomActiveDotProps = {
    cx: number;
    cy: number;
    value: number;
    type: FormatType;
    selectedColor: string;
    reverse?: boolean;
}

const TooltipGContent = React.forwardRef<SVGGElement, CustomActiveDotProps>((
    { cx, cy, value, type, selectedColor, reverse = false },
    ref
) => {
    // constants
    const HORIZONTAL_PADDING = 12;
    const VERTICAL_PADDING = 8;
    const DOT_RADIUS = 4;
    const DOT_STROKEWIDTH = 2;

    const strValue = formatValue(value, type)

    const rectHeight = 18 + 2 * VERTICAL_PADDING;
    const rectWidth = strValue.toString().length * 7 + 2 * HORIZONTAL_PADDING;

    const horizontalMargin = 5.5 + DOT_RADIUS + DOT_STROKEWIDTH; // Margin between dot and tooltip

    // pos for pointy head of triangle shape
    const triangleHeadX = reverse ? cx - horizontalMargin : cx + horizontalMargin;
    const triangleEndX = reverse ? triangleHeadX - 6 : triangleHeadX + 6;
    const triangleTopY = cy + DOT_RADIUS;
    const triangleBottomY = cy - DOT_RADIUS;
    // Simply display tooltips to the left/right of the active dot
    const tooltipX = reverse ? triangleEndX - rectWidth : triangleEndX;
    const tooltipY = cy - rectHeight / 2;

    return (
        <g>
            {/* Dot */}
            <circle
                cx={cx}
                cy={cy}
                r={DOT_RADIUS}
                stroke={selectedColor}
                strokeWidth={DOT_STROKEWIDTH}
                fill={colors.grey["950"]}
            />

            {/* Tail of the dialog box */}
            <polygon
                points={`${triangleHeadX},${cy} ${triangleEndX},${triangleTopY} ${triangleEndX},${triangleBottomY}`}
                fill={selectedColor}
                stroke={selectedColor}
                strokeWidth={1}
            />

            {/* Dialog box background rectangle */}
            <g transform={`translate(${tooltipX}, ${tooltipY})`}>
                <rect
                    x={0}
                    y={0}
                    width={rectWidth}
                    height={rectHeight}
                    rx={8}
                    fill={selectedColor}
                    stroke={selectedColor}
                    strokeWidth={1}
                />
                <text
                    x={rectWidth / 2}
                    y={rectHeight / 2}
                    textAnchor="middle"
                    dominantBaseline="central"
                    className="text-Grey-950 text-xs font-semibold"
                >
                    {strValue}
                </text>
            </g>
        </g>
    );
});

type TooltipContentProps = React.HTMLAttributes<HTMLDivElement> & {
    value: string;
    selectedColor: string;
    activePos: dataPosProps;
    margin: {left: number; right: number};
    reverse?: boolean;
};

const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>(
    (
        {
            className,
            value,
            selectedColor,
            activePos,
            margin,
            reverse = false,
            ...props
        },
        ref
    ) => {
        const dialogBoxHeight = 34;
        const horizontalMargin = 13.5;
        const triangleHeight = 6;

        const dialogBoxRef = React.useRef<HTMLDivElement>(null);
        const [dialogBoxWidth, setDialogBoxWidth] = React.useState(0);

        useEffect(() => {
            if (dialogBoxRef.current) {
                setDialogBoxWidth(dialogBoxRef.current.clientWidth);
            }
        }, [value]);

        const leftPosX = Math.max(activePos.x - dialogBoxWidth - horizontalMargin - 2 + (activePos.offsetX ?? 0), margin.left - dialogBoxWidth);
        const rightPosX = Math.min(activePos.x + horizontalMargin + (activePos.offsetX ?? 0), margin.right - dialogBoxWidth - 4);

        return (
            <div className={cn(className, `relative`)}
                 style={{
                     top: activePos.y + (activePos.offsetY ?? 0) - dialogBoxHeight / 2,
                     left: reverse ? leftPosX : rightPosX,
                 }}
            >
                <div
                    className={`absolute`}
                    style={{
                        top: dialogBoxHeight * 0.37,
                        left: reverse ? dialogBoxWidth - 4 : -triangleHeight - 3,
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: `${triangleHeight}px ${triangleHeight}px 0 ${triangleHeight}px`,
                        borderColor: `${selectedColor} transparent transparent transparent`,
                        transform: reverse ? "rotate(-90deg)" : "rotate(90deg)",
                    }}
                />

                <div className={`absolute flex z-2 py-2 px-3 rounded-lg text-Grey-950 text-xs font-semibold`}
                     style={{ backgroundColor: selectedColor }}
                     ref={dialogBoxRef}
                >
                    {value}
                </div>
            </div>
        );
});

const apartIfCollision = ({
    dataKey,
    activePos,
}: {
    dataKey: string;
    activePos: {[key: string]: dataPosProps};
}) => {
    const dialogBoxHeight = 34;

    // solve roas collision
    if (dataKey === "roas"){
        const y = activePos[dataKey].y;
        const spendY = activePos["spend"].y;
        const distance = Math.abs(y - spendY);

        if (distance > dialogBoxHeight) return;

        if (y - spendY <= 0) { // go up
            activePos[dataKey].offsetY = -(dialogBoxHeight - distance);
        } else { // go down
            activePos[dataKey].offsetY = dialogBoxHeight - distance;
        }
    }
}

export const CustomTooltip = (props: any) => {
    const { active, payload, labels, activePos, viewBox } = props;

    if (active && payload && payload.length && activePos) {
        // Tooltips will be shown in order Left, Right, Left, ...
        // Left = reverse True
        const keyProps = labels.reduce((acc: { [key: string]: Omit<multipleAxisLabel, "name"> }, label: multipleAxisLabel) => {
            const { name, ...rest } = label;
            acc[name] = rest;
            return acc;
        }, {})

        return (
            <>
                {payload.map((item: any, index: number) => {
                    const dataKey = item.dataKey;

                    apartIfCollision({dataKey, activePos});
                    return (
                        <TooltipContent
                            key={index}
                            value={formatValue(item.value, keyProps[dataKey].format)}
                            selectedColor={keyProps[dataKey].color}
                            activePos={activePos[dataKey]}
                            reverse={keyProps[dataKey].tooltipReversed}
                            margin={{left: viewBox.left, right: viewBox.width + viewBox.left + viewBox.right + 28}}
                        />
                    );
                })}
            </>
        );
    }

    return null;
}

export const ChartTitle = ({
    icon,
    title,
    period,
}: {
    icon: string | undefined;
    title: string;
    period: string | undefined;
}) => {
    return (
        <div className={"flex flex-row justify-between flex-1"}>
            <div className={"flex flex-row items-center gap-x-3"}>
                <div className={"p-1.5 bg-Grey-900 border-[1px] rounded-md border-Grey-800"}>
                    <img src={icon} alt={"Chart Title Icon"}/>
                </div>
                <span className={"text-Grey-100 text-base font-normal leading-normal"}>
                    {title}
                </span>
            </div>
            {period && (
                <div className={"pl-3.5 pr-[18px] py-2.5 bg-Grey-900 border-[1px] rounded-md border-Grey-800 " +
                    "shadow-xs"}>
                    <span className={"text-Grey-300 text-sm font-normal leading-normal tracking-[-0.28px]"}>
                        {period}
                    </span>
                </div>
            )}
        </div>
    );
}

export const ChartSupplementaryFields = ({
    mainKPI,
    subtitle,
    value,
}: {
    mainKPI: string;
    subtitle: string;
    value: string;
}) => {
    return (
        <div className={"flex flex-col gap-y-3"}>
            <span className={"text-Base-white text-[34px] font-bold leading-[36px] tracking-[-0.68px]"}>
                {mainKPI}
            </span>
            <div className={"flex flex-row gap-x-2"}>
                <span
                    className={"overflow-hidden text-Grey-300 text-overflow-ellipsis text-sm font-normal leading-[20px]"}>
                    {subtitle}
                </span>
                <span className={"text-Green-700 text-center text-sm font-normal leading-[20px]"}>
                    {value}
                </span>
            </div>
        </div>
    );
}

export const NoDataGraphic = ({
    content
}: {
    content: noDataProps;
}) => {
    return (
        <div className={"flex flex-col bottom-24 absolute -mx-7 w-full items-center text-center text-base font-normal leading-[20px]"}>
            <span className={"text-white pb-2"}>
                {content.title}
            </span>
            <span className={"w-[321px] text-Grey-400 pb-5"}>
                {content.description}
            </span>
            {!!content.action && (
                <Button
                    icon={<img src={GrantPermissionIcon} alt={"ActionIcon"}/>}
                    variant={"formSubmit"}
                    size={"regular"}
                >
                    {content.action}
                </Button>
            )}
        </div>
    );
}