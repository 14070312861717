import * as React from "react";

import {FormatType} from "@/utils/formatter";
import SalesIcon from "@/assets/icons/card/sales.svg";
import UnitsIcon from "@/assets/icons/card/units.svg";
import AOVIcon from "@/assets/icons/card/aov.svg";
import Last7DIcon from "@/assets/icons/card/last7d.svg";
import Last30DIcon from "@/assets/icons/card/last30d.svg";
import Last365Icon from "@/assets/icons/card/last365d.svg";


const SingleItemCardInfo: {[key: string]: {format: FormatType, icon: JSX.Element}} = {
    "Sales": {
        format: FormatType.Currency,
        icon: <img src={SalesIcon} alt={"SalesIcon"}/>,
    },
    "Units": {
        format: FormatType.Integer,
        icon: <img src={UnitsIcon} alt={"UnitsIcon"}/>,
    },
    "Average Order Value": {
        format: FormatType.Currency,
        icon: <img src={AOVIcon} alt={"AOVIcon"}/>,
    },
};

const MultipleItemCardInfo: {[key: string]: {format?: FormatType, icon?: JSX.Element}} = {
    "Last 7 Days": {
        icon: <img src={Last7DIcon} alt={"Last7DIcon"}/>,
    },
    "Last 30 Days": {
        icon: <img src={Last30DIcon} alt={"Last30DIcon"}/>,
    },
    "Last 365 Days": {
        icon: <img src={Last365Icon} alt={"Last365Icon"}/>,
    },
    "Sales": {
        format: FormatType.Currency,
    },
    "Units": {
        format: FormatType.Integer,
    },
    "Spends": {
        format: FormatType.Currency,
    }
};


export {
    SingleItemCardInfo,
    MultipleItemCardInfo,
}