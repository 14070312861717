import {usePurchaseChart} from "@/features/purchase/api/get-purchase-chart";
import {MonthlySummaryBarChart} from "@/components/ui/chart/recharts/bar/bar";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {FormatType, formatValue} from "@/utils/formatter";
import {ChartSupplementaryFields, NoDataGraphic} from "@/components/ui/chart/recharts/recharts";
import {useBrand} from "@/app/providers/brand";
import {barMock} from "@/components/ui/chart/mock";
import {purchaseForecast} from "@/content/chart/no-data";


export const PurchaseChart = ({
    height = "20.8rem",
    month = 13,
}:{
    height?: string;
    month?: number;
}) => {
    const { brand } = useBrand();

    const purchaseChartQuery = usePurchaseChart({
        brand,
        month,
    });

    if (purchaseChartQuery.isLoading) {
        return <div>Purchase Chart Loading...</div>;
    }

    if (!purchaseChartQuery.data) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "amount",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "quantity",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Integer,
        },
    ];

    if (purchaseChartQuery.data.summary.length === 0) {
        return (
            <MonthlySummaryBarChart
                title={"Purchase Forecast"}
                labels={labels}
                series={barMock}
                noDataGraphic={<NoDataGraphic content={purchaseForecast} />}
            />
        );
    }

    // rename data fields if applicable
    const series = purchaseChartQuery.data.summary.map(({ time, ...rest }) => ({
        ...rest,
        date: time,
    }));

    const totalAmount = series.reduce((sum, serie) => sum + serie.amount, 0);
    const averageAmount = totalAmount / series.length;

    return (
        // <MultipleAxisTimeValueBarChart
        //     title={"Purchase Forecast"}
        //     labels={[
        //         { name: "Amount", position: "left" },
        //         { name: "Quantity", position: "right" },
        //     ]}
        //     series={[
        //         purchaseChartQuery.data.summary.map((row) => [row.time, row.amount]),
        //         purchaseChartQuery.data.summary.map((row) => [row.time, row.quantity]),
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <MonthlySummaryBarChart
            title={"Purchase Forecast"}
            labels={labels}
            series={series}
            chartSupplementaryFields={(
                <ChartSupplementaryFields
                    mainKPI={formatValue(totalAmount, FormatType.Currency)}
                    subtitle={"Average Amount"}
                    value={formatValue(averageAmount, FormatType.Currency)}
                />
            )}
        />
    );
}