import * as React from "react";
import {HTMLAttributes} from "react";

import {cn} from "@/utils/cn";
import Button from "@/components/ui/button/button";
import {ButtonProps} from "@/components/ui/button/button";
import ColumnSelector from "@/assets/icons/table/column-selector.svg";
import BrandSelector from "@/assets/icons/brand/brand-arrow-down.svg";

const DropdownMenu  = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <div ref={ref} className={cn("", className)} {...props} />
    )
);
DropdownMenu.displayName = "Dropdown"

const DropdownTrigger = React.forwardRef<HTMLButtonElement, ButtonProps & { isOpen?: boolean; hideIcon?: boolean }>(
    (
        {
            isOpen,
            className,
            children,
            hideIcon = false,
            ...props
        },
        ref
    ) => (
        <Button
            ref={ref}
            aria-haspopup="menu"
            aria-label="Dropdown"
            className={cn("", className)}
            {...props}
        >
            <span className={"flex items-center w-full gap-x-1.5"}>
                {children}
                {!hideIcon &&
                    (isOpen ? (
                        <DropdownIconDown className={"rotate-180"} />
                    ) : (
                        <DropdownIconDown />
                    ))
                }
            </span>
        </Button>
    )
);
DropdownTrigger.displayName = "DropdownTrigger";

const DropdownIconDown = React.forwardRef<HTMLImageElement, React.HTMLAttributes<HTMLImageElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <img className={cn("", className)} src={ColumnSelector} alt={"DropDownSelector"}/>
    )
);
DropdownIconDown.displayName = "DropdownIconDown";

const DropdownArrowIconDown = React.forwardRef<HTMLImageElement, HTMLAttributes<HTMLImageElement> & {isActive: boolean}>(
    (
        {
            className,
            isActive,
            ...props
        },
        ref
    ) => (
        <div className={`flex items-center p-2 gap-2.5 ${isActive ? "rounded-[30px] bg-Grey-800": ""}`}>
            <img className={cn(`rounded-full ${isActive ? "rotate-180" : ""}`, className)}
                 src={BrandSelector} alt={"BrandSelector"}/>
        </div>
    )
);
DropdownArrowIconDown.displayName = "DropdownArrowIconDown";

const DropdownMenuContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <div ref={ref}
             className={cn("", className)}
             role="menu"
             aria-orientation="vertical"
             aria-labelledby="hs-dropdown-example"
             {...props}
        />
    )
);
DropdownMenuContent.displayName = "DropdownMenuContent";

type DropdownMenuItemProps = React.HTMLAttributes<HTMLButtonElement> &{
    value?: string;
}

const DropdownMenuItem = React.forwardRef<HTMLButtonElement, DropdownMenuItemProps>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <div className={"flex items-center self-stretch px-1.5 py-[1px]"}>
            <Button
                ref={ref}
                variant={"dropdownMenuItem"}
                onClick={props.onClick}
                {...props}
            />
        </div>
    )
);
DropdownMenuItem.displayName = "DropdownMenuItem";

const DropdownMenuItemCheck = React.forwardRef<HTMLOrSVGElement, React.HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6666 5L7.49992 14.1667L3.33325 10" stroke="#8CF222" strokeWidth="1.66667"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
);

export {DropdownMenu, DropdownArrowIconDown, DropdownIconDown, DropdownTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuItemCheck};