import React from "react";

import {Range} from "react-date-range";

import {useSales} from "@/features/sales/api/get-sales";
import {SimpleCard} from "@/components/ui/card/simple-card/simple-card";
import {useBrand} from "@/app/providers/brand";


export const SalesCard = ({
    dateRange,
}: {
    dateRange: Range[];
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const salesQuery = useSales({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (salesQuery.isLoading) {
        return <div>Sales Card Loading...</div>;
    }

    if (!salesQuery.data) return null;

    return (
        <SimpleCard
            data={salesQuery.data}
        >
        </SimpleCard>
    )
}