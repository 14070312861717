import * as React from "react";
import {useLocation} from "react-router-dom";

import {Card, CardContent} from "@/components/ui/card/card";
import {FormatType, formatValue} from "@/utils/formatter";
import {NewTabButton} from "@/components/ui/button/newtab-button/newtab-button";
import ViewDetailIcon from "@/assets/icons/table/view-detail.svg";
import CategoryRankIcon from "@/assets/icons/card/category-rank.svg";
import ExternalLinkIcon from "@/assets/icons/table/external-link.svg";
import SalesGreenIcon from "@/assets/icons/card/sales-green.svg";
import {CellViewDetail} from "@/components/ui/table/tanStack/cell-render";
import {jumpLinkViewDetail} from "@/utils/page-trace";
import {useBrand} from "@/app/providers/brand";


const CarouselSmallCard = ({
    serie,
}: {
    serie: any;
}) => {
    const location = useLocation();
    const {brand, setBrand} = useBrand();

    return (
        <Card variant={"carousel_sm"} wrapperVariant={"carousel_sm"}>
            <div className={"flex flex-col items-center gap-1"}>
                <img className={"flex w-[88px] justify-center items-center rounded-lg pointer-events-none"} src={serie.img} alt={"card avatar"}/>
            </div>
            <CardContent variant={"carousel_sm"}>
                <span className={"w-[320px] line-clamp-2 text-Grey-100 text-sm font-medium"}>
                    {serie.listing_title}
                </span>
                <div className={"flex justify-between items-center self-stretch"}>
                    <span className={"text-Green-700 text-sm font-medium"}>
                        {formatValue(serie.sales, FormatType.Currency)}
                    </span>
                    <NewTabButton
                        url={jumpLinkViewDetail({view_link: serie.view_link, location, brand})}
                    >
                        <div className={"flex items-center gap-[7px] py-1.5 px-2.5 rounded-[10px] border border-Grey-800"}>
                            <span className={"text-Grey-500 text-sm"}>
                                View Details
                            </span>
                            <img src={ViewDetailIcon} alt={"ViewDetailIcon"}/>
                        </div>
                    </NewTabButton>
                </div>
            </CardContent>
        </Card>
    );
}

const CarouselMediumCard = ({
    serie
}: {
    serie: any;
}) => {
    return (
        <Card variant={"carousel_md"} wrapperVariant={"carousel_md"}>
            <div className={`flex justify-center items-center gap-2.5 w-[224px] h-[224px] rounded-lg ${serie.status === "Inactive" ? " opacity-50": ""} bg-Base-white`}>
                <img className={"pointer-events-none w-[176px] h-[181px] shrink-0 rounded-lg"} src={serie.img} alt={"product image"} />
            </div>
            <CardContent className={`${serie.status === "Inactive" ? " opacity-60": ""}`} variant={"carousel_md"}>
                {/* Category rank */}
                <div className={"flex justify-between items-center self-stretch rounded-[10px]"}>
                    <span className={"text-Grey-200 text-sm font-medium"}>
                        {serie.category}
                    </span>
                    <div className={"flex items-center gap-[1px] rounded-full"}>
                        <img src={CategoryRankIcon} alt={"CategoryRankIcon"}/>
                        <span className={"text-Green-700 text-sm font-medium"}>
                            {String(serie.category_rank)}
                        </span>
                    </div>
                </div>
                {/* IDs */}
                <div className={"flex flex-col items-start self-stretch gap-2.5"}>
                    <a
                        href={serie.amazon_link}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        className={"flex justify-center items-center gap-1.5"}
                    >
                            <span className={"text-Grey-400 text-sm"}>
                               {serie.asin}
                            </span>
                        <img className={""} src={ExternalLinkIcon} alt={"ExternalLinkIcon"}/>
                    </a>
                    <span className={"self-stretch line-clamp-3 text-Grey-100 text-base font-medium leading-[22px]"}>
                        {serie.listing_title}
                    </span>
                </div>
                {/* KPIs */}
                <div className={"flex justify-between items-start self-stretch"}>
                    <div className={"flex items-center gap-4 h-10 py-[1px]"}>
                        <div className={"flex justify-center items-center gap-1.5 py-2 pl-2.5 pr-3.5 rounded-[10px] bg-[#182D19]"}>
                            <img src={SalesGreenIcon} alt={"SalesGreenIcon"} />
                            <span className={"text-Green-700 text-center text-base leading-[20px]"}>
                                {formatValue(serie.sales, FormatType.Currency)}
                            </span>
                        </div>
                        <div className={"w-0 h-[22px] border border-Grey-800"} />
                        <span className={"text-Grey-500 text-base leading-[20px]"}>
                            Units:&nbsp;{formatValue(serie.units, FormatType.Integer)}
                        </span>
                    </div>
                    {serie.status === "Active" && (
                        <CellViewDetail url={serie.view_link} />
                    )}
                </div>
            </CardContent>
        </Card>
    );
}

export {
    CarouselSmallCard,
    CarouselMediumCard,
};