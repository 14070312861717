import {Range} from "react-date-range";

import {useAssemblyFunnel} from "@/features/assembly/api/get-assembly-funnel";
import {AdsFunnelChart} from "@/components/ui/chart/recharts/funnel/funnel";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";


export const AssemblyFunnel = ({
    assembly_item,
    dateRange,
    height = "21.4rem",
}: {
    assembly_item: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const assemblyFunnelQuery = useAssemblyFunnel({
        brand,
        assembly_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (assemblyFunnelQuery.isLoading) {
        return <div>Assembly Funnel Loading...</div>;
    }

    if (!assemblyFunnelQuery.data  || assemblyFunnelQuery.data.length === 0) return null;

    const series = assemblyFunnelQuery.data.map(({ value, ...rest }, index, array) => ({
        ...rest,
        value: value,
        nextValue: array[index + 1]?.value || value,
        rate: value / (array[index - 1]?.value || 1),
    }));

    return (
        // <FunnelChart
        //     title={"Ad Funnel"}
        //     series={assemblyFunnelQuery.data.map(row => [row.name, row.value])}
        //     height={height}
        //     colors={[
        //         echartColors.brown,
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <AdsFunnelChart
            title={"Ad Funnel Insight"}
            series={series}
            period={formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate)}
        />
    );
}