import * as React from "react";
import {useEffect, useState} from "react";

import Button from "@/components/ui/button/button";
import SearchLgIcon from "@/assets/icons/table/search-lg.svg";
import DownLoadIcon from "@/assets/icons/table/download.svg";
import {Column, HeaderGroup, RowData, Table} from "@tanstack/react-table";
import SearchBarDropdown from "@/components/ui/dropdown/table/search-bar";
import {generatePageNumbers, PageButton, RenderJumpPages} from "@/components/ui/table/tanStack/table-paginator";
import DebouncedInput from "@/components/ui/input/debounced/input";
import TableNoDataImg from "@/assets/icons/table/nodata.svg";


const TableTitle = ({
    title,
    period,
}: {
    title: string;
    period?: string;
}) => {
    return (
        <div className={"flex items-center"}>
            <span className={"text-Base-white text-lg font-bold leading-7"}>
                {title}
            </span>
            {period && (
                <>
                    <span className={"mx-[18px] h-3.5 border-Base-white opacity-20 border-[1px]"}/>
                    <div className={"pl-3.5 pr-[18px] py-2.5 bg-Grey-900 border-[1px] rounded-md border-Grey-800 " +
                        "shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"}>
                    <span
                        className={"text-Grey-300 text-sm font-normal leading-normal tracking-[-0.28px]"}>
                        {period}
                    </span>
                    </div>
                </>
            )}
        </div>
    );
}

const SearchBar = ({
    defaultSearchColumn,
    headerGroups,
}: {
    headerGroups: HeaderGroup<RowData>[];
    defaultSearchColumn: string;
}) => {
    const [searchColumn, setSearchColumn] = useState<string>(defaultSearchColumn);
    const [isFocused, setFocused] = React.useState<boolean>(false);

    const findColumn = (column: string) => {
        return headerGroups
            .flatMap(group => group.headers)
            .find(header => header.column.columnDef.header === column)?.column;
    }

    const [selectedColumn, setSelectedColumn] = React.useState<Column<any, any> | undefined>(findColumn(defaultSearchColumn));

    // Get the column based on the selected searchColumn
    useEffect(() => {
        const column = findColumn(searchColumn);

        column?.setFilterValue(selectedColumn?.getFilterValue());
        selectedColumn?.setFilterValue(null);
        setSelectedColumn(column);
    }, [searchColumn]);

    // Get the filter value of the selected column
    const columnFilterValue = selectedColumn ? selectedColumn.getFilterValue() : '';

    return (
        <div
            className={`flex justify-between items-center relative py-2 px-3.5 w-[320px] gap-x-2 self-stretch bg-Grey-900 rounded-lg border-[1px] ${isFocused ? "border-Grey-600" : "border-Grey-800"} hover:border-Grey-600`}>
            <div className={"flex items-center relative gap-x-2 flex-1"}>
                <img
                    className={"absolute"}
                    src={SearchLgIcon}
                    alt={"SearchLgIcon"}
                />
                <DebouncedInput
                    className={"pl-[26px] rounded-sm outline-none focus:ring-0 caret-Secondary-500 overflow-hidden text-Grey-100 text-ellipsis text-sm leading-[18px] placeholder:overflow-hidden placeholder:text-Grey-100 placeholder:text-ellipsis placeholder:text-sm placeholder:leading-[18px]"}
                    onChange={value => selectedColumn?.setFilterValue(value)}
                    placeholder={"Search"}
                    type={"text"}
                    value={(columnFilterValue ?? '') as string}
                    debounce={250}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                />
            </div>
            <SearchBarDropdown
                searchColumn={searchColumn}
                setSearchColumn={setSearchColumn}
                headerGroups={headerGroups}
            />
        </div>
    );
}

const DownloadButton = ({
                            handleExport,
}: {
    handleExport: () => void;
}) => {
    return (
        <Button
            className={"bg-Grey-900 rounded-lg border-[1px] border-Grey-800 hover:border-Grey-600"}
            onClick={handleExport}
        >
            <span className={"flex px-3.5 py-2.5 justify-center items-center gap-x-1"}>
                <img src={DownLoadIcon} alt={"DownloadIcon"}/>
                <span className={"text-Grey-100 text-sm"}>
                    Download
                </span>
            </span>
        </Button>
    );
}

const TablePaginator = ({
    table,
}: {
    table: Table<any>;
}) => {
    const totalPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex + 1;
    const setPage = (page: number) => table.setPageIndex(page-1);

    const pages = generatePageNumbers({currentPage, totalPages});

    const pageSize = table.getState().pagination.pageSize;
    const itemsCurrentPage = table.getRowModel().rows.length;
    const totalItems = table.getFilteredRowModel().rows.length;

    return (
        <div className={"flex flex-col flex-start self-stretch py-4 px-7 gap-3.5 bg-Grey-950 rounded-2xl"}>
            <div className={"flex justify-center items-center gap-x-3 self-stretch"}>
                <div className={`flex items-center flex-1`}>
                    <PageButton table={table} text={"Previous"} />
                </div>
                <div className={"flex flex-start gap-x-0.5"}>
                    {pages.map((page, index) => (
                        <RenderJumpPages
                            key={"page-" + page + index}
                            currentPage={currentPage}
                            setPage={setPage}
                            page={page}
                        />
                    ))}
                </div>
                <div className={`flex justify-end items-center flex-1`}>
                    <span className={"text-Grey-500 text-base leading-[20px]"}>
                        {`(${(currentPage - 1) * pageSize + 1 } - ${(currentPage - 1) * pageSize + itemsCurrentPage} of ${totalItems})`}
                    </span>
                    <div className={"ml-4 mr-1 w-0.5 h-3.5 bg-Base-white opacity-20"} />
                    <PageButton table={table} text={"Next"} isEnd={true} />
                </div>
            </div>
        </div>
    );
}

const TableNoDataRender = ({
    title = "No listed product to show",
    description = "Any active or inactive listing that you listed through Rigle would be shown here",
}: {
    title?: string;
    description?: string;
}) => {
    return (
        <div className={"flex items-center justify-center self-stretch h-[465px] px-[226px] py-[42px]"}>
            <div className={"flex flex-col items-center justify-center w-[727px] h-[381px] bg-Grey-950"}>
                <img src={TableNoDataImg} alt={"TableNoDataImg"}/>
                <div className={"text-Base-white text-base leading-[20px]"}>
                    {title}
                </div>
                <div className={"w-[306px] text-Grey-400 text-center text-sm"}>
                    {description}
                </div>
            </div>
        </div>
    );
}

export {
    TableTitle,
    SearchBar,
    DownloadButton,
    TablePaginator,
    TableNoDataRender,
};