

const adBlockAccess = "Start advertising with Rigle to unlock new potentials";

const purchaseBlockAccess = "Your forecast is still being prepared";


export {
    adBlockAccess,
    purchaseBlockAccess,
}