import * as React from "react";
import {
    useEffect,
    useMemo,
    useRef, useState,
} from "react";

import {
    ColumnDef,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Header,
    Row,
    useReactTable,
} from "@tanstack/react-table";
import jsonToCsvExport, {HeaderMapping} from "json-to-csv-export";

import {
    DownloadButton,
    SearchBar,
    TableNoDataRender,
    TablePaginator,
    TableTitle,
} from "@/components/ui/table/tanStack/tanStackTable";
import {ExtendedColumnDef, tanStackTableHeaderProps, tanStackTableProps} from "@/components/ui/table/table";
import {cn} from "@/utils/cn";
import SortDescIcon from "@/assets/icons/table/sort-desc.svg";
import FilterDropdown from "@/components/ui/dropdown/table/filter";
import {DYNAMIC_TABLE_COLUMN_WIDTH} from "@/components/ui/table/tanStack/column-def";


const TableHeader = React.forwardRef<HTMLDivElement, tanStackTableHeaderProps>(
    (
        {
            className,
            children,
            title,
            period,
            defaultSearchColumn,
            headerGroups,
            rows,
            handleExport,
            ...props
        },
        ref
    ) => {
        return (
            <div className={"flex justify-between sticky top-0 z-40 px-7 py-6 bg-Grey-950 rounded-t-[14px]"}>
                <TableTitle title={title} period={period} />
                <div className={"flex justify-center items-center gap-x-3 h-10"}>
                    <SearchBar
                        defaultSearchColumn={defaultSearchColumn}
                        headerGroups={headerGroups}
                    />
                    <FilterDropdown
                        headerGroups={headerGroups}
                        rows={rows}
                    />
                    {handleExport && <DownloadButton handleExport={handleExport} />}
                </div>
            </div>
        );
    }
);

const TableColumn = ({
    header,
    columnSize,
    columnWidthRest,
}: {
    header: Header<any, any>;
    columnSize: number;
    columnWidthRest: number;
}) => {
    return (
        <th
            colSpan={header.colSpan}
            className={`bg-Grey-900 ${header.index === 0 ? "rounded-l-md" : ""} ${header.index === columnSize-1 ? "rounded-r-md" : ""}`}
            style={{ width: header.getSize() !== DYNAMIC_TABLE_COLUMN_WIDTH ? header.getSize() : columnWidthRest }}
        >
            {header.isPlaceholder ? null: (
                <div
                    {...{
                        className: cn(`flex h-11 px-6 py-3 items-center gap-1 flex-1`,
                            header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : ""),
                        onClick: header.column.getToggleSortingHandler(),
                    }}
                >
                    <span className={"text-Grey-300 text-xs leading-[18px]"}>
                        {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                        )}
                    </span>
                    {{
                        asc: <img className={"rotate-180"} src={SortDescIcon} alt={"SortDescIcon"}/>,
                        desc: <img src={SortDescIcon} alt={"SortDescIcon"}/>,
                    }[header.column.getIsSorted() as string] ?? null}
                </div>
            )}
        </th>
    );
}

const TableRow = ({
    row,
    columnWidthRest,
}: {
    row: Row<any>;
    columnWidthRest: number;
}) => {
    return (
        <tr className={"border-b-[1.5px] border-Alpha-black20"}>
            {row.getVisibleCells().map((cell => {
                return (
                    <td
                        key={cell.id}
                        className={"inline-flex px-6 py-4 items-center"}
                        style={{ width: cell.column.getSize() !== DYNAMIC_TABLE_COLUMN_WIDTH ? cell.column.getSize() : columnWidthRest }}
                    >
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                        )}
                    </td>
                );
            }))}
        </tr>
    );
}

const Table = React.forwardRef<HTMLDivElement, tanStackTableProps>(
    (
        {
            className,
            variant,
            size,
            children,
            title,
            data,
            columns,
            period,
            defaultSearchColumn,
            defaultSortColumn,
            filteredData,
            noDataGraphic,
            ...props
        },
        ref
    ) => {
        const [pagination, setPagination] = React.useState({
            pageIndex: 0,
            pageSize: 50,
        });
        const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

        const table = useReactTable({
            data,
            columns,
            initialState: {
                sorting: defaultSortColumn ? [defaultSortColumn] : [],
            },
            filterFns: {},
            state: {
                columnFilters,
                pagination,
            },
            onColumnFiltersChange: setColumnFilters,
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
        });

        const handleExportData = () => {
            if (filteredData == undefined) {
                filteredData = data.map(item => {
                    const {img, view_link, ...rest} = item;
                    return rest;
                });
            }

            // const headers: HeaderMapping[] = columns.map(column => ({key: String(column.id), header: String(column.header), label: String(column.header)}));

            jsonToCsvExport({data: filteredData});
        }

        const fixedColumnWidth = columns.reduce((sum, cur) => {
            if (cur.size) {
                sum += cur.size;
            }
            return sum
        }, 0);

        const tableContainerRef = useRef<HTMLDivElement>(null);
        const [columnWidthRest, setColumnWidthRest] = useState<number>(0);

        const updateDynamicWidth = () => {
            if (tableContainerRef.current) {
                setColumnWidthRest(tableContainerRef.current.clientWidth - fixedColumnWidth);
            }
        }

        useEffect(() => {
            updateDynamicWidth();
            window.addEventListener("resize", updateDynamicWidth);

            return () => {
                window.removeEventListener("resize", updateDynamicWidth);
            };
        }, [])

        return (
            <div className={"app flex flex-col flex-start self-stretch gap-y-[1px]"}>
                <div
                    className={"relative h-[650px] flex flex-col self-stretch px-4 rounded-2xl bg-Grey-950"}
                    ref={tableContainerRef}
                >
                    <TableHeader
                        title={title}
                        period={period}
                        defaultSearchColumn={defaultSearchColumn}
                        headerGroups={table.getHeaderGroups()}
                        rows={data}
                        handleExport={handleExportData}
                    />
                    <table className={"grid relative overflow-auto w-full"}>
                        <thead className={"grid sticky top-0 z-30"}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableColumn
                                        key={header.id}
                                        header={header}
                                        columnSize={table.getAllColumns().length}
                                        columnWidthRest={columnWidthRest}
                                    />
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {table.getCoreRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map(row => (
                                <TableRow
                                    key={row.id}
                                    row={row}
                                    columnWidthRest={columnWidthRest}
                                />
                            ))
                        ): (
                            <tr>
                                <td
                                    colSpan={columns.length}
                                >
                                    {noDataGraphic}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <TablePaginator table={table} />
            </div>
        );
    }
);

export {
    Table,
};