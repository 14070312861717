import React from "react";

import {useComponentInfo} from "@/features/component/api/get-component-info";
import {PageTitle} from "@/components/layouts/page/layout";
import {useBrand} from "@/app/providers/brand";


export const ComponentInfoCard = ({
    component_item,
}: {
    component_item: string;
}) => {
    const { brand } = useBrand();

    const componentInfoQuery = useComponentInfo({
        brand,
        component_item,
    });

    if (componentInfoQuery.isLoading) {
        return <div>Component Info Card Loading</div>;
    }

    if (!componentInfoQuery.data) return null;

    const info = componentInfoQuery.data;

    return (
        <PageTitle
            name={info.item_name}
            supplementaryInfo={[
                {
                    name: "SKU",
                    value: info.vendor_sku,
                },
                {
                    name: "UPC",
                    value: info.upc ?? "",
                },
            ]}
        />
    );
}