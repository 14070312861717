const serverErrors = {
    500: "Issues with server. Please try again later.",
}


const loginErrors = {
    400: "You have entered an invalid email address or password. Please try again.",
    410: "Temporary password expired.",
    429: "Too many attempts. Please try again later.",
};

const registerErrors = {
    400: "Invalid/expired invitation code.",
    409: "Email is associated with an existing account.",
    422: "Passwords do not match.",
}

const forgotPasswordErrors = {
    429: "Too many attempts. Please try again later.",
}

const resetPasswordErrors = {
    400: "Current password incorrect.",
    422: "New passwords do not match.",
    429: "Too many attempts. Please try again later.",
}


export {
    serverErrors,
    loginErrors,
    registerErrors,
    forgotPasswordErrors,
    resetPasswordErrors,
}