import React from "react";

import {useComponent} from "@/features/component/api/get-component";
import SummaryCard from "@/components/ui/card/summary-card/summary-card";
import {useBrand} from "@/app/providers/brand";


export const ComponentCard = ({
    component_item,
}: {
    component_item: string;
}) => {
    const { brand } = useBrand();

    const componentCardQuery = useComponent({
        brand: brand,
        component_item: component_item
    });

    if (componentCardQuery.isLoading) {
        return <div>Component Card Loading...</div>;
    }

    if (!componentCardQuery.data) return null;

    return (
        <SummaryCard
            data={componentCardQuery.data}
        >
        </SummaryCard>
    );
}