import React from "react";

import {useAssemblyDetails} from "@/features/assembly/api/get-assembly-details";
import {FormatType, formatValue} from "@/utils/formatter";
import {InfoCategoryCard, InfoKPICard} from "@/components/ui/card/simple-card/simple-card";
import {useBrand} from "@/app/providers/brand";


export const AssemblyDetailsCard = ({
    assembly_item,
}: {
    assembly_item: string;
}) => {
    const { brand } = useBrand();

    const assemblyDetailsQuery = useAssemblyDetails({
        brand,
        assembly_item,
    });

    if (assemblyDetailsQuery.isLoading) {
        return <div>Assembly Details Card Loading...</div>;
    }

    if (!assemblyDetailsQuery.data) return null;

    const details = assemblyDetailsQuery.data;

    const categoryInfos = [
        {
            name: "Main Category",
            category: details.category ?? "",
            rank: String(details.category_rank) ?? "",
        },
        {
            name: "Sub Category",
            category: details.subcategory ?? "",
            rank: String(details.subcategory_rank) ?? "",
        },
    ];

    const kpiInfos = [
        {
            name: "Price",
            kpis: [
                {
                    name: "Buy Box",
                    value: formatValue(details.bb_price, FormatType.Currency),
                },
                {
                    name: "Average",
                    value: formatValue(details.avg_price, FormatType.Currency),
                },
                {
                    name: "MAP",
                    value: formatValue(details.map_price, FormatType.Currency),
                },
            ],
        },
        {
            name: "Inventory",
            kpis: [
                {
                    name: "Available",
                    value: formatValue(details.inventory_available, FormatType.Integer),
                },
                {
                    name: "Reserved",
                    value: formatValue(details.inventory_reserved, FormatType.Integer),
                },
                {
                    name: "Inbound",
                    value: formatValue(details.inventory_inbound, FormatType.Integer),
                },
            ],
        },
        {
            name: "Inventory Age",
            kpis: [
                {
                    name: "0-180",
                    value: formatValue(details.inventory_0_to_180, FormatType.Integer),
                },
                {
                    name: "180-365",
                    value: formatValue(details.inventory_180_to_365, FormatType.Integer),
                },
                {
                    name: "365+",
                    value: formatValue(details.inventory_365_plus, FormatType.Integer),
                },
            ],
        },
    ];

    return (
        <div className={"flex items-start self-stretch gap-[1px]"}>
            {/* Info Card */}
            <div className={"flex flex-col items-start gap-[1px] flex-1"}>
                <InfoCategoryCard infos={categoryInfos}/>
                <InfoKPICard infos={kpiInfos}/>
            </div>
            {/* Product Image */}
            <div className={"flex items-start w-[392px] h-[384px] p-7 gap-[1px] rounded-2xl bg-Grey-950"}>
                <img className={"self-stretch flex-1 rounded-xl"} src={details.img} alt={"listing image"}/>
            </div>
        </div>
    );
}