import {useComponentPurchaseForecasting} from "@/features/component/api/get-component-purchase-forecasting";
import {MonthlySummaryBarChart} from "@/components/ui/chart/recharts/bar/bar";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {FormatType, formatValue} from "@/utils/formatter";
import {ChartSupplementaryFields, NoDataGraphic} from "@/components/ui/chart/recharts/recharts";
import {useBrand} from "@/app/providers/brand";
import {barMock} from "@/components/ui/chart/mock";
import {componentPurchaseForecast, purchaseForecast} from "@/content/chart/no-data";


export const ComponentPurchaseForecasting = ({
    component_item,
    height = "21.4rem"
}: {
    component_item: string;
    height?: string;
}) => {
    const { brand } = useBrand();

    const componentPurchaseForecastingQuery = useComponentPurchaseForecasting({
        brand: brand,
        month: 6,
        component_item: component_item,
    });

    if (componentPurchaseForecastingQuery.isLoading) {
        return <div>Component Purchase Forecasting Loading...</div>;
    }

    if (!componentPurchaseForecastingQuery.data) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "amount",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "quantity",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Integer,
        },
    ];

    if (componentPurchaseForecastingQuery.data.summary.length === 0) {
        return (
            <MonthlySummaryBarChart
                title={"Purchase Forecast"}
                labels={labels}
                series={barMock}
                noDataGraphic={<NoDataGraphic content={componentPurchaseForecast} />}
            />
        );
    }

    // rename data fields if applicable
    const series = componentPurchaseForecastingQuery.data.summary.map(({ time, ...rest }) => ({
        ...rest,
        date: time,
    }));

    const totalAmount = series.reduce((sum, serie) => sum + serie.amount, 0);
    const averageAmount = totalAmount / series.length;

    return (
        // <MultipleAxisTimeValueBarChart
        //     title={"Purchase Forecast"}
        //     labels={[
        //         { name: "Amount", position: "left" },
        //         { name: "Quantity", position: "right" },
        //     ]}
        //     series={[
        //         componentPurchaseForecastingQuery.data.summary.map((row) => [row.time, row.amount]),
        //         componentPurchaseForecastingQuery.data.summary.map((row) => [row.time, row.quantity]),
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <MonthlySummaryBarChart
            title={"Purchase Forecast"}
            labels={labels}
            series={series}
            chartSupplementaryFields={(
                <ChartSupplementaryFields
                    mainKPI={formatValue(totalAmount, FormatType.Currency)}
                    subtitle={"Average Amount"}
                    value={formatValue(averageAmount, FormatType.Currency)}
                />
            )}
        />
    );
}