import React from "react";

import {Range} from "react-date-range";

import {useProductCharts} from "@/features/component/api/get-component-chart";
import {TimeSeriesLineChart} from "@/components/ui/chart/recharts/line/line";
import {ChartSupplementaryFields} from "@/components/ui/chart/recharts/recharts";
import {FormatType, formatValue} from "@/utils/formatter";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {useBrand} from "@/app/providers/brand";


export const ComponentChart = ({
    component_item,
    dateRange,
    height = "21.4rem",
}: {
    component_item: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const componentChartQuery = useProductCharts({
        brand: brand,
        component_item: component_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (componentChartQuery.isLoading) {
        return <div>Component Chart Loading...</div>;
    }

    if (!componentChartQuery.data) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "sales",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "units",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Integer,
        },
    ]

    // rename data fields if applicable
    const series = componentChartQuery.data;

    const totalSales = series.reduce((sum, serie) => sum + serie.sales, 0);
    const averageSales = totalSales / series.length;

    return (
        // <MultipleAxisTimeValueLineChart
        //     title={"Sales Summary"}
        //     labels={[
        //         { name: "Sales", position: "left" },
        //         { name: "Units", position: "right" },
        //     ]}
        //     series={[
        //         componentChartQuery.data.map(row => [row.date, row.sales]),
        //         componentChartQuery.data.map(row => [row.date, row.units])
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <TimeSeriesLineChart
            title={"Sales Insight"}
            labels={labels}
            series={series}
            period={formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate)}
            chartSupplementaryFields={(
                <ChartSupplementaryFields
                    mainKPI={formatValue(totalSales, FormatType.Currency)}
                    subtitle={"Average Sales"}
                    value={formatValue(averageSales, FormatType.Currency)}
                />
            )}
        />
    );
}