import React from "react";

import {QueryClient} from "@tanstack/react-query";

import {getPurchaseChartQueryOptions} from "@/features/purchase/api/get-purchase-chart";
import {getPurchaseItemsQueryOptions} from "@/features/purchase/api/get-purchase-items";
import {PurchaseChart} from "@/features/purchase/components/purchase-chart";
import {PurchaseItems} from "@/features/purchase/components/purchase-items";
import {PageTitle} from "@/components/layouts/page/layout";
import {useBrand} from "@/app/providers/brand";

export const purchaseDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    const selector = "Quantity"

    if (!brand) return null;

    const purchaseChartQuery = getPurchaseChartQueryOptions({
        brand: brand,
    });

    const purchaseItemQuery = getPurchaseItemsQueryOptions({
        brand: brand,
        selector: selector,
    });

    const promises = [
        queryClient.getQueryData(purchaseChartQuery.queryKey) ??
            (await queryClient.fetchQuery(purchaseChartQuery)),
        queryClient.getQueryData(purchaseItemQuery.queryKey) ??
            (await queryClient.fetchQuery(purchaseItemQuery)),
    ] as const;

    const [purchaseChart, purchaseItems ] = await Promise.all(promises);

    return {
        purchaseChart,
        purchaseItems,
    }
}

export const PurchaseDashboardRoute = () => {
    const { brand } = useBrand();
    const filterOptions = ["Quantity", "Amount"];
    const [filter, setFilter] = React.useState<typeof filterOptions[number]>("Quantity");

    if (!brand) return null;

    return (
        <>
            <PageTitle name={"Purchase Forecast"} />
            <div className={"flex flex-col items-start gap-[1px] overflow-auto"}>
                <div className={"w-full"}>
                    <PurchaseChart/>
                </div>
                <PurchaseItems filter={filter}/>
            </div>
        </>
    );
}