

const barMock = [
    {
        "quantity": 752,
        "amount": 7285.6,
        "date": "January 2025"
    },
    {
        "quantity": 618,
        "amount": 5497.2,
        "date": "February 2025"
    },
    {
        "quantity": 1110,
        "amount": 9160.8,
        "date": "March 2025"
    },
    {
        "quantity": 1132,
        "amount": 8640.5,
        "date": "April 2025"
    },
    {
        "quantity": 1368,
        "amount": 11720.8,
        "date": "May 2025"
    },
    {
        "quantity": 1204,
        "amount": 9608.1,
        "date": "June 2025"
    }
];

const funnelMock = [
    {
        "brand": "RIGLE",
        "name": "Impressions",
        "period": "2024-12-08 ~ 2025-01-06",
        "value": 3180200,
        "nextValue": 23600,
        "rate": 3180200
    },
    {
        "brand": "RIGLE",
        "name": "Clicks",
        "period": "2024-12-08 ~ 2025-01-06",
        "value": 23600,
        "nextValue": 5200,
        "rate": 0.007419296940724002
    },
    {
        "brand": "RIGLE",
        "name": "Orders",
        "period": "2024-12-08 ~ 2025-01-06",
        "value": 5200,
        "nextValue": 5200,
        "rate": 0.2205001928374511
    }
];

const lineMock = [
    {
        "date": "2024-12-08",
        "sales": 1480.50,
        "units": 65,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-09",
        "sales": 2100.25,
        "units": 91,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-10",
        "sales": 2110.60,
        "units": 88,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-11",
        "sales": 1810.50,
        "units": 73,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-12",
        "sales": 2290.00,
        "units": 115,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-13",
        "sales": 2640.45,
        "units": 157,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-14",
        "sales": 2105.30,
        "units": 162,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-15",
        "sales": 2455.00,
        "units": 185,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-16",
        "sales": 2890.90,
        "units": 170,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-17",
        "sales": 2040.75,
        "units": 103,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-18",
        "sales": 1580.10,
        "units": 73,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-19",
        "sales": 1940.85,
        "units": 85,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-20",
        "sales": 2190.50,
        "units": 96,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-21",
        "sales": 1345.25,
        "units": 58,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-22",
        "sales": 995.30,
        "units": 47,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-23",
        "sales": 1655.80,
        "units": 76,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-24",
        "sales": 860.75,
        "units": 39,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-25",
        "sales": 1100.20,
        "units": 48,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-26",
        "sales": 1980.90,
        "units": 87,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-27",
        "sales": 3260.40,
        "units": 145,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-28",
        "sales": 2025.80,
        "units": 77,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-29",
        "sales": 1940.00,
        "units": 81,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-30",
        "sales": 3060.10,
        "units": 120,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2024-12-31",
        "sales": 1290.00,
        "units": 51,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2025-01-01",
        "sales": 1485.25,
        "units": 58,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2025-01-02",
        "sales": 2625.50,
        "units": 116,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2025-01-03",
        "sales": 2935.00,
        "units": 134,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2025-01-04",
        "sales": 3315.00,
        "units": 167,
        "period": "2024-12-08 ~ 2025-01-06"
    },
    {
        "date": "2025-01-05",
        "sales": 3455.80,
        "units": 161,
        "period": "2024-12-08 ~ 2025-01-06"
    }
];

const pieMock = [
    {
        "brand": "RIGLE",
        "value": 179500.23,
        "value_last": 188200.47,
        "period": "2024-12-08 ~ 2025-01-06",
        "name": "Organic Sales"
    },
    {
        "brand": "RIGLE",
        "value": 45900.65,
        "value_last": 39500.29,
        "period": "2024-12-08 ~ 2025-01-06",
        "name": "Ad Sales"
    }
];

const progressBarMock = [
    {"New Customers": 171985, "Repeat Customers": 24152}
];


export {
    barMock,
    funnelMock,
    lineMock,
    pieMock,
    progressBarMock,
}