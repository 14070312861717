import React from "react";
import {Link, Location, useLocation, useNavigate} from "react-router-dom";

import {FormatType, formatValue} from "@/utils/formatter";
import {getLatestDay, getPast30d} from "@/utils/date_retriever";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import PageTitleLogo1 from "@/assets/icons/page-title-logo1.svg";
import PageTitleLogo2 from "@/assets/icons/page-title-logo2.svg";
import {NewTabButton} from "@/components/ui/button/newtab-button/newtab-button";
import ExternalLinkIcon from "@/assets/icons/external-link-grey-100.svg";
import AmazonIcon from "@/assets/icons/amazon.svg";
import BreadcrumbArrowIcon from "@/assets/icons/breadcrumb/arrow.svg";
import BreadcrumbBackwardArrowIcon from "@/assets/icons/breadcrumb/backward-arrow.svg";
import {
    getCurrentPageName,
    getPrevPagesArr,
    getPrevPageUrl,
    jumpLinkPrevPage,
    jumpLinkPrevPageFromParams,
    RoutePageNameMap,
} from "@/utils/page-trace";
import {useBrand} from "@/app/providers/brand";


const Period = ({
    startDate,
    endDate,
}: {
    startDate: Date,
    endDate: Date,
}) => {
    return (
        <div className={"flex justify-center items-center gap-3.5 h-[40px] pl-3.5 pr-[18px] py-2.5 bg-Grey-900 border rounded-md border-Grey-800 " +
            "shadow-xs"}>
            <div className={"flex items-center gap-2"}>
                <span className={"text-Grey-300 text-sm font-normal leading-normal tracking-[-0.28px]"}>
                    {formatValue(startDate, FormatType.LongDate) + " - " + formatValue(endDate, FormatType.LongDate)}
                </span>
            </div>
        </div>
    );
}

const DatePicker = ({
    name,
                    }: {
    name: string;
}) => {
    const datePickerMap: Record<string, JSX.Element> = {
        "Dashboard": <Period startDate={getPast30d} endDate={getLatestDay}/>,
        "Sales": <DateRangeDropdown />,
        "Products": <DateRangeDropdown />,
        "Advertising": <DateRangeDropdown />,
        "Purchase Forecast": <></>,
        "Settings": <></>,
    }

    return (
        <div className={"flex items-center gap-4"}>
            {datePickerMap[name] || <DateRangeDropdown />}
        </div>
    );
}

const DashboardTitle = ({
    title,
    description,
    isDashboard = true,
}: {
    title: string;
    description: string;
    isDashboard?: boolean;
}) => {
    return (
        <div className={"flex flex-col items-start gap-7 flex-1"}>
            <div className={"flex flex-col items-start gap-2"}>
                <div className={"flex items-center gap-5"}>
                    <span className={"text-Base-white text-3xl font-bold leading-[38px] tracking-[-0.6px]"}>
                        {title}
                    </span>
                    {isDashboard && (
                        <>
                            <div className={"w-0 h-3.5 border border-Base-white opacity-20"}/>
                            <div className={"flex items-center gap-[5px] rounded-[5px]"}>
                                <div className={"flex w-2 h-2 justify-center items-center"}>
                                    <div className={"w-1.5 h-1.5 shrink-0 rounded-full bg-Primary-600"}/>
                                </div>
                                <span className={"text-Primary-600 text-sm tracking-[-0.28px]"}>
                                    On Track
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <span className={"text-Grey-200 text-base leading-[20px]"}>
                    {description}
                </span>
            </div>
        </div>
    );
}

const DetailPageTitle = ({
                             title,
                             supplementaryInfo,
                         }: {
    title: string;
    supplementaryInfo?: { name: string, value: string }[];
}) => {
    const logos = [
        {
            icon: <img className={"pointer-events-none"} src={PageTitleLogo1} alt={"PageTitleLogo1"}/>
        },
        {
            icon: <img className={"pointer-events-none"} src={PageTitleLogo2} alt={"PageTitleLogo2"}/>
        },
    ];

    return (
        <div className={"flex flex-col items-start gap-7 flex-1"}>
            <span className={"text-Base-white text-3xl font-bold leading-[38px] tracking-[-0.6px]"}>
                {title}
            </span>
            <div className={"flex items-center gap-4"}>
                {supplementaryInfo && supplementaryInfo.map((info, i) => (
                    <div key={"page-title-" + i} className={"flex items-center gap-2"}>
                        <div className={"flex items-center gap-1"}>
                            {logos[i].icon}
                            <span className={"text-Grey-300 text-base leading-[20px]"}>
                                {info.name}
                            </span>
                        </div>
                        <div className={"w-2 h-0 border-[1.5px] border-Grey-300"}/>
                        <span className={"max-w-[600px] text-Grey-200 text-base leading-[20px]"}>
                            {info.value}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

const BackButton = ({location}: {location: Location<any>}) => {
    const navigate = useNavigate();
    const {brand} = useBrand();

    const url = jumpLinkPrevPage({location, brand});

    return (
        <NewTabButton
            url={url}
            customNavigate={() => {
                navigate(`/app${url.split("app")[1]}`, { replace: true });
            }}
        >
            <div className={"flex w-10 h-10 justify-center items-center rounded-full bg-Grey-900"}>
                <img src={BreadcrumbBackwardArrowIcon} alt={"BreadcrumbBackwardArrowIcon"}/>
            </div>
        </NewTabButton>
    );
}

const CurrentPageTag = ({location}: {location: Location<any>}) => {
    const currentPageName = getCurrentPageName(location);

    return (
        <div className={"flex justify-center items-center gap-[3px] h-5 px-1.5 rounded-[5px] bg-Alpha-white5"}>
                <span className={"text-Grey-300 text-sm font-medium leading-[18px] tracking-[-0.12px]"}>
                    {currentPageName}
                </span>
        </div>
    );
}

const PreviousPageTags = ({location}: {location: Location<any>}) => {
    const {brand} = useBrand();
    const prevPages = getPrevPagesArr(location);

    if (!prevPages) return null;

    return (
        <>
            {prevPages.map((prevPageUrl, index) => (
                <React.Fragment key={index}>
                    <Link
                        to={jumpLinkPrevPageFromParams({
                            prevPages: prevPages.slice(0, index + 1),
                            brand,
                        })}
                        className={"text-Grey-200 text-sm leading-[18px]"}
                    >
                        {RoutePageNameMap[prevPageUrl.split("/")[0]]}
                    </Link>
                    <img src={BreadcrumbArrowIcon} alt={"BreadcrumbArrowIcon"}/>
                </React.Fragment>
            ))}
        </>
    );
}

const Breadcrumbs = () => {
    const location = useLocation();
    const prevPageURL = getPrevPageUrl(location);

    return (
        <div className={"flex items-center gap-4 min-h-5"}>
            {prevPageURL && (
                <>
                    <BackButton location={location} />
                    <div className={"w-0 h-6 border border-Grey-700"}/>
                </>
                )}
            <div className={"flex items-center gap-2.5"}>
                {prevPageURL && (
                    <PreviousPageTags location={location} />
                )}
                <CurrentPageTag location={location} />
            </div>
        </div>
    );
}

const PageTitle = ({
                       name,
                       supplementaryInfo,
                       externalLink,
                   }: {
    name: string,
    supplementaryInfo?: { name: string, value: string }[];
    externalLink?: string;
}) => {
    const titles: { [key: string]: string } = {
        "Dashboard": "Track brand growth with an all-in-one performance overview",
        "Sales": "Track sales trends and performance to unlock growth opportunities",
        "Products": "Evaluate product-level performance to manage your catalog effectively",
        "Advertising": "Comprehensive metrics for advertising success and spend efficiency",
        "Purchase Forecast": "Accurate purchase forecasts to guide inventory planning",
        "Settings": "Update your personal info and password",
    };

    return (
        <header
            className={"flex flex-col items-start self-stretch gap-5 pl-7 pr-8 py-7 rounded-b-[14px] bg-Grey-950 border-b border-Alpha-black50"}>
            <div className={"flex flex-col items-start self-stretch gap-5"}>
                <Breadcrumbs />
                <div className={"flex w-full items-end self-stretch gap-3"}>
                    {Object.keys(titles).includes(name) ? (
                        <DashboardTitle
                            title={name}
                            description={titles[name]}
                            isDashboard={!["Settings"].includes(name)}
                        />
                    ): (
                        <DetailPageTitle
                            title={name}
                            supplementaryInfo={supplementaryInfo}
                        />
                    )}
                    {externalLink && (
                        <NewTabButton url={externalLink}>
                            <div
                                className={"flex justify-center items-center self-stretch gap-2.5 py-2.5 px-3.5 rounded-md border border-Grey-700 bg-Grey-800 shadow-xs"}>
                                <div className={"flex items-center gap-2"}>
                                    <img src={AmazonIcon} alt={"AmazonIcon"} />
                                    <span className={"text-Base-white text-sm tracking-[-0.28px]"}>
                                        Amazon Link
                                    </span>
                                </div>
                                <img src={ExternalLinkIcon} alt={"ExternalLinkIcon"}/>
                            </div>
                        </NewTabButton>
                    )}
                    <DatePicker
                        name={name}
                    />
                </div>
            </div>
        </header>
    );
}


export {
    Period,
    PageTitle,
};