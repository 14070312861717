import {Range} from "react-date-range";

import {useAssemblyChart} from "@/features/assembly/api/get-assembly-chart";
import {TimeSeriesLineChart} from "@/components/ui/chart/recharts/line/line";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";


export const AssemblyChart = ({
    assembly_item,
    dateRange,
    height = "21.4rem",
}: {
    assembly_item: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const assemblyChartQuery = useAssemblyChart({
        brand,
        assembly_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (assemblyChartQuery.isLoading) {
        return <div>Assembly Charts Loading...</div>;
    }

    if (!assemblyChartQuery.data) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "sales",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "units",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Integer,
        },
    ];

    // rename data fields if applicable
    const series = assemblyChartQuery.data;

    return (
        // <MultipleAxisTimeValueLineChart
        //     title={"Sales Summary"}
        //     labels={[
        //         { name: "Sales", position: "left" },
        //         { name: "Units", position: "right" },
        //     ]}
        //     series={[
        //         assemblyChartQuery.data.map(row => [row.date, row.sales]),
        //         assemblyChartQuery.data.map(row => [row.date, row.units])
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <TimeSeriesLineChart
            title={"Sales Insight"}
            labels={labels}
            series={series}
            period={formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate)}
        />
    );
}