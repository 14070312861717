import React from "react";

import {Range} from "react-date-range";

import {useComponentItems} from "@/features/component/api/get-component-items";
import {FormatType, formatValue} from "@/utils/formatter";
import {ComponentCarousel} from "@/components/ui/table/carousel/carousel";
import {useBrand} from "@/app/providers/brand";


export const ComponentItems = ({
    component_item,
    dateRange,
}: {
    component_item: string;
    dateRange: Range[];
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const componentItemsQuery = useComponentItems({
        brand: brand,
        component_item: component_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (componentItemsQuery.isLoading) {
        return <div>Component Table Loading...</div>;
    }

    if (!componentItemsQuery.data) return null;

    // const initialFilters: CustomFilterMeta = {
    //     component_item: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     asin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     category: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
    //     sales_rank: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     avg_price: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     units: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     inventory_available: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     inventory_inbound: { value: null, matchMode: FilterMatchMode.CUSTOM },
    // }
    //
    // const visMapper: ColumnVisMapperProps = {
    //     img: ColumnVisMeta.Image,
    //     asin: ColumnVisMeta.Merge_toAmzLink,
    //     marketplace: ColumnVisMeta.Amz_link,
    //     detail_link: ColumnVisMeta.Detail_link,
    // }

    return (
        // <PaginationTable
        //     data={componentItemsQuery.data.data.map(item => ({
        //         ...item,
        //         avg_price: currencyF(item.avg_price),
        //         units: numberF(item.units),
        //         sales: currencyF(item.sales),
        //         inventory_available: numberF(item.inventory_available),
        //         inventory_inbound: numberF(item.inventory_inbound),
        //     }))}
        //     columns={componentItemsQuery.data.columns}
        //     initialFilters={initialFilters}
        //     visMapper={visMapper}
        // />
        <ComponentCarousel
            series={componentItemsQuery.data.data}
            period={formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate)}
        />
    );
}