import React, {HTMLAttributes, useEffect} from "react";
import {Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {ChartTitle, legendBox, NoDataGraphic} from "@/components/ui/chart/recharts/recharts";
import ProgressBarIcon from "@/assets/icons/graph/progress-bar.svg";
import GrantPermissionIcon from "@/assets/icons/button/grant-permission.svg";
import {lineHeightFn, RechartsProps} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {FormatType, formatValue} from "@/utils/formatter";
import Button from "@/components/ui/button/button";
import {dashboardCustomerBar} from "@/content/chart/no-data";


const ProgressBarKPI = ({
    leftValue,
    rightValue,
    height,
}: {
    leftValue: number;
    rightValue: number;
    height: number;
}) => {
    const lineHeight36 = lineHeightFn(36);
    const lineHeight22 = lineHeightFn(22);
    const lineHeight20 = lineHeightFn(20);

    const total = leftValue + rightValue;

    return (
        <>
            <g
                transform={`translate(6, 0)`}
            >
                {/* TopLeft Text */}
                <text
                    textAnchor={"start"}
                    dominantBaseline={"hanging"}
                >
                    <tspan
                        x={0}
                        dy={lineHeight36.offsetFn(34) + 2}
                        fill={colors.base.white}
                        fontSize={34}
                        fontWeight={700}
                        letterSpacing={-0.68}
                    >
                        {formatValue(total, FormatType.Integer)}
                    </tspan>
                    <tspan dy={lineHeight36.height - lineHeight36.offsetFn(34)}>&#8203;</tspan>
                    <tspan
                        x={0}
                        dy={lineHeight20.offsetFn(14)}
                        fill={colors.grey["300"]}
                        fontSize={14}
                        fontWeight={400}
                    >
                        Total Customers
                    </tspan>
                </text>
                {/* BottomLeft Text */}
                <text
                    x={0}
                    y={height - lineHeight22.offsetFn(16) - 4}
                    dy={lineHeight22.offsetFn(16)}
                    textAnchor={"start"}
                    fontSize={16}
                    fontWeight={700}
                    letterSpacing={-0.32}
                >
                    <tspan
                        fill={colors.base.white}
                    >
                        {formatValue(leftValue, FormatType.Integer)}&nbsp;
                    </tspan>
                    <tspan
                        fill={colors.grey["500"]}
                    >
                        ({formatValue(leftValue / total, FormatType.Percent)})
                    </tspan>
                </text>
            </g>
            {/* BottomRight Text */}
            <text
                x={"100%"}
                dx={-18}
                y={height - lineHeight22.offsetFn(16) - 4}
                dy={lineHeight22.offsetFn(16)}
                textAnchor={"end"}
                fontSize={16}
                fontWeight={700}
                letterSpacing={-0.32}
            >
                <tspan
                    fill={colors.base.white}
                >
                    {formatValue(rightValue, FormatType.Integer)}&nbsp;
                </tspan>
                <tspan
                    fill={colors.grey["500"]}
                >
                    ({formatValue(rightValue / total, FormatType.Percent)})
                </tspan>
            </text>
        </>
    );
}

const ProgressBar = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & RechartsProps>(
    (
        {
            className,
            children,
            title,
            labels,
            series,
            noDataGraphic,
            period,
        },
        ref
    ) => {
        const height = 158;

        return (
            <div className={"relative flex flex-col justify-between h-full flex-1 p-7 bg-Grey-950 rounded-[14px]"}>
                <div className={"flex flex-col gap-y-5"}>
                    <ChartTitle icon={ProgressBarIcon} title={title} period={period}/>
                </div>
                {noDataGraphic}
                <ResponsiveContainer
                    width={"100%"}
                    height={height}
                    className={noDataGraphic ? "blur pointer-events-none select-none" : ""}
                >
                    <BarChart
                        data={series}
                        layout="vertical"
                        barSize={18}
                    >
                        <defs>
                            {/* Bar Fill */}
                            <linearGradient id={"progressBarPrimaryGradient"} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="3%" stopColor={colors.primary["500"]} stopOpacity="1"/>
                                <stop offset="15%" stopColor={colors.primary["500"]} stopOpacity="0.88"/>
                                <stop offset="100%" stopColor={colors.primary["500"]} stopOpacity="0.0"/>
                            </linearGradient>
                            <linearGradient id={"progressBarSecondaryGradient"} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={colors.accent} stopOpacity="1"/>
                                <stop offset="100%" stopColor={colors.accent} stopOpacity="0.0"/>
                            </linearGradient>
                        </defs>

                        <XAxis type="number" domain={[0, ]} hide />
                        <YAxis type="category" hide />
                        <Legend
                            align={"right"}
                            verticalAlign={"top"}
                            iconType={"circle"}
                            iconSize={5}
                            formatter={(label: string) => legendBox(label)}
                            wrapperStyle={{
                                marginTop: 28,
                                marginRight: 4,
                            }}
                        />
                        <g>
                            <ProgressBarKPI
                                leftValue={series[0]["New Customers"]}
                                rightValue={series[0]["Repeat Customers"]}
                                height={height}
                            />
                        </g>
                        {Object.keys(series[0]).map(key => (
                            <Bar
                                key={"progressBar-" + key}
                                dataKey={key}
                                fill={key === "New Customers" ? "url(#progressBarPrimaryGradient)" : "url(#progressBarSecondaryGradient)"}
                                stackId={"progressBar"}
                                radius={40}
                                opacity={0.4}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
);

const ProgressBarNoGraph = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & RechartsProps>(
    (
        {
            className,
            title,
            series,
            ...props
        },
        ref
    ) => {
        const total = Object.values(series[0] as number[]).reduce((sum, value) => sum + value, 0);

        return (
            <div className={"flex items-start self-stretch gap-2.5"}>
                <div className={"flex items-center w-full gap-[52px] py-7 pl-7 pr-12 bg-Grey-950 rounded-[14px]"}>
                    <div className={"flex self-stretch flex-1"}>
                        <ChartTitle icon={ProgressBarIcon} title={title} period={undefined} />
                    </div>
                    {Object.keys(series[0]).map(key => (
                        <div key={"progressBar-" + key} className={"flex flex-col items-start shrink-0 gap-2"}>
                            <div className={"flex justify-center items-center gap-1.5"}>
                                <span className={"truncate text-Grey-400 text-base leading-[20px]"}>
                                    {key}
                                </span>
                            </div>
                            <div className={"flex items-center gap-2.5"}>
                                <span className={"text-Base-white text-base font-bold leading-[20px]"}>
                                    {formatValue(series[0][key], FormatType.Integer)}&nbsp;
                                    ({formatValue(series[0][key] / total, FormatType.Percent)})
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
);

export {
    ProgressBar,
    ProgressBarNoGraph,
};
