import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {
    DropdownMenu,
    DropdownTrigger
} from "@/components/ui/dropdown/dropdown";
import {HTMLAttributes, useEffect, useRef, useState} from "react";
import SimpleDateRangePicker from "@/components/ui/date-picker/date-picker";
import {Range} from "react-date-range";
import {cn} from "@/utils/cn";
import CalendarIcon from "@/assets/icons/date-picker/calendar.svg";
import {FormatType, formatValue} from "@/utils/formatter";
import DateSelector from "@/assets/icons/date-picker/date-selector.svg";
import {useDateRange} from "@/app/providers/date-range";


const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type DateRangeDropdownProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dropdownVariants> & {
}

const DateRangeDropdown = React.forwardRef<HTMLDivElement, DateRangeDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            ...props
        },
        ref
    ) => {
        const { dateRange, setDateRange } = useDateRange();
        const { startDate, endDate } = dateRange[0];
        const [tmpDateRange, setTmpDateRange] = React.useState<Range[]>(dateRange);
        const [isOpen, setIsOpen] = useState(false);
        const modalRef = useRef<HTMLDivElement>(null);

        const onChangeSetDateRange = () => {
            setTmpDateRange((currentTmpDateRange) => {
                setDateRange(currentTmpDateRange);
                return currentTmpDateRange;
            });
        };

        const toggleDropdown = () => {
            setIsOpen((prevIsOpen) => {
                if (prevIsOpen) {
                    onChangeSetDateRange();
                }
                return !prevIsOpen;
            });
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onChangeSetDateRange();
                setIsOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <DropdownMenu>
                <DropdownTrigger
                    className={cn("", className)}
                    aria-expanded={isOpen}
                    isOpen={isOpen}
                    onClick={toggleDropdown}
                    hideIcon={true}
                >
                    <div className={"flex justify-center items-center gap-3.5 pl-3.5 pr-[18px] py-2.5 bg-Grey-800 border rounded-md border-Grey-700 shadow-xs"}>
                        <div className={"flex items-center gap-2"}>
                            <img src={CalendarIcon} alt={"CalendarIcon"}/>
                            <span className={"text-Base-white text-sm tracking-[-0.28px]"}>
                                 {formatValue(startDate, FormatType.LongDate) + " - " + formatValue(endDate, FormatType.LongDate)}
                            </span>
                        </div>
                        {isOpen ? <img className={"rotate-180"} src={DateSelector} alt={"DateSelector"}/> :
                            <img src={DateSelector} alt={"DateSelector"}/>}
                    </div>
                </DropdownTrigger>

                {
                    isOpen ? (
                        <div className={"fixed inset-0 z-50 flex items-center justify-center bg-[#1D1F1FE6]"}>
                            <div ref={modalRef}>
                                <SimpleDateRangePicker
                                    dateRange={tmpDateRange}
                                    setDateRange={setTmpDateRange}
                                    cancelFn={() => {
                                        setTmpDateRange(dateRange);
                                        setIsOpen(false);
                                    }}
                                    applyFn={onChangeSetDateRange}
                                />
                            </div>
                        </div>
                    ) : null
                }
            </DropdownMenu>
        )
    }
);

export default DateRangeDropdown;