import Input, {InputProps} from "@/components/ui/input/input";
import React from "react";

const DebouncedInput = ({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }: {
    onChange: (value: any) => void;
} & Omit<InputProps, 'onChange'>) => {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <Input
            value={value}
            onChange={e => {setValue(e.target.value)}}
            {...props}
        />
    );
}

export default DebouncedInput;