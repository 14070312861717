import {queryOptions, useQuery} from "@tanstack/react-query";

import {CardResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getComponent = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}): Promise<CardResponse> => {
    return api.get(`/products/${component_item}`, {
        params: {
            brand,
        },
    });
}

export const getComponentQueryOptions = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}) => {
    return queryOptions({
        queryKey: ["component", brand, component_item],
        queryFn: () => getComponent({ brand, component_item }),
    })
}

type UseComponentOptions = {
    brand: string;
    component_item: string;
    queryConfig?: QueryConfig<typeof getComponentQueryOptions>;
}

export const useComponent = ({
    brand,
    component_item,
    queryConfig,
}: UseComponentOptions) => {
    return useQuery({
        ...getComponentQueryOptions({ brand, component_item }),
        ...queryConfig
    })
}