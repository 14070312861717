import * as React from "react";
import {useEffect, useMemo, useRef} from "react";

import {cva, VariantProps} from "class-variance-authority";

import {cn} from "@/utils/cn";
import {
    DropdownArrowIconDown,
    DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuItemCheck,
    DropdownTrigger
} from "@/components/ui/dropdown/dropdown";
import {useBrand} from "@/app/providers/brand";
import BrandIcon from "@/features/brand/components/brand-icon";


const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type BrandDropdownProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dropdownVariants> & {
    brands: string[];
}

const MenuItemContent = ({
    brand,
    icon,
    currentBrand,
}: {
    brand: string,
    icon: JSX.Element,
    currentBrand: string;
}) => {
    return (
        <span className={"flex items-center w-full gap-2"}>
            <span className={"flex items-center flex-1 gap-2"}>
                <span className={"w-6 h-6"}>
                    {icon}
                </span>
                <span className={"text-Base-white text-base leading-[20px]"}>
                    {brand}
                </span>
            </span>
            {
                currentBrand === brand ? <DropdownMenuItemCheck/> : null
            }
        </span>
    );
}

const BrandDropdown = React.forwardRef<HTMLDivElement, BrandDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            brands,
            ...props
        },
        ref
    ) => {
        const { brand: currentBrand, setBrand } = useBrand();
        const [isOpen, setOpen] = React.useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const handleSelect = (event: any) => {
            setBrand(event.target.value);
        }

        const toggleDropdown = () => {
            setOpen(!isOpen);
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <DropdownMenu ref={dropdownRef} className={cn("w-full", { variant, size, className })} {...props}>
                <DropdownTrigger
                    aria-expanded={isOpen}
                    onClick={toggleDropdown}
                    isOpen={isOpen}
                    hideIcon={true}
                    className={"w-full"}
                >
                    <div className={"flex justify-between items-center self-stretch shrink-0 w-full h-[41px] rounded-2xl"}>
                        <span className={"flex items-center gap-3"}>
                            <span className={"w-10 h-10 self-stretch"}>
                                {currentBrand ? <BrandIcon size={40} brand={currentBrand} />: <></>}
                            </span>
                            <span className={"text-Grey-100 text-base leading-[20px]"}>
                                {currentBrand}
                            </span>
                        </span>

                        {
                            brands.length > 1 ? (
                                <DropdownArrowIconDown isActive={isOpen} />
                            ): null
                        }
                    </div>
                </DropdownTrigger>

                {brands.length > 1 && isOpen ? (
                    <DropdownMenuContent className={"flex items-start absolute overflow-y-auto w-[280px] max-h-[280px] left-[30px] top-[150px] z-50 rounded-lg border border-Grey-800 bg-Grey-900 shadow-lg"}>
                        <div className={"flex flex-col items-start py-1 flex-1"}>
                            {brands.map((brand, i) => (
                                <DropdownMenuItem
                                    key={"dropdown-menuitem-" + brand}
                                    value={brand}
                                    onClick={handleSelect}
                                >
                                    <MenuItemContent
                                        currentBrand={currentBrand}
                                        brand={brand}
                                        icon={brand ? <BrandIcon brand={brand} /> : <></>}
                                    />
                                </DropdownMenuItem>
                            ))}
                        </div>
                    </DropdownMenuContent>
                ): null}
            </DropdownMenu>
        );
    }
);

export default BrandDropdown;