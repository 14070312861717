import React, {useState} from "react";

import {QueryClient} from "@tanstack/react-query";
import {Range} from "react-date-range";

import {getProductsItemsQueryOptions} from "@/features/products/api/get-products-items";
import {ProductsItems} from "@/features/products/components/products-items";
import {PageTitle} from "@/components/layouts/page/layout";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";
import {useDateRange} from "@/app/providers/date-range";

export const productsDashboardLoader = (queryClient: QueryClient, brand: string, dateRange: Range[]) => async () => {
    if (!brand || !dateRange) return null;

    const { startDate: startDateObj, endDate: endDateObj } = dateRange[0];
    const [ startDate, endDate ] = [formatValue(startDateObj, FormatType.DateForQuery), formatValue(endDateObj, FormatType.DateForQuery)];

    const productsItemsQuery = getProductsItemsQueryOptions({
        brand: brand,
        period: "Custom",
        startDate,
        endDate,
    });

    const promises = [
        queryClient.getQueryData(productsItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(productsItemsQuery)),
    ] as const;

    const [ productsItems ] = await Promise.all(promises);

    return {
        productsItems,
    }
}

export const ProductsDashboardRoute = () => {
    const { brand } = useBrand();
    const { dateRange, setDateRange } = useDateRange();

    if (!brand || !dateRange) return null;

    return (
        <>
            <PageTitle name={"Products"} />
            <div className={"flex flex-col items-start gap-[1px] overflow-auto"}>
                <ProductsItems
                    dateRange={dateRange}
                />
            </div>
        </>
    );
}