import * as React from "react";
import {useMemo} from "react";

import {Range} from "react-date-range";

import {UseSalesItems} from "@/features/sales/api/get-sales-items";
import {FormatType, formatValue} from "@/utils/formatter";
import {Table} from "@/components/ui/table/tanStack/pagination-table/pagination-table";
import {ExtendedColumnDef} from "@/components/ui/table/table";
import {ProductsListData} from "@/types";
import {
    CellCurrency,
    CellInteger, CellPercent,
    CellText, CellViewDetail
} from "@/components/ui/table/tanStack/cell-render";
import {useBrand} from "@/app/providers/brand";
import {SalesColumnDef} from "@/components/ui/table/tanStack/column-def";


export const SalesItems = ({
    dateRange,
    hidePeriod = false,
}: {
    dateRange: Range[];
    hidePeriod?: boolean;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const salesItemsQuery = UseSalesItems({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    const columns: ExtendedColumnDef<ProductsListData>[] = useMemo(() => SalesColumnDef, []);

    if (salesItemsQuery.isLoading) {
        return <div>Sales Table Loading...</div>;
    }

    if (!salesItemsQuery.data) return null;

    // const initialFilters: CustomFilterMeta = {
    //     component_item: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     vendor_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     component_upc: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
    //     units: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     revenue_share: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     amazon_listing_count: { value: null, matchMode: FilterMatchMode.CUSTOM },
    // }
    //
    // const visMapper: ColumnVisMapperProps = {
    //     marketplace: ColumnVisMeta.Amz_link,
    //     detail_link: ColumnVisMeta.Detail_link,
    // }

    return (
        // <PaginationTable
        //     data={salesItemsQuery.data.data.map(item => ({
        //         ...item,
        //         units: numberF(item.units),
        //         sales: currencyF(item.sales),
        //         revenue_share: percentF(item.revenue_share),
        //     }))}
        //     columns={salesItemsQuery.data.columns}
        //     initialFilters={initialFilters}
        //     visMapper={visMapper}
        //     dateRange={dateRange}
        // />
        <Table
            title={"SKU Listings"}
            data={salesItemsQuery.data.data}
            columns={columns}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
            defaultSearchColumn={"Item Name"}
            defaultSortColumn={{id: "sales", desc: true}}
        />
    )
}