import {queryOptions, useQuery} from "@tanstack/react-query";

import {AdChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getAssemblyAdvertisingChart = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<AdChartResponse[]> => {
    return api.get(`/assembly/${assembly_item}/advertising-chart`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        }
    })
}

export const getAssemblyAdvertisingChartQueryOptions = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", "advertising-chart", brand, assembly_item, period, startDate, endDate],
        queryFn: () => getAssemblyAdvertisingChart({ brand, assembly_item, period, startDate, endDate }),
    })
}

type UseAssemblyAdvertisingChartOptions = {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getAssemblyAdvertisingChartQueryOptions>;
}

export const useAssemblyAdvertisingChart = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseAssemblyAdvertisingChartOptions) => {
    return useQuery({
        ...getAssemblyAdvertisingChartQueryOptions({ brand, assembly_item, period, startDate, endDate }),
        ...queryConfig,
    })
}