import * as React from "react";
import {useMemo} from "react";

import {Range} from "react-date-range";

import {UseProductsItems} from "@/features/products/api/get-products-items";
import {FormatType, formatValue} from "@/utils/formatter";
import {Table} from "@/components/ui/table/tanStack/pagination-table/pagination-table";
import {ExtendedColumnDef} from "@/components/ui/table/table";
import {
    CellCurrency,
    CellHyperlink,
    CellImage,
    CellInteger,
    CellText, CellViewDetail
} from "@/components/ui/table/tanStack/cell-render";
import {ProductDetailLinkData} from "@/types";
import {useBrand} from "@/app/providers/brand";
import {ProductsColumnDef} from "@/components/ui/table/tanStack/column-def";


export const ProductsItems = ({
    dateRange,
    hidePeriod = false,
}: {
    dateRange: Range[];
    hidePeriod?: boolean;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const productsItemsQuery = UseProductsItems({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    const columns: ExtendedColumnDef<ProductDetailLinkData>[] = useMemo(() => ProductsColumnDef, []);

    if (productsItemsQuery.isLoading) {
        return <div>Products Table Loading...</div>;
    }

    if (!productsItemsQuery.data) return null;

    // const initialFilters: CustomFilterMeta = {
    //     component_item: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     asin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     category: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
    //     sales_rank: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     avg_price: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     units: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     inventory_available: { value: null, matchMode: FilterMatchMode.CUSTOM },
    //     inventory_inbound: { value: null, matchMode: FilterMatchMode.CUSTOM },
    // }
    //
    // const visMapper: ColumnVisMapperProps = {
    //     img: ColumnVisMeta.Image,
    //     asin: ColumnVisMeta.Merge_toAmzLink,
    //     marketplace: ColumnVisMeta.Amz_link,
    //     detail_link: ColumnVisMeta.Detail_link,
    //     display_name: ColumnVisMeta.Long_text,
    //     status: ColumnVisMeta.Void,
    // }

    return (
        // <PaginationTable
        //     data={productsItemsQuery.data.data.map(item => ({
        //         ...item,
        //         avg_price: currencyF(item.avg_price),
        //         units: numberF(item.units),
        //         sales: currencyF(item.sales),
        //         inventory_available: numberF(item.inventory_available),
        //         inventory_inbound: numberF(item.inventory_inbound),
        //     }))}
        //     columns={productsItemsQuery.data.columns}
        //     initialFilters={initialFilters}
        //     visMapper={visMapper}
        //     dateRange={dateRange}
        // />
        <Table
            title={"ASINs"}
            data={productsItemsQuery.data.data}
            columns={columns}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
            defaultSearchColumn={"Listing Title"}
            defaultSortColumn={{id: "sales", desc: true}}
        />
    )
}