import {queryOptions, useQuery} from "@tanstack/react-query";

import {ProductsPOListResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getComponentOpenPo = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}): Promise<ProductsPOListResponse> => {
    return api.get(`/products/${component_item}/open-po`, {
        params: {
            brand,
            component_item,
        },
    });
}

export const getComponentOpenPoQueryOptions = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}) => {
    return queryOptions({
        queryKey: ["component", "open-po", brand, component_item],
        queryFn: () => getComponentOpenPo({ brand, component_item }),
    })
}

type UseComponentOpenPoOptions = {
    brand: string;
    component_item: string;
    queryConfig?: QueryConfig<typeof getComponentOpenPoQueryOptions>;
}

export const useComponentOpenPo = ({
    brand,
    component_item,
    queryConfig,
}: UseComponentOpenPoOptions) => {
    return useQuery({
        ...getComponentOpenPoQueryOptions({ brand, component_item }),
        ...queryConfig,
    })
}