import React from "react";

import {useAssemblyInfo} from "@/features/assembly/api/get-assembly-info";
import {PageTitle} from "@/components/layouts/page/layout";
import {useBrand} from "@/app/providers/brand";


export const AssemblyInfoCard = ({
    assembly_item,
}: {
    assembly_item: string;
}) => {
    const { brand } = useBrand();

    const assemblyInfoQuery = useAssemblyInfo({
        brand,
        assembly_item,
    });

    if (assemblyInfoQuery.isLoading) {
        return <div>Assembly Info Card Loading...</div>;
    }

    if (!assemblyInfoQuery.data) return null;

    const info = assemblyInfoQuery.data;

    const titleInfos = [
        {
            name: "ASIN",
            value: info.asin,
        },
        {
            name: "SKU",
            value: info.vendor_sku,
        },
    ];

    return (
        <>
            <PageTitle
                name={info.listing_title}
                supplementaryInfo={titleInfos}
                externalLink={info.amazon_link}
            />
        </>
    );
}