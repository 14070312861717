
export const getFirstDay = new Date("2024-01-02");

export const getLatestDay = new Date(
    new Date().setDate(new Date().getDate() - 2)
);

export const getPast7d = new Date(
    new Date(getLatestDay).setDate(getLatestDay.getDate() - 7 + 1)
);

export const getPast30d = new Date(
    new Date(getLatestDay).setDate(getLatestDay.getDate() - 30 + 1)
);

export const getPast90d = new Date(
    new Date(getLatestDay).setDate(getLatestDay.getDate() - 90 + 1)
);

export const getPast180d = new Date(
    new Date(getLatestDay).setDate(getLatestDay.getDate() - 180 + 1)
);

export const getPast365d = new Date(
    new Date(getLatestDay).setDate(getLatestDay.getDate() - 365 + 1)
);

export const startOfWeek = (date: string | number | Date) => {
    const start = new Date(date);
    start.setDate(start.getDate() - start.getDay());
    return start;
};

export const endOfWeek = (date: string | number | Date) => {
    const end = new Date(date);
    end.setDate(end.getDate() + (6 - end.getDay()));
    return end;
};

export const startOfMonth = (date: string | number | Date) => {
    const start = new Date(date);
    start.setDate(1);
    return start;
};

export const endOfMonth = (date: string | number | Date) => {
    const end = new Date(date);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);
    return end;
};

export const startOfLastMonth = (date: string | number | Date) => {
    const start = new Date(date);
    start.setMonth(start.getMonth() - 1);
    start.setDate(1);
    return start;
};

export const endOfLastMonth = (date: string | number | Date) => {
    const end = new Date(date);
    end.setDate(0);
    return end;
};

export const StartOfLastQuarter = (date: string | number | Date) => {
    const currentDate = new Date(date);
    const currentMonth = currentDate.getMonth();
    const lastQuarterStartMonth = Math.floor(currentMonth / 3) * 3 - 3;

    const startOfQuarter = new Date(currentDate);
    startOfQuarter.setMonth(lastQuarterStartMonth);
    startOfQuarter.setDate(1);

    // If last quarter goes to the previous year
    if (lastQuarterStartMonth < 0) {
        startOfQuarter.setFullYear(startOfQuarter.getFullYear() - 1);
        startOfQuarter.setMonth(9); // October of the previous year
    }

    return startOfQuarter;
}

export const endOfLastQuarter = (date: string | number | Date) => {
    const currentDate = new Date(date);
    const currentMonth = currentDate.getMonth();
    const lastQuarterEndMonth = Math.floor(currentMonth / 3) * 3 - 1;

    const endOfQuarter = new Date(currentDate);
    endOfQuarter.setMonth(lastQuarterEndMonth + 1);
    endOfQuarter.setDate(0); // Set to the last day of the month

    // If last quarter goes to the previous year
    if (lastQuarterEndMonth < 0) {
        endOfQuarter.setFullYear(endOfQuarter.getFullYear() - 1);
        endOfQuarter.setMonth(11); // December of the previous year
    }

    return endOfQuarter;
};

export const startOfLastSixMonths = (date: string | number | Date) => {
    const start = new Date(date);
    start.setMonth(start.getMonth() - 6);
    start.setDate(1);
    return start;
};

export const startOfThisYear = (date: string | number | Date) => {
    const start = new Date(date);
    start.setFullYear(start.getFullYear());
    start.setMonth(0);
    start.setDate(1);
    return start;
};

export const startOfLastYear = (date: string | number | Date) => {
    const start = new Date(date);
    start.setFullYear(start.getFullYear() - 1);
    start.setMonth(0);
    start.setDate(1);
    return start;
};

export const endOfLastYear = (date: string | number | Date) => {
    const end = new Date(date);
    end.setFullYear(end.getFullYear() - 1);
    end.setMonth(12);
    end.setDate(0);
    return end;
};