import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {AdOrganicBarResponse} from "@/types";

export const getAssemblyOrganicBar = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}): Promise<AdOrganicBarResponse[]> => {
    return api.get(`/assembly/${assembly_item}/organic-bar`, {
        params: {
            brand,
        },
    });
}

export const getAssemblyOrganicBarQueryOptions = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", "organic-bar", brand, assembly_item],
        queryFn: () => getAssemblyOrganicBar({ brand, assembly_item }),
    })
}

type UseAssemblyOrganicBarOptions = {
    brand: string;
    assembly_item: string;
    queryConfig?: QueryConfig<typeof getAssemblyOrganicBarQueryOptions>;
}

export const useAssemblyOrganicBar = ({
    brand,
    assembly_item,
    queryConfig,
}: UseAssemblyOrganicBarOptions) => {
    return useQuery({
        ...getAssemblyOrganicBarQueryOptions({ brand, assembly_item }),
        ...queryConfig,
    })
}