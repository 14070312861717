import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import {QueryClientProvider} from "@tanstack/react-query";

import {queryClient} from "@/lib/react-query";
import {MainErrorFallback} from "@/components/errors/main";
import {AuthLoader} from "@/lib/auth";
import {DateRangeProvider} from "@/app/providers/date-range";
import {BrandProvider} from "@/app/providers/brand";
import {ToastContainer} from "react-toastify";


type AppProviderProps = {
    children: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
    return (
        <React.Suspense
            fallback={
                <div>
                    Loading...
                </div>
            }
        >
            <ErrorBoundary FallbackComponent={MainErrorFallback}>
                <QueryClientProvider client={queryClient}>
                    <AuthLoader
                        renderLoading={() => <div>Render Loading...</div>}
                    >
                        <DateRangeProvider>
                            <BrandProvider>
                                <ToastContainer newestOnTop pauseOnFocusLoss={false} />
                                {children}
                            </BrandProvider>
                        </DateRangeProvider>
                    </AuthLoader>
                </QueryClientProvider>
            </ErrorBoundary>
        </React.Suspense>
    );
}