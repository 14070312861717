import React, {useEffect, useState} from "react";
import {LoaderFunctionArgs, useParams, useSearchParams} from "react-router-dom";

import {QueryClient} from "@tanstack/react-query";
import {Range} from "react-date-range";

import {getComponentQueryOptions} from "@/features/component/api/get-component";
import {getComponentChartQueryOptions} from "@/features/component/api/get-component-chart";
import {ComponentCard} from "@/features/component/components/component-card";
import {ComponentChart} from "@/features/component/components/component-chart";
import {ComponentItems} from "@/features/component/components/component-items";
import {ComponentPurchaseForecasting} from "@/features/component/components/component-purchase-forecasting";
import {getComponentPurchaseForecastingQueryOptions} from "@/features/component/api/get-component-purchase-forecasting";
import {getComponentOpenPoQueryOptions} from "@/features/component/api/get-component-open-po";
import {getComponentItemsQueryOptions} from "@/features/component/api/get-component-items";
import {FormatType, formatValue} from "@/utils/formatter";
import {ComponentInfoCard} from "@/features/component/components/component-info";
import {useBrand} from "@/app/providers/brand";
import {useDateRange} from "@/app/providers/date-range";


export const componentDashboardLoader = (queryClient: QueryClient, brandParam: string, dateRange: Range[]) => async ({ params, request }: LoaderFunctionArgs) => {
    const productId = params.productId || "";
    const url = new URL(request.url);
    const brand = url.searchParams.get("brand") || brandParam;

    if (!brand || !productId || !dateRange) return null;

    const { startDate: startDateObj, endDate: endDateObj } = dateRange[0];
    const [ startDate, endDate ] = [formatValue(startDateObj, FormatType.DateForQuery), formatValue(endDateObj, FormatType.DateForQuery)];

    const componentQuery = getComponentQueryOptions({
        brand: brand,
        component_item: productId,
    });

    const componentChartQuery = getComponentChartQueryOptions({
        brand: brand,
        component_item: productId,
        period: "Custom",
        startDate,
        endDate,
    });

    const componentPurchaseForecastingQuery = getComponentPurchaseForecastingQueryOptions({
        brand: brand,
        component_item: productId,
    });

    const componentItemsQuery = getComponentItemsQueryOptions({
        brand: brand,
        component_item: productId,
        period: "Custom",
        startDate,
        endDate,
    });

    const componentOpenPoQuery = getComponentOpenPoQueryOptions({
        brand: brand,
        component_item: productId,
    })

    const promises = [
        queryClient.getQueryData(componentQuery.queryKey) ??
            (await queryClient.fetchQuery(componentQuery)),
        queryClient.getQueryData(componentChartQuery.queryKey) ??
            (await queryClient.fetchQuery(componentChartQuery)),
        queryClient.getQueryData(componentPurchaseForecastingQuery.queryKey) ??
            (await queryClient.fetchQuery(componentPurchaseForecastingQuery)),
        queryClient.getQueryData(componentItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(componentItemsQuery)),
        queryClient.getQueryData(componentOpenPoQuery.queryKey) ??
            (await queryClient.fetchQuery(componentOpenPoQuery)),
    ] as const;

    const [ component, componentChart, componentPurchaseForecasting, componentItems, componentOpenPo ] = await Promise.all(promises);

    return {
        component,
        componentChart,
        componentPurchaseForecasting,
        componentItems,
        componentOpenPo,
    }
}

export const ComponentDashboardRoute = () => {
    const { productId = "" } = useParams();
    const [searchParams] = useSearchParams();

    const { brand, setBrand } = useBrand();
    const { dateRange, setDateRange } = useDateRange();

    useEffect(() => {
        const brandParam = searchParams.get("brand");
        if (brandParam) {
            setBrand(brandParam);
        }
    }, [searchParams, setBrand]);

    if (!brand || !dateRange) return null;

    return (
        <>
            <ComponentInfoCard
                component_item={productId}
            />
            <div className={"flex flex-col items-start gap-[1px] overflow-auto"}>
                <ComponentCard
                    component_item={productId}
                />
                <div className={"flex w-full gap-x-[1px]"}>
                    <ComponentChart
                        component_item={productId}
                        dateRange={dateRange}
                    />
                    <ComponentPurchaseForecasting
                        component_item={productId}
                    />
                </div>
                <ComponentItems
                    component_item={productId}
                    dateRange={dateRange}
                />
            </div>
        </>
    );
}