import React, {useState} from "react";

import {QueryClient} from "@tanstack/react-query";
import {Range} from "react-date-range";

import {getAdvertisingChartQueryOptions} from "@/features/advertising/api/get-advertising-chart";
import {getAdvertisingItemsQueryOptions} from "@/features/advertising/api/get-advertising-items";
import {AdvertisingCard} from "@/features/advertising/components/advertising-card";
import {AdvertisingChart} from "@/features/advertising/components/advertising-chart";
import {AdvertisingItems} from "@/features/advertising/components/advertising-items";
import {AdvertisingFunnel} from "@/features/advertising/components/advertising-funnel";
import {AdvertisingOrganicBar} from "@/features/advertising/components/advertising-organic-bar";
import {getAdvertisingQueryOptions} from "@/features/advertising/api/get-advertising";
import {getAdvertisingOrganicBarQueryOptions} from "@/features/advertising/api/get-advertising-organic-bar";
import {getAdvertisingFunnnelQueryOptions} from "@/features/advertising/api/get-advertising-funnel";
import {PageTitle} from "@/components/layouts/page/layout";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";
import {useDateRange} from "@/app/providers/date-range";

export const advertisingDashboardLoader = (queryClient: QueryClient, brand: string, dateRange: Range[]) => async () => {
    if (!brand || !dateRange) return null;

    const { startDate: startDateObj, endDate: endDateObj } = dateRange[0];
    const [ startDate, endDate ] = [formatValue(startDateObj, FormatType.DateForQuery), formatValue(endDateObj, FormatType.DateForQuery)];

    const advertisingQuery = getAdvertisingQueryOptions({
        brand: brand,
    });

    const advertisingChartQuery = getAdvertisingChartQueryOptions({
        brand: brand,
        period: "Custom",
        startDate,
        endDate,
    });

    const advertisingOrganicBarQuery = getAdvertisingOrganicBarQueryOptions({
        brand: brand,
    })

    const advertisingFunnelQuery = getAdvertisingFunnnelQueryOptions({
        brand: brand,
        period: "Custom",
        startDate,
        endDate,
    })

    const advertisingItemsQuery = getAdvertisingItemsQueryOptions({
        brand: brand,
        period: "Custom",
        startDate,
        endDate,
    });

    const promises = [
        queryClient.getQueryData(advertisingQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingQuery)),
        queryClient.getQueryData(advertisingChartQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingChartQuery)),
        queryClient.getQueryData(advertisingOrganicBarQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingOrganicBarQuery)),
        queryClient.getQueryData(advertisingFunnelQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingFunnelQuery)),
        queryClient.getQueryData(advertisingItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingItemsQuery)),
    ] as const;

    const [advertisingCard, advertisingChart, advertisingOrganicBar, advertisingFunnel, advertisingItems] = await Promise.all(promises);

    return {
        advertisingCard,
        advertisingChart,
        advertisingOrganicBar,
        advertisingFunnel,
        advertisingItems,
    }
}

export const AdvertisingDashboardRoute = () => {
    const { brand } = useBrand();
    const { dateRange, setDateRange } = useDateRange();

    if (!brand || !dateRange) return null;

    return (
        <>
            <PageTitle name={"Advertising"} />
            <div className={"flex flex-col items-start gap-[1px] overflow-auto"}>
                <AdvertisingCard />
                <div className={"w-full"}>
                    <AdvertisingChart
                        dateRange={dateRange}
                        hideIfNoData={false}
                    />
                </div>
                <div className={"flex flex-row w-full gap-x-[1px]"}>
                    <AdvertisingOrganicBar />
                    <AdvertisingFunnel
                        dateRange={dateRange}
                        hideIfNoData={false}
                    />
                </div>
                <AdvertisingItems
                    dateRange={dateRange}
                />
            </div>
        </>
    );
}