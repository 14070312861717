import {Range} from "react-date-range";

import {useAdvertisingFunnel} from "@/features/advertising/api/get-advertising-funnel";
import {AdsFunnelChart} from "@/components/ui/chart/recharts/funnel/funnel";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";
import {funnelMock} from "@/components/ui/chart/mock";
import {NoDataGraphic} from "@/components/ui/chart/recharts/recharts";
import {adsFunnel} from "@/content/chart/no-data";

export const AdvertisingFunnel = ({
    dateRange,
    hideIfNoData,
    hidePeriod = false,
    height = "18.5rem",
}: {
    dateRange: Range[];
    hideIfNoData: boolean;
    hidePeriod?: boolean;
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const advertisingFunnelQuery = useAdvertisingFunnel({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    })

    if (advertisingFunnelQuery.isLoading) {
        return <div>Advertising Funnel Loading...</div>;
    }

    if (!advertisingFunnelQuery.data || (hideIfNoData && advertisingFunnelQuery.data.length === 0)) return null

    if (!hideIfNoData && advertisingFunnelQuery.data.length === 0) return (
        <AdsFunnelChart
            title={"Ad Funnel Insight"}
            series={funnelMock}
            period={formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate)}
            noDataGraphic={<NoDataGraphic content={adsFunnel} />}
        />
    );

    const series = advertisingFunnelQuery.data.map(({ value, ...rest }, index, array) => ({
        ...rest,
        value: value,
        nextValue: array[index + 1]?.value || value,
        rate: value / (array[index - 1]?.value || 1),
    }));

    return (
        // <FunnelChart
        //     title={"Ad Funnel"}
        //     series={advertisingFunnelQuery.data.map(row => [row.name, row.value])}
        //     height={height}
        //     colors={[
        //         echartColors.brown,
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <AdsFunnelChart
            title={"Ad Funnel Insight"}
            series={series}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
        />
    );
}