import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {AdOrganicBarResponse} from "@/types";

export const getAdvertisingOrganicBar = ({
    brand,
}: {
    brand: string;
}): Promise<AdOrganicBarResponse[]> => {
    return api.get(`/advertising/organic-bar`, {
        params: {
            brand,
        },
    });
}

export const getAdvertisingOrganicBarQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["advertising", "organic-bar", brand],
        queryFn: () => getAdvertisingOrganicBar({ brand }),
    })
}

type UseAdvertisingOrganicBarOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getAdvertisingOrganicBarQueryOptions>;
}

export const useAdvertisingOrganicBar = ({
    brand,
    queryConfig,
}: UseAdvertisingOrganicBarOptions) => {
    return useQuery({
        ...getAdvertisingOrganicBarQueryOptions({ brand }),
        ...queryConfig,
    })
}