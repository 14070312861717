import {useAssembly} from "@/features/assembly/api/get-assembly";
import SummaryCard from "@/components/ui/card/summary-card/summary-card";
import {useBrand} from "@/app/providers/brand";

export const AssemblyCard = ({
    assembly_item,
}: {
    assembly_item: string;
}) => {
    const { brand } = useBrand();

    const assemblyCardQuery = useAssembly({
        brand,
        assembly_item,
    });

    if (assemblyCardQuery.isLoading) {
        return <div>Assembly Card Loading...</div>;
    }

    if (!assemblyCardQuery.data) return null;

    return (
        <SummaryCard
            data={assemblyCardQuery.data}
        />
    );
}