import {Bounce, toast} from "react-toastify";
import {
    forgotPasswordErrors,
    loginErrors,
    registerErrors,
    resetPasswordErrors,
    serverErrors
} from "@/content/auth/errors";


const loginErrorMessage = (status: number | undefined) => {
    if (!status) return "Unknown Status";

    if (status === 400) {
        return loginErrors["400"];
    } else if (status === 429) {
        return loginErrors["429"];
    }

    return serverErrors["500"];
}

const registerErrorMessage = (status: number | undefined) => {
    if (!status) return "Unknown Status";

    if (status === 400) {
        return registerErrors["400"];
    } else if (status === 409) {
        return registerErrors["409"];
    } else if (status === 422) {
        return registerErrors["422"];
    }

    return serverErrors["500"];
}

const resetPasswordErrorMessage = (status: number | undefined) => {
    if (!status) return "Unknown Status";

    if (status === 400) {
        return resetPasswordErrors["400"];
    } else if (status === 422) {
        return resetPasswordErrors["422"];
    } else if (status === 429) {
        return resetPasswordErrors["429"];
    }

    return serverErrors["500"];
}

const forgotPasswordErrorMessage = (status: number | undefined) => {
    if (!status) return "Unknown Status";

    if (status === 429) {
        return forgotPasswordErrors["429"];
    }

    return serverErrors["500"];
}

const toastError = (message: string) => {
    toast.error(message, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });
}

const toastSuccess = (message: string) => {
    toast.success(message, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });
}

const toastInfo = (message: string) => {
    toast.info(message, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    })
}


export {
    loginErrorMessage,
    registerErrorMessage,
    resetPasswordErrorMessage,
    forgotPasswordErrorMessage,
    toastError,
    toastSuccess,
    toastInfo,
}