import React from "react";

import {QueryClient} from "@tanstack/react-query";
import {Range} from "react-date-range";

import {SalesChart} from "@/features/sales/components/sales-chart";
import {getSalesChartQueryOptions} from "@/features/sales/api/get-sales-chart";
import {SalesAnnualChart} from "@/features/sales/components/sales-annual-chart";
import {SalesItems} from "@/features/sales/components/sales-items";
import {getSalesAnnualChartQueryOptions} from "@/features/sales/api/get-sales-annual-chart";
import {getSalesItemsQueryOptions} from "@/features/sales/api/get-sales-items";
import {SalesCard} from "@/features/sales/components/sales-card";
import {getSalesQueryOptions} from "@/features/sales/api/get-sales";
import {PageTitle} from "@/components/layouts/page/layout";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";
import {useDateRange} from "@/app/providers/date-range";

export const salesDashboardLoader = (queryClient: QueryClient, brand: string, dateRange: Range[]) => async () => {
    if (!brand || !dateRange) return null;

    const { startDate: startDateObj, endDate: endDateObj } = dateRange[0];
    const [ startDate, endDate ] = [formatValue(startDateObj, FormatType.DateForQuery), formatValue(endDateObj, FormatType.DateForQuery)];

    const salesCardQuery = getSalesQueryOptions({
        brand: brand,
        period: "Custom",
        startDate,
        endDate,
    })

    const salesAnnualChartQuery = getSalesAnnualChartQueryOptions({
        brand: brand,
    })

    const salesChartQuery = getSalesChartQueryOptions({
        brand: brand,
        period: "Custom",
        startDate,
        endDate,
    });

    const salesItemsQuery = getSalesItemsQueryOptions({
        brand: brand,
        period: "Custom",
        startDate,
        endDate,
    })

    const promises = [
        queryClient.getQueryData(salesCardQuery.queryKey) ??
            (await queryClient.fetchQuery(salesCardQuery)),
        queryClient.getQueryData(salesAnnualChartQuery.queryKey) ??
            (await queryClient.fetchQuery(salesAnnualChartQuery)),
        queryClient.getQueryData(salesChartQuery.queryKey) ??
            (await queryClient.fetchQuery(salesChartQuery)),
        queryClient.getQueryData(salesItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(salesItemsQuery)),
    ] as const;
    const [salesCard, salesAnnualChart, salesChart, salesItems] = await Promise.all(promises);

    return {
        salesCard,
        salesAnnualChart,
        salesChart,
        salesItems
    }
}

export const SalesDashboardRoute = () => {
    const { brand } = useBrand();
    const { dateRange, setDateRange } = useDateRange();

    if (!brand || !dateRange) return null;

    return (
        <>
            <PageTitle name={"Sales"} />
            <div className={"flex flex-col items-start gap-[1px] overflow-auto"}>
                <SalesCard
                    dateRange={dateRange}
                />

                <div className={"flex w-full gap-[1px]"}>
                    <SalesChart
                        dateRange={dateRange}
                    />
                    <SalesAnnualChart />
                </div>

                <SalesItems
                    dateRange={dateRange}
                />
            </div>
        </>
    );
}