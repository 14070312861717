import * as React from "react";
import {HTMLAttributes} from "react";
import {cn} from "@/utils/cn";
import {Table} from "@tanstack/react-table";
import Button from "@/components/ui/button/button";

const PaginatorLeftArrowIcon = React.forwardRef<HTMLOrSVGElement, HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => {
        return (
            <svg className={cn("", className)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8337 10H4.16699M4.16699 10L10.0003 15.8333M4.16699 10L10.0003 4.16667" stroke="currentColor"
                      strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }
);

const PageButton = ({
                        table,
                        text,
                        isEnd = false,
                    }: {
    table: Table<any>;
    text: string;
    isEnd?: boolean;
}) => {
    const pageExisted = !isEnd ? table.getCanPreviousPage() : table.getCanNextPage();
    const pageMove = () => !isEnd ? table.previousPage() : table.nextPage();

    return (
        <Button
            variant="tablePaginator"
            onClick={pageMove}
            disabled={!pageExisted}
            className={`${!pageExisted ? "text-Grey-700" : "hover:text-Grey-200 hover:bg-Grey-800"}`}
        >
            <span className={"flex justify-center items-center gap-x-1.5"}>
                {isEnd && text}
                <PaginatorLeftArrowIcon className={`${isEnd ? "rotate-180" : ""}`} />
                {!isEnd && text}
            </span>
        </Button>
    );
}

const RenderJumpPages = ({
                             currentPage,
                             setPage,
                             page,
                         }: {
    currentPage: number;
    setPage: (page: number) => void;
    page: string | number;
}) => {
    if (typeof page === "string") {
        // Render ellipses
        return (
            <div
                className={"flex justify-center items-center w-10 h-10 p-2 rounded-[20px]"}
            >
                <span
                    className={"pointer-events-none text-Grey-400 text-sm"}
                >
                    ...
                </span>
            </div>
        );
    }

    // Render page buttons
    return (
        <Button
            variant={"tableJumpPage"}
            onClick={() => setPage(page)}
            className={currentPage === page ? "bg-Base-white text-Grey-700" : ""}
        >
            {page}
        </Button>
    );
}

/*
    Dynamic Page Numbers Logic
    Always Show:
        The first page (1).
        The last page (total pages).
        The current page.
    Dynamic Neighbors:
        Show a few pages before and after the current page (e.g., currentPage ± 2).
    Ellipses:
        Add ellipses (...) where there are gaps between ranges.
 */
const generatePageNumbers = ({
                                 currentPage,
                                 totalPages,
                                 range = 2,
                             }: {
    currentPage: number;
    totalPages: number;
    range?: number;
}) => {
    const pages = [];

    // Add the first page
    pages.push(1);
    // if neighbor area is out of range to 1, add ellipsis
    if (currentPage - range > 2) {
        pages.push('...');
    }

    // Add pages around the current Page
    for (let i = Math.max(2, currentPage - range); i <= Math.min(totalPages - 1, currentPage + range); i++) {
        pages.push(i);
    }

    // Add the last page
    if (currentPage < totalPages - range - 1) {
        pages.push('...', totalPages);
    } else if (totalPages > 1) {
        pages.push(totalPages);
    }

    return pages;
}

export {
    PageButton,
    RenderJumpPages,
    generatePageNumbers,
};