import FilterIcon from "@/assets/icons/table/filter.svg";
import * as React from "react";
import {HTMLAttributes, useEffect, useRef} from "react";
import {cva, VariantProps} from "class-variance-authority";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownTrigger} from "@/components/ui/dropdown/dropdown";
import {cn} from "@/utils/cn";
import {ExtendedColumnDef} from "@/components/ui/table/table";
import {HeaderGroup, Row, RowData} from "@tanstack/react-table";
import RangeSlider from "@/components/ui/input/range-slider";
import Button from "@/components/ui/button/button";


const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type FilterDropdownProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dropdownVariants> & {
    headerGroups: HeaderGroup<RowData>[];
    rows: any[];
};

const getInitialFilterValue = (headerGroups: HeaderGroup<RowData>[], rows: any[]) => {
    const initialMap: {[key: string] : {min: number; max: number;} | null} = {};

    headerGroups.forEach(headerGroup => (
        headerGroup.headers
            .filter((header) => (header.column.columnDef as ExtendedColumnDef<any>).filterable)
            .forEach((header, i) => {
                const values = rows.map(row => row[header.id]);

                if (values.every(value => typeof value === "number")) {
                    const min = Math.min(...values);
                    const max = Math.max(...values);

                    initialMap[header.id] = {min, max};
                } else {
                    initialMap[header.id] = null;
                }
            })
    ));

    return initialMap;
}

const getInitialActiveStates = (headerGroups: HeaderGroup<RowData>[]) => {
    const initialMap: {[key: string] : boolean} = {};

    headerGroups.forEach(headerGroup => (
        headerGroup.headers
            .filter((header) => (header.column.columnDef as ExtendedColumnDef<any>).filterable)
            .forEach((header, i) => {
                initialMap[header.id] = false;
            })
    ));

    return initialMap;
}

const FilterFooter = ({
    applyFilters,
    clearMap,
}: {
    applyFilters: () => void;
    clearMap: () => void;
}) => {
    return (
        <div className={"flex items-start justify-center self-stretch pt-2 pb-5 px-5 gap-x-5"}>
            <Button
                variant={"filterFooter"}
                onClick={() => clearMap()}
            >
                <span className={"text-Base-white text-sm"}>
                    Clear
                </span>
            </Button>
            <Button
                variant={"filterFooter"}
                className={"bg-Secondary-500"}
                onClick={() => applyFilters()}
            >
                <span className={"text-Grey-950 text-sm"}>
                    Apply filters
                </span>
            </Button>
        </div>
    );
}

const FilterDropdown = React.forwardRef<HTMLDivElement, FilterDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            headerGroups,
            rows,
            ...props
        },
        ref
    ) => {
        const [isOpen, setOpen] = React.useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const initialValueMap = getInitialFilterValue(headerGroups, rows);
        const [valueMap, setValueMap] = React.useState(initialValueMap);
        const initialActiveMap = getInitialActiveStates(headerGroups);
        const [isActiveMap, setActiveMap] = React.useState(initialActiveMap);

        const handleValueMapChange = (headerId: string) =>
            ({min, max} : {min: number; max: number;}) => {
                setValueMap((prevMap) => ({
                    ...prevMap,
                    [headerId]: {min, max},
                }));
        }

        const handleActiveMapChange = (headerId: string) =>
            (state: boolean) => {
            setActiveMap((prevMap) => ({
                ...prevMap,
                [headerId]: state,
            }))
        }

        const toggleDropdown = () => {
            setOpen(!isOpen);
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        const applyFilters = () => {
            headerGroups.map((headerGroup) => (
                headerGroup.headers
                    .filter((header) => (header.column.columnDef as ExtendedColumnDef<any>).filterable)
                    .map((header, i) => {
                        if (isActiveMap[header.id]) {
                            header.column.setFilterValue(() => [valueMap[header.id]?.min, valueMap[header.id]?.max]);
                        } else {
                            header.column.setFilterValue(() => [initialValueMap[header.id]?.min, initialValueMap[header.id]?.max]);
                        }
                    })
            ))
        }

        const clearMap = () => {
            setValueMap(initialValueMap);
            setActiveMap(initialActiveMap);

            headerGroups.map((headerGroup) => (
                headerGroup.headers
                    .filter((header) => (header.column.columnDef as ExtendedColumnDef<any>).filterable)
                    .map((header, i) => {
                        header.column.setFilterValue(() => [initialValueMap[header.id]?.min, initialValueMap[header.id]?.max]);
                    })
            ));
        }

        return (
            <DropdownMenu ref={dropdownRef} className={cn("relative", { variant, size, className })} {...props}>
                <DropdownTrigger
                    aria-expanded={isOpen}
                    onClick={toggleDropdown}
                    isOpen={isOpen}
                    hideIcon={true}
                    className={"bg-Grey-900 rounded-lg border-[1px] border-Grey-800 hover:border-Grey-600"}
                >
                    <span
                        className={"flex w-10 h-10 justify-center items-center gap-x-1"}>
                        <img src={FilterIcon} alt={"FilterDropdownIcon"}/>
                    </span>
                </DropdownTrigger>

                {isOpen ? (
                    <DropdownMenuContent className={"flex flex-col items-start absolute w-[360px] right-0 mt-1 rounded-lg border-[1px] border-Grey-800 bg-Grey-900"}>
                        <div className={"flex flex-col h-[314px] overflow-y-auto overflow-x-hidden items-start p-5 gap-y-5 self-stretch"}>
                            {headerGroups.map((headerGroup) => (
                                headerGroup.headers
                                    .filter((header) => (header.column.columnDef as ExtendedColumnDef<any>).filterable)
                                    .map((header, i) => (
                                        <RangeSlider
                                            key={"slider-" + header.id}
                                            min={initialValueMap[header.id]?.min ?? 0}
                                            max={initialValueMap[header.id]?.max ?? 100}
                                            value={valueMap[header.id]}
                                            onChange={handleValueMapChange(header.id)}
                                            header={header}
                                            isActive={isActiveMap[header.id]}
                                            setActive={handleActiveMapChange(header.id)}
                                        />
                                    ))
                            ))}
                        </div>
                        <FilterFooter
                            applyFilters={applyFilters}
                            clearMap={clearMap}
                        />
                    </DropdownMenuContent>
                ) : null
                }
            </DropdownMenu>
        );
    }
);

export default FilterDropdown;