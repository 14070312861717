import * as React from "react";
import {useMemo} from "react";

import {usePurchaseItems} from "@/features/purchase/api/get-purchase-items";
import {Table} from "@/components/ui/table/tanStack/pagination-table/pagination-table";
import {ExtendedColumnDef} from "@/components/ui/table/table";
import {
    CellForecastAmount,
    CellText, CellViewDetail,
} from "@/components/ui/table/tanStack/cell-render";
import {useBrand} from "@/app/providers/brand";
import {PurchaseColumnDefFn} from "@/components/ui/table/tanStack/column-def";


export const PurchaseItems = ({
    filter,
}: {
    filter: string;
}) => {
    const { brand } = useBrand();

    const purchaseItemsQuery = usePurchaseItems({
        brand: brand,
        selector: filter
    });

    const dynamicColumns : ExtendedColumnDef<any>[] = useMemo(() => {
        // Generate dynamic columns only if there is data
        if (!purchaseItemsQuery.data) return [];

        return purchaseItemsQuery.data.columns
            .filter(col => col.type?.toLowerCase() === "dynamic")
            .map((col, i) => ({
                accessorFn: row => row[col.name],
                id: col.name,
                cell: info => <CellForecastAmount quantity={info.getValue()["quantity"]} amount={info.getValue()["amount"]} {...(i < 2 && {highlight: true})} />,
                header: col.name,
                size: 100,
                filterable: true,
            }));
    }, [purchaseItemsQuery.data]);

    const columns: ExtendedColumnDef<any>[] = useMemo(() => PurchaseColumnDefFn(dynamicColumns), []);

    if (purchaseItemsQuery.isLoading) {
        return <div>Purchase Table Loading...</div>;
    }

    if (!purchaseItemsQuery.data) return null;

    // const visMapper: ColumnVisMapperProps = {
    //     ...purchaseItemsQuery.data.columns.reduce((acc: ColumnVisMapperProps, col) => {
    //         if (col.type?.toLowerCase() === "dynamic" && Object.keys(acc).length < 2){
    //             acc[col.name] = ColumnVisMeta.Highlight;
    //         }
    //         return acc;
    //     }, {})
    // }

    return (
        // <div>
        //     <PaginationTable
        //         data={purchaseItemsQuery.data.data.map((item: { [key: string]: any }) => ({
        //             ...item,
        //             ...purchaseItemsQuery.data.columns.reduce((acc: { [key: string]: any }, col) => {
        //                 if (col.type?.toLowerCase() === "dynamic"){
        //                     acc[col.name] = numberF(item[col.name]);
        //                 }
        //                 return acc;
        //             }, {})
        //         }))}
        //         columns={purchaseItemsQuery.data.columns}
        //         initialFilters={{}}
        //         visMapper={visMapper}
        //     />
        // </div>
        <Table
            title={"Forecast by Component Item"}
            data={purchaseItemsQuery.data.data}
            columns={columns}
            defaultSearchColumn={"Item Name"}
            filteredData={purchaseItemsQuery.data.data.map((item: any) => {
                const {vendor_sku, upc, item_name, view_link, ...rest} = item;

                const modified_rest = Object.entries(rest).reduce((obj: Record<string, string>, [key, value]) => {
                    const { amount, quantity } = value as { amount: number; quantity: number };
                    obj[`${key} quantity`] = `${quantity}`;
                    obj[`${key} amount`] = `${Math.round(amount * 100) / 100}`;
                    return obj;
                }, {} as Record<string, string>);

                return { vendor_sku, upc, item_name, ...modified_rest };
            })}
        />
    );
}