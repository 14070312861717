import {ReturnCustomerResponse} from "@/types";
import {api} from "@/lib/api-client";
import {queryOptions, useQuery} from "@tanstack/react-query";
import {QueryConfig} from "@/lib/react-query";

export const getAssemblyRepeatCustomer = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}): Promise<ReturnCustomerResponse[]> => {
    return api.get(`/assembly/${assembly_item}/repeat-customer`, {
        params: {
            brand,
            assembly_item,
        },
    });
}

export const getAssemblyRepeatCustomerQueryOptions = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", "repeat-customer", brand, assembly_item],
        queryFn: () => getAssemblyRepeatCustomer({ brand, assembly_item }),
    })
}

type UseAssemblyRepeatCustomerOptions = {
    brand: string;
    assembly_item: string;
    queryConfig?: QueryConfig<typeof getAssemblyRepeatCustomerQueryOptions>
}

export const useAssemblyRepeatCustomer = ({
    brand,
    assembly_item,
    queryConfig,
}: UseAssemblyRepeatCustomerOptions) => {
    return useQuery({
        ...getAssemblyRepeatCustomerQueryOptions({ brand, assembly_item }),
        ...queryConfig,
    })
}