import * as React from "react";

import {cva, VariantProps} from "class-variance-authority";

import {CardResponse} from "@/types";
import {
    Card, CardBody, CardContent, CardHeader,
    CardTrend,
} from "@/components/ui/card/card";
import {FormatType, formatValue} from "@/utils/formatter";
import {MultipleItemCardInfo} from "@/components/ui/card/config";


const summaryCardVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                extraSmall: '',
                small: '',
                medium: '',
                large: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'medium',
        },
    },
);

export type SummaryCardProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof summaryCardVariants> & {
    data: CardResponse;
};

const SummaryCard = React.forwardRef<HTMLDivElement, SummaryCardProps>(
    (
        {
            className,
            variant,
            size,
            children,
            data,
            ...props
        },
        ref,
    ) => {
        const summaryPeriods = [7, 30, 365];

        const getSummaryTitle = (period: number) => {
            return `Last ${period} Days`;
        }

        return (
            <div className={"flex items-start self-stretch gap-[1px]"}>
                {data.summary.map((summary, summary_i) => (
                    <Card key={summary_i} wrapperVariant={"multiple"} variant={"multiple"}>
                        <CardHeader variant={"multiple"}>
                            <div className={"flex items-center gap-3"}>
                                <div className={"flex items-center gap-2.5 p-1.5 bg-Grey-900 rounded-md border border-Grey-800"}>
                                    {MultipleItemCardInfo[getSummaryTitle(summaryPeriods[summary_i])].icon}
                                </div>
                                <span className={"text-Grey-300 text-base"}>
                                    {getSummaryTitle(summaryPeriods[summary_i])}
                                </span>
                            </div>
                        </CardHeader>
                        <CardBody variant={"multiple"} wrapperVariant={"multiple"}>
                            {summary.data.map((item, index) => (
                                <CardContent key={index} variant={"multiple"} {...(item.name !== "Units" && {gap: "trend"})}>
                                    <span className={"text-Grey-400 text-base"}>
                                        {item.name}
                                    </span>
                                    <div className={"flex flex-col justify-center items-start gap-2.5"}>
                                        <span className={"text-Base-white text-[34px] font-bold leading-[36px] tracking-[-0.68px]"}>
                                            {formatValue(item.value, MultipleItemCardInfo[item.name]?.format ?? FormatType.Integer)}
                                        </span>
                                        {MultipleItemCardInfo[item.name]?.format === FormatType.Currency && (
                                            <CardTrend
                                                key={index}
                                                dataEntry={item}
                                                period={summaryPeriods[summary_i]}
                                            />
                                        )}
                                    </div>
                                </CardContent>
                            ))}
                        </CardBody>
                    </Card>
                ))}
            </div>
        );
    }
);


export default SummaryCard;