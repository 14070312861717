import {useStockCard} from "@/features/inventory/api/get-stock-card";
import StockCardIcon from "@/assets/icons/stock.svg";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";

export const StockCard = () => {
    const { brand } = useBrand();

    const stockCardQuery = useStockCard({
        brand,
    });

    if (stockCardQuery.isLoading) {
        return <div>Stock Card Loading...</div>;
    }

    if (!stockCardQuery.data) return null;

    return (
        <div className={"flex justify-center items-end gap-1.5 rounded-[46px] shadow-xs"}>
            <div className={"flex items-center gap-1.5"}>
                <img src={StockCardIcon} alt={"StockCardIcon"}/>
                <span className={"truncate text-Grey-300 text-sm"}>
                    In Stock
                </span>
            </div>
            <span className={"text-Grey-100 text-center text-sm font-bold"}>
                {formatValue(stockCardQuery.data.summary[0].data[0].value, FormatType.Percent)}
            </span>
        </div>
    );
}