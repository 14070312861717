import {Location, useLocation} from "react-router-dom";
import {env} from "@/config/env";

/*
 RULES
 ex.
 http://192.168.1.15:3000/app/assembly/B077J7Q4DC?brand=ADORE&from=null
 {APP_URL}                app/ {currentPage URL} ?   {Search Param}
                                                        /      \
                                                    {brand}  {prevPageNames}
 */
const DetailPages = ["ASIN", "SKU"];
const HomePageName = 'home';

const RoutePageNameMap: Record<string, string> = {
    '': 'Home',
    [HomePageName]: 'Home',
    'sales': 'Sales',
    'products': 'Products',
    'advertising': 'Advertising',
    'purchase': 'Purchase',
    'profile': 'Profile',
    'component': 'SKU',
    'assembly': 'ASIN',
};

// get current page name from url
const getCurrentPageName = (location: Location<any>) => {
    const currentRoutes = location.pathname.split("/").slice(2);

    const currentRouteName = currentRoutes.length === 0 ? '' : currentRoutes[0];

    return RoutePageNameMap[currentRouteName];
}

const getCurrentPageUrl = (location: Location<any>) => {
    const currentRoutes = location.pathname.split("/").slice(2).join("/");

    if (!currentRoutes) return '';

    return currentRoutes;
}

// get most recent prev page name
const getPrevPageName = (location: Location<any>) => {
    const params = new URLSearchParams(location.search);
    const prevPagesUrl = params.get("from")?.split(",");

    if (!prevPagesUrl) return null;

    const prevPageUrl = prevPagesUrl[prevPagesUrl.length - 1];
    const prevPageUrlName = prevPageUrl.split("/")[0];

    return RoutePageNameMap[prevPageUrlName];
}

// get most recent prev page url
const getPrevPageUrl = (location: Location<any>) => {
    const params = new URLSearchParams(location.search);
    const prevPagesUrl = params.get("from")?.split(",");

    if (!prevPagesUrl) return null;

    const prevPageUrl = prevPagesUrl[prevPagesUrl.length - 1];

    return `app/${prevPageUrl === HomePageName ? '' : prevPageUrl}`;
}

const getPrevPagesArr = (location: Location<any>) => {
    const params = new URLSearchParams(location.search);
    const prevPagesUrl = params.get("from")?.split(",");

    if (!prevPagesUrl) return null;

    return prevPagesUrl;
}

const getParentPrevPages = (location: Location<any>) => {
    const params = new URLSearchParams(location.search);
    const prevPages = params.get("from")?.split(",");

    if (!prevPages) return null;

    const parentPrevPages = prevPages.slice(0, prevPages.length - 1);

    return parentPrevPages.join(",");
}

const setPrevPages = (location: Location<any>, currentPageUrl: string) => {
    const params = new URLSearchParams(location.search);
    const prevPages = params.get("from")?.split(",");

    if (!prevPages) return currentPageUrl !== '' ? currentPageUrl: HomePageName;

    prevPages.push(currentPageUrl);

    return prevPages.join(",");
}

const toSearchParamsText = (mainPath: string, searchParams: {param: string; link: string}[]) => {
    const searchParamsText = searchParams
        .filter(({ link }) => link.trim() !== '')
        .map(({ param,  link }) => `${encodeURIComponent(param)}=${encodeURIComponent(link)}`)
        .join('&');
    return `${mainPath}${searchParamsText ? `?${searchParamsText}` : ''}`;
}

const jumpLinkViewDetail = ({
    view_link,
    location,
    brand,
}: {
    view_link: string;
    location: Location<any>;
    brand: string;
}) => {
    const currentPageUrl = getCurrentPageUrl(location);
    const prevPages = setPrevPages(location, currentPageUrl);

    const mainPath = `${env.APP_URL}app/${view_link}`;
    const searchParams = [
        {
            param: "brand",
            link: `${brand}`,
        },
        {
            param: "from",
            link: `${prevPages}`,
        },
    ];

    return toSearchParamsText(mainPath, searchParams);
}

const isDashboardPage = (location: Location<any>) => {
    const prevPageName = getPrevPageName(location);

    return !DetailPages.includes(prevPageName ?? "");
}

const jumpLinkPrevPage = ({
    location,
    brand,
}: {
    location: Location<any>;
    brand: string;
}) => {
    const prevPageURL = getPrevPageUrl(location);
    const parentPrevPages = getParentPrevPages(location);

    const mainPath = `${env.APP_URL}${prevPageURL}`;
    const searchParams = [
        {
            param: "brand",
            link: `${isDashboardPage(location) ? '' : brand}`,
        },
        {
            param: "from",
            link: `${parentPrevPages}`,
        },
    ];

    return toSearchParamsText(mainPath, searchParams);
}

const jumpLinkPrevPageFromParams = ({
    prevPages,
    brand,
}: {
    prevPages: string[];
    brand: string;
}) => {
    const prevPageURL = prevPages[prevPages.length - 1];
    const parentPrevPages = prevPages.slice(0, prevPages.length - 1);

    const mainPath = `${env.APP_URL}app/${prevPageURL === HomePageName ? '' : prevPageURL}`;
    const searchParams = [
        {
            param: "brand",
            link: `${!DetailPages.includes(RoutePageNameMap[prevPageURL.split("/")[0]]) ? '' : brand}`,
        },
        {
            param: "from",
            link: `${parentPrevPages.join(",")}`,
        },
    ];

    return toSearchParamsText(mainPath, searchParams);
}


export {
    RoutePageNameMap,
    getCurrentPageName,
    getPrevPageName,
    getPrevPageUrl,
    getPrevPagesArr,
    jumpLinkViewDetail,
    jumpLinkPrevPage,
    jumpLinkPrevPageFromParams,
}