import {useAssemblyRepeatCustomer} from "@/features/assembly/api/get-assembly-repeat-customer";
import {ProgressBarNoGraph} from "@/components/ui/chart/recharts/bar/progress-bar";
import {useBrand} from "@/app/providers/brand";

export const AssemblyRepeatCustomer = ({
    assembly_item,
    height = "18.5rem",
}: {
    assembly_item: string;
    height?: string;
}) => {
    const { brand } = useBrand();

    const assemblyRepeatCustomerQuery = useAssemblyRepeatCustomer({
        brand,
        assembly_item,
    });

    if (assemblyRepeatCustomerQuery.isLoading) {
        return <div>Assembly Repeat Customer Loading...</div>;
    }

    if (!assemblyRepeatCustomerQuery.data || assemblyRepeatCustomerQuery.data.length === 0) return null;

    // rename data fields if applicable
    const series = assemblyRepeatCustomerQuery.data.reduce<{ [key: string]: number }>((obj, item) => {
        obj[item.name] = item.value;
        return obj;
    }, {});

    return (
        // <DonutChart
        //     title={"New vs Repeat Customers"}
        //     labels={["New Customers", "Repeat Customers"]}
        //     series={assemblyRepeatCustomerQuery.data.map(row => [row.name, row.value])}
        //     height={height}
        //     center={['35%', '55%']}
        // />
        <ProgressBarNoGraph
            title={"New & Repeat Customers"}
            series={[series]}
        />
    );
}