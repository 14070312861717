import {queryOptions, useQuery} from "@tanstack/react-query";

import {CardResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getIcon = ({
    brand,
}: {
    brand: string;
}) => {
    return api.get(`/brand/icon`, {
        params: {
            brand,
        }
    })
}

export const getIconQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["brand", "icon", brand],
        queryFn: () => getIcon({ brand }),
    })
}

type UseIconQueryOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getIconQueryOptions>;
}

export const useIcon = ({
    brand,
    queryConfig,
}: UseIconQueryOptions) => {
    return useQuery({
        ...getIconQueryOptions({ brand }),
        ...queryConfig,
    })
}