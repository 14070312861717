import ExternalLinkIcon from "@/assets/icons/table/external-link.svg";
import {FormatType, formatValue} from "@/utils/formatter";
import ViewDetailIcon from "@/assets/icons/table/view-detail.svg";
import * as React from "react";
import {Link, useLocation} from "react-router-dom";
import {jumpLinkViewDetail} from "@/utils/page-trace";
import {useBrand} from "@/app/providers/brand";

const CellImage = ({
    url,
    status,
}: {
    url: string;
    status: string;
}) => {
    return (
        <span className={"absolute"}>
            <img className={"block w-[58px] h-[58px] rounded-md"} src={url} alt={"ProductImage"}/>
            <div className={`absolute bottom-0 right-0 z-10 w-3 h-3 shrink-0 ${status?.toLowerCase() === "active" ? "bg-Green-500" : "bg-Grey-300"} rounded-full border-2 border-Grey-950`}/>
        </span>
    );
}

const CellHyperlink = ({
                           prefix_text,
                           url,
                       }: {
    prefix_text: string;
    url: string;
}) => {
    return (
        <span className={"flex"}>
            <a
                href={url}
                target={"_blank"}
                rel={"noopener noreferrer"}
                className={"flex justify-center items-center pb-1 gap-x-1 border-b-[1px] border-Grey-800"}
            >
            <span className={"text-Base-white text-sm"}>
                {prefix_text}
            </span>
            <img className={""} src={ExternalLinkIcon} alt={"ExternalLinkIcon"}/>
        </a>
        </span>
    );
}

const CellText = ({
    truncateWidth,
    text,
}: {
    truncateWidth?: number;
    text: string;
}) => {
    return (
        <span
            className={`block text-Base-white text-sm ${truncateWidth && `truncate`}`}
            style={{width: truncateWidth ? `${truncateWidth}px` : "auto"}}
        >
            {text}
        </span>
    );
}

const CellCurrency = ({
                          total,
                          average,
                      }: {
    total: number;
    average?: number;
}) => {
    return (
        <span className={"flex flex-col"}>
            <span className={"text-Green-700 text-sm"}>
                {formatValue(total, FormatType.Currency)}
            </span>
            {average ? (
                <span className={"text-Grey-400 text-sm"}>
                    Avg {formatValue(average, FormatType.Currency)}
                </span>
            ): null}
        </span>
    );
}

const CellForecastAmount = ({
    quantity,
    amount,
    highlight,
}: {
    quantity: number;
    amount: number;
    highlight?: boolean;
}) => {
    return (
        <span className={"flex flex-col"}>
            <span className={`${highlight ? "text-Green-700": "text-Base-white"} text-sm`}>
                {formatValue(quantity, FormatType.Integer)}
            </span>
            <span className={"text-Grey-400 text-sm"}>
                {formatValue(amount, FormatType.Currency)}
            </span>
        </span>
    );
}

const CellInteger = ({
                         value,
                     }: {
    value: number;
}) => {
    return (
        <span className={"text-Base-white text-sm"}>
            {formatValue(value, FormatType.Integer)}
        </span>
    );
}

const CellFloat = ({
                         value,
                     }: {
    value: number;
}) => {
    return (
        <span className={"text-Base-white text-sm"}>
            {formatValue(value, FormatType.Float)}
        </span>
    );
}

const CellPercent = ({
    value,
}: {
    value: number;
}) => {
    return (
        <span className={"text-Base-white text-sm"}>
            {formatValue(value, FormatType.Percent)}
        </span>
    );
}

const Badge = ({
    text,
    colors,
}: {
    text: string;
    colors: {background: string; border: string; dot: string; text: string};
}) => {
    return (
        <span className={`flex items-center py-1 px-2.5 gap-1 ${colors.background} rounded-[9999px] border ${colors.border}`}>
            <div className={"flex justify-center items-center w-2 h-2"}>
                <div className={`w-1.5 h-1.5 rounded-full ${colors.dot}`}/>
            </div>
            <span className={`${colors.text} text-center text-sm font-medium`}>
                {text}
            </span>
        </span>
    );
}

const CellStatus = ({
                        text,
                        status,
                    }: {
    text: string;
    status: boolean;
}) => {
    if (status) {
        return (
            <Badge
                text={text}
                colors={{
                    background: "bg-Green-950",
                    border: "border-Green-800",
                    dot: "bg-Green-600",
                    text: "text-Green-300",
                }}
            />
        );
    }

    return (
        <Badge
            text={text}
            colors={{
                background: "bg-Secondary-950",
                border: "border-Secondary-900",
                dot: "bg-Secondary-400",
                text: "text-Secondary-200",
            }}
        />
    );
}

const CellViewDetail = ({
    url,
}: {
    url: string;
}) => {
    const location = useLocation();
    const {brand} = useBrand();

    return (
        <Link
            to={jumpLinkViewDetail({view_link: url, location, brand})}
            target={"_blank"}
            rel={"noopener noreferrer"}
            className={"flex justify-center items-center w-8 h-8 flex-shrink-0 bg-Grey-950 rounded-[5px] border-[1px] border-Grey-800"}
        >
            <img src={ViewDetailIcon} alt={"ViewDetailIcon"}/>
        </Link>
    );
}

export {
    CellImage,
    CellHyperlink,
    CellText,
    CellCurrency,
    CellForecastAmount,
    CellInteger,
    CellFloat,
    CellPercent,
    CellStatus,
    CellViewDetail,
};