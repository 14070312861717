import * as React from "react";
import {useEffect, useRef} from "react";

import {createStaticRanges, DateRangePicker, Range, RangeKeyDict} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '@/styles/custom-date-range.css';
import { cva, type VariantProps} from "class-variance-authority";
import { enUS } from 'date-fns/locale';

import {cn} from "@/utils/cn";
import {
    endOfLastMonth, endOfLastQuarter, endOfLastYear,
    getFirstDay,
    getLatestDay,
    getPast180d,
    getPast30d,
    getPast365d,
    getPast7d,
    getPast90d, startOfLastMonth, StartOfLastQuarter, startOfLastYear, startOfThisYear
} from "@/utils/date_retriever";
import Button from "@/components/ui/button/button";


const dateRangePickerVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
)

export type DateRangePickerProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dateRangePickerVariants> & {
    dateRange: Range[];
    setDateRange: (dateRange: Range[]) => void;
    cancelFn: () => void;
    applyFn: () => void;
}

const DateRangePickerButton = ({
    cancelFn,
    applyFn,
}: {
    cancelFn: () => void;
    applyFn: () => void;
}) => {
    return (
        <div className={"flex items-start absolute bottom-4 right-4 gap-3"}>
            <Button
                variant={"modal"}
                className={"bg-Grey-900 border border-Grey-800"}
                onClick={cancelFn}
            >
                <div className={"flex justify-center items-center px-0.5"}>
                    <span className={"text-Grey-400 text-sm"}>
                        Cancel
                    </span>
                </div>
            </Button>
            <Button
                variant={"modal"}
                onClick={applyFn}
            >
                <div className={"flex justify-center items-center px-0.5"}>
                    <span className={"text-Grey-950 text-sm"}>
                        Apply
                    </span>
                </div>
            </Button>
        </div>
    );
}

const SimpleDateRangePicker = React.forwardRef<HTMLDivElement, DateRangePickerProps>(
    (
        {
            className,
            variant,
            size,
            children,
            dateRange,
            setDateRange,
            cancelFn,
            applyFn,
            ...props
        },
        ref
    ) => {


        const handleSelect = (ranges: RangeKeyDict) => {
            const {selection} = ranges;
            setDateRange([selection]);
        }

        return (
            <div className={"relative flex items-start bg-Grey-950 rounded-xl shadow-xl"}>
                <DateRangePicker
                    className={cn("", dateRangePickerVariants({variant, size, className}))}
                    ranges={dateRange}
                    onChange={handleSelect}
                    locale={enUS}
                    maxDate={getLatestDay}
                    minDate={getFirstDay}
                    months={2}
                    direction={"horizontal"}
                    staticRanges={staticRanges}
                    inputRanges={[]}
                    showPreview={true}
                    moveRangeOnFirstSelection={false}
                    showMonthAndYearPickers={false}
                />
                <DateRangePickerButton
                    cancelFn={cancelFn}
                    applyFn={applyFn}
                />
            </div>
        )
    }
);


const today = getLatestDay;

const staticRanges = createStaticRanges([
    {
        label: 'Last 7 Days',
        range: () => ({
            startDate: getPast7d,
            endDate: today,
        }),
    },
    {
        label: 'Last 30 Days',
        range: () => ({
            startDate: getPast30d,
            endDate: today,
        }),
    },
    {
        label: 'Last 90 Days',
        range: () => ({
            startDate: getPast90d,
            endDate: today,
        }),
    },
    {
        label: 'Last 180 Days',
        range: () => ({
            startDate: getPast180d,
            endDate: today,
        }),
    },
    {
        label: 'Last 365 Days',
        range: () => ({
            startDate: getPast365d,
            endDate: today,
        }),
    },
    {
        label: 'Last Month',
        range: () => ({
            startDate: startOfLastMonth(today),
            endDate: endOfLastMonth(today),
        }),
    },
    {
        label: 'Last Quarter',
        range: () => ({
            startDate: StartOfLastQuarter(today),
            endDate: endOfLastQuarter(today),
        }),
    },
    {
        label: 'This Year',
        range: () => ({
            startDate: startOfThisYear(today),
            endDate: today,
        }),
    },
    {
        label: 'Last Year',
        range: () => ({
            startDate: startOfLastYear(today),
            endDate: endOfLastYear(today),
        }),
    },
]);

export default SimpleDateRangePicker;