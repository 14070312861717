import {queryOptions, useQuery} from "@tanstack/react-query";

import {SalesChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getComponentChart = ({
    brand,
    period,
    startDate,
    endDate,
    component_item,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    component_item: string;
}): Promise<SalesChartResponse[]> => {
    return api.get(`/products/${component_item}/chart`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getComponentChartQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
    component_item,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    component_item: string;
}) => {
    return queryOptions({
        queryKey: ["component", "chart", brand, period, startDate, endDate, component_item],
        queryFn: () => getComponentChart({ brand, period, startDate, endDate, component_item }),
    })
}

type UseProductChartOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    component_item: string;
    queryConfig?: QueryConfig<typeof getComponentChartQueryOptions>;
}

export const useProductCharts = ({
    brand,
    period,
    startDate,
    endDate,
    component_item,
    queryConfig,
}: UseProductChartOptions) => {
    return useQuery({
        ...getComponentChartQueryOptions({ brand, period, startDate, endDate, component_item }),
        ...queryConfig
    })
}