import * as React from "react";

import {Range} from "react-date-range";

import {useAdvertisingChart} from "@/features/advertising/api/get-advertising-chart";
import {TimeSeriesLineChart} from "@/components/ui/chart/recharts/line/line";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {FormatType, formatValue} from "@/utils/formatter";
import {ChartSupplementaryFields, NoDataGraphic} from "@/components/ui/chart/recharts/recharts";
import {colors} from "@/styles/colors";
import {useBrand} from "@/app/providers/brand";
import {lineMock} from "@/components/ui/chart/mock";
import {adsLine} from "@/content/chart/no-data";

export const AdvertisingChart = ({
    dateRange,
    hideIfNoData,
    hidePeriod = false,
    height = "21.4rem",
}: {
    dateRange: Range[];
    hideIfNoData: boolean;
    hidePeriod?: boolean;
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const advertisingChartQuery = useAdvertisingChart({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (advertisingChartQuery.isLoading) {
        return <div>Advertising Chart Loading...</div>;
    }

    if (!advertisingChartQuery.data || (hideIfNoData && advertisingChartQuery.data.length === 0)) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "sales",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "roas",
            yAxisId: "right1",
            color: colors.base.white,
            format: FormatType.Float,
            yAxisHide: true,
        },
        {
            name: "spend",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Currency,
        },
    ]

    if (!hideIfNoData && advertisingChartQuery.data.length === 0) return (
        <TimeSeriesLineChart
            title={"Advertising Insight"}
            labels={labels}
            series={lineMock}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
            noDataGraphic={<NoDataGraphic content={adsLine} />}
        />
    );

    const series = advertisingChartQuery.data.map(({ ad_sales, ad_spend, ...rest }) => ({
        ...rest,
        sales: ad_sales,
        spend: ad_spend,
    }));

    const totalSales = series.reduce((sum, serie) => sum + serie.sales, 0);
    const averageROAS = series.reduce((sum, serie) => sum + serie.roas, 0) / series.length;

    return (
        // <MultipleAxisTimeValueLineChart
        //     title={"Advertising Summary"}
        //     labels={[
        //         { name: "Sales", position: "right" },
        //         { name: "ROAS", position: "left", offset: 30 },
        //         { name: "Spend", position: "left" },
        //     ]}
        //     series={[
        //         advertisingChartQuery.data.map(row => [row.date, row.total_ad_sales]),
        //         advertisingChartQuery.data.map(row => [row.date, row.roas]),
        //         advertisingChartQuery.data.map(row => [row.date, row.total_ad_spend]),
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //         echartColors.white,
        //     ]}
        // />
        <TimeSeriesLineChart
            title={"Advertising Insight"}
            labels={labels}
            series={series}
            {...(!hidePeriod && { period: formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate) })}
            chartSupplementaryFields={(
                <ChartSupplementaryFields
                    mainKPI={formatValue(totalSales, FormatType.Currency)}
                    subtitle={"Average ROAS"}
                    value={formatValue(averageROAS, FormatType.Float)}
                />
            )}
        />
    );
}