import {queryOptions, useQuery} from "@tanstack/react-query";

import {AdFunnelResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getAssemblyFunnel = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<AdFunnelResponse[]> => {
    return api.get(`/assembly/${assembly_item}/funnel`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        }
    })
}

export const getAssemblyFunnelQueryOptions = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", "funnel", brand, assembly_item, period, startDate, endDate],
        queryFn: () => getAssemblyFunnel({ brand, assembly_item, period, startDate, endDate }),
    })
}

type UseAssemblyFunnelOptions = {
    brand: string;
    assembly_item: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getAssemblyFunnelQueryOptions>;
}

export const useAssemblyFunnel = ({
    brand,
    assembly_item,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseAssemblyFunnelOptions) => {
    return useQuery({
        ...getAssemblyFunnelQueryOptions({ brand, assembly_item, period, startDate, endDate }),
        ...queryConfig,
    })
}