import React, {useEffect, useState} from "react";
import {LoaderFunctionArgs, useParams, useSearchParams} from "react-router-dom";

import {QueryClient} from "@tanstack/react-query";
import {Range} from "react-date-range";

import {AssemblyCard} from "@/features/assembly/components/assembly-card";
import {AssemblyChart} from "@/features/assembly/components/assembly-chart";
import {AssemblyAdvertisingChart} from "@/features/assembly/components/assembly-advertising-chart";
import {AssemblyFunnel} from "@/features/assembly/components/assembly-funnel";
import {AssemblyOrganicBar} from "@/features/assembly/components/assembly-organic-bar";
import {AssemblyRepeatCustomer} from "@/features/assembly/components/assembly-repeat-customer";
import {getAssemblyQueryOptions} from "@/features/assembly/api/get-assembly";
import {getAssemblyChartQueryOptions} from "@/features/assembly/api/get-assembly-chart";
import {getAssemblyAdvertisingChartQueryOptions} from "@/features/assembly/api/get-assembly-advertising-chart";
import {getAssemblyFunnelQueryOptions} from "@/features/assembly/api/get-assembly-funnel";
import {getAssemblyOrganicBarQueryOptions} from "@/features/assembly/api/get-assembly-organic-bar";
import {getAssemblyRepeatCustomerQueryOptions} from "@/features/assembly/api/get-assembly-repeat-customer";
import {AssemblyInfoCard} from "@/features/assembly/components/assembly-info";
import {FormatType, formatValue} from "@/utils/formatter";
import {AssemblyDetailsCard} from "@/features/assembly/components/assembly-details";
import {useBrand} from "@/app/providers/brand";
import {useDateRange} from "@/app/providers/date-range";


export const assemblyDashboardLoader = (queryClient: QueryClient, brandParam: string, dateRange: Range[]) => async ({ params, request }: LoaderFunctionArgs) => {
    const assemblyId = params.assemblyId || "";
    const url = new URL(request.url);
    const brand = url.searchParams.get("brand") || brandParam;

    if(!brand || !assemblyId || !dateRange) return null;

    const { startDate: startDateObj, endDate: endDateObj } = dateRange[0];
    const [ startDate, endDate ] = [formatValue(startDateObj, FormatType.DateForQuery), formatValue(endDateObj, FormatType.DateForQuery)];

    const assemblyCardQuery = getAssemblyQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
    })

    const assemblyChartQuery = getAssemblyChartQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
        period: "Custom",
        startDate,
        endDate,
    })

    const assemblyAdvertisingChartQuery = getAssemblyAdvertisingChartQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
        period: "Custom",
        startDate,
        endDate,
    })

    const assemblyFunnelQuery = getAssemblyFunnelQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
        period: "Custom",
        startDate,
        endDate,
    })

    const assemblyOrganicBarQuery = getAssemblyOrganicBarQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
    })

    const assemblyRepeatCustomerQuery = getAssemblyRepeatCustomerQueryOptions({
        brand: brand,
        assembly_item: assemblyId,
    })

    const promises = [
        queryClient.getQueryData(assemblyCardQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyCardQuery)),
        queryClient.getQueryData(assemblyChartQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyChartQuery)),
        queryClient.getQueryData(assemblyAdvertisingChartQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyAdvertisingChartQuery)),
        queryClient.getQueryData(assemblyFunnelQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyFunnelQuery)),
        queryClient.getQueryData(assemblyOrganicBarQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyOrganicBarQuery)),
        queryClient.getQueryData(assemblyRepeatCustomerQuery.queryKey) ??
            (await queryClient.fetchQuery(assemblyRepeatCustomerQuery)),
    ] as const;

    const [ assemblyCard, assemblyChart, assemblyAdvertisingChart, assemblyFunnel, assemblyOrganicBar, assemblyRepeatCustomer ] = await Promise.all(promises);

    return {
        assemblyCard,
        assemblyChart,
        assemblyAdvertisingChart,
        assemblyFunnel,
        assemblyOrganicBar,
        assemblyRepeatCustomer,
    };
}

export const AssemblyDashboardRoute = () => {
    const { assemblyId = "" } = useParams();
    const [searchParams] = useSearchParams();

    const { brand, setBrand } = useBrand();
    const { dateRange, setDateRange } = useDateRange();

    useEffect(() => {
        const brandParam = searchParams.get("brand");
        if (brandParam) {
            setBrand(brandParam);
        }
    }, [searchParams, setBrand]);

    if (!brand || !dateRange) return null;

    return (
        <>
            <AssemblyInfoCard
                assembly_item={assemblyId}
            />
            <div className={"flex flex-col items-start gap-[1px] overflow-auto"}>
                <AssemblyDetailsCard
                    assembly_item={assemblyId}
                />
                <AssemblyCard
                    assembly_item={assemblyId}
                />
                <AssemblyRepeatCustomer
                    assembly_item={assemblyId}
                />
                <div className={"grid grid-cols-2 w-full gap-[1px]"}>
                    <AssemblyChart
                        assembly_item={assemblyId}
                        dateRange={dateRange}
                    />
                    <AssemblyAdvertisingChart
                        assembly_item={assemblyId}
                        dateRange={dateRange}
                    />
                    <AssemblyOrganicBar
                        assembly_item={assemblyId}
                    />
                    <AssemblyFunnel
                        assembly_item={assemblyId}
                        dateRange={dateRange}
                    />
                </div>
            </div>
        </>
    );
}