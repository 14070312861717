import {queryOptions, useQuery} from "@tanstack/react-query";

import {CardResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getAssembly = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}): Promise<CardResponse> => {
    return api.get(`/assembly/${assembly_item}`, {
        params: {
            brand,
        }
    })
}

export const getAssemblyQueryOptions = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", brand, assembly_item],
        queryFn: () => getAssembly({ brand, assembly_item }),
    })
}

type UseAssemblyOptions = {
    brand: string;
    assembly_item: string;
    queryConfig?: QueryConfig<typeof getAssemblyQueryOptions>;
}

export const useAssembly = ({
    brand,
    assembly_item,
    queryConfig,
}: UseAssemblyOptions) => {
    return useQuery({
        ...getAssemblyQueryOptions({ brand, assembly_item }),
        ...queryConfig,
    })
}