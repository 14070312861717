import React, {useEffect, useState} from "react";

import {useLogout, useUser} from "@/lib/auth";
import RigleLogo from "@/assets/rigle-logo.svg";
import BrandDropdown from "@/components/ui/dropdown/brand";
import NavBar from "@/components/ui/side-bar/nav-bar";
import LogoutIcon from "@/assets/icons/user/logout.svg";
import { CircleUser } from 'lucide-react';
import {colors} from "@/styles/colors";
import {useBrand} from "@/app/providers/brand";
import {useLocation, useNavigate} from "react-router-dom";
import {useAdvertisingAccess} from "@/features/advertising/api/get-advertising-access";
import {usePurchaseAccess} from "@/features/purchase/api/get-purchase-access";


const LogoContainer = () => {
    const navigate = useNavigate();

    return (
        <div
            className={"flex items-center self-stretch h-[76px] px-[22px] gap-x-2.5 bg-Grey-950 rounded-br-[14px] cursor-pointer"}
            onClick={() => navigate('/')}
        >
            <img src={RigleLogo} alt="RigleLogo" />
            <span className={"bg-gradient-to-bl from-Primary-600 to-Secondary-500 bg-clip-text text-transparent text-base italic font-black leading-[16px] tracking-[-0.16px] uppercase select-none"}>
                accelerate
            </span>
        </div>
    );
}

const BrandSwitchContainer = () => {
    const user = useUser();
    const { brand, setBrand } = useBrand();
    const location = useLocation();
    const [pageName, setPageName] = useState<string>("");

    useEffect(() => {
        if (user.data && brand === "") {
            setBrand(user.data.brand[0]);
        }

        setPageName(location.pathname.split("/").slice(2, 3).join("/"));
    }, [user.data, setBrand, brand, location]);

    return (
        <div className={"flex flex-col justify-center items-start self-stretch h-[76px] px-5 gap-2.5 bg-Grey-950 rounded-r-[14px]"}>
            <BrandDropdown
                brands={['assembly', 'component'].includes(pageName) ? [] : user.data.brand}
            />
        </div>
    );
}

const SideNavigation = () => {
    const { brand } = useBrand();

    if (!brand) return null;

    return <SideMenu brand={brand} />
}

const SideMenu = ({brand}: {brand: string}) => {
    const advertisingAccess = useAdvertisingAccess({ brand });
    const noAdAccess = advertisingAccess.data !== "fullDataAccess";

    const purchaseAccess = usePurchaseAccess({ brand });
    const noPurchaseAccess = purchaseAccess.data !== "fullDataAccess";

    const menuNavigate = [
        { name: "Dashboard", to: "." },
        { name: "Sales", to: "./sales" },
        { name: "Products", to: "./products" },
        { name: "Advertising", to: "./advertising", noAccess: noAdAccess },
        { name: "Analytics", to: "#",
            subTabs: [
                { name: "Purchase Forecast", to: "./purchase", noAccess: noPurchaseAccess },
                // { name: "Brand Analytics", to: "./analytics" },
            ]
        },
    ]

    const optionNavigate = [
        { name: "Settings", to: "./profile" },
        // { name: "Support", to: "./support" },
    ]

    return (
        <div className={"flex flex-col items-start w-[260px] px-3 py-[22px] gap-5 flex-1 bg-Grey-950 rounded-r-[14px]"}>
            <NavBar title={"Main Menu"} menu={menuNavigate} />
            <NavBar title={"More Options"} menu={optionNavigate} />
        </div>
    );
}

const UserContainer = () => {
    const user = useUser();
    const logout = useLogout();

    return (
        <div className={"flex flex-col justify-center items-start self-stretch gap-2.5 p-5 bg-Grey-950 rounded-tr-[14px]"}>
            <div className={"flex justify-between items-center self-stretch h-[41px] rounded-2xl"}>
                <div className={"flex items-start gap-3"}>
                    <CircleUser width={40} height={40} color={colors.grey["300"]} />
                    <div className={"flex flex-col items-start gap-[1px]"}>
                        <span className={"text-Grey-100 text-base leading-[20px]"}>
                            {user.data.name}
                        </span>
                        <span className={"w-[150px] truncate text-Grey-400 text-sm"}>
                            {user.data.email}
                        </span>
                    </div>
                </div>
                <div
                    className={"hover:cursor-pointer"}
                    onClick={() => logout.mutate({})}
                >
                    <img
                        src={LogoutIcon}
                        alt={"LogoutIcon"}
                    />
                </div>
            </div>
        </div>
    );
}

const MainLayout = ({
    children
}: {
    children: React.ReactNode;
}) => {
    return (
        <div className={"flex items-start gap-[1px] h-screen overflow-hidden bg-Base-black"}>
            <aside className={"flex flex-col items-start self-stretch w-[260px] gap-[1px]"}>
                <LogoContainer />
                <BrandSwitchContainer />
                <SideNavigation />
                <UserContainer />
            </aside>
            <main className={"flex flex-col item-start self-stretch gap-[1px] w-full overflow-auto"}>
                {children}
            </main>
        </div>
    );
}

export default MainLayout;