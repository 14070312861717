import {Range} from "react-date-range";

import {useAssemblyAdvertisingChart} from "@/features/assembly/api/get-assembly-advertising-chart";
import {TimeSeriesLineChart} from "@/components/ui/chart/recharts/line/line";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {FormatType, formatValue} from "@/utils/formatter";
import {useBrand} from "@/app/providers/brand";


export const AssemblyAdvertisingChart = ({
    assembly_item,
    dateRange,
    height = "21.4rem",
}: {
    assembly_item: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const assemblyAdvertisingChartQuery = useAssemblyAdvertisingChart({
        brand,
        assembly_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (assemblyAdvertisingChartQuery.isLoading) {
        return <div>Assembly Advertising Charts Loading...</div>;
    }

    if (!assemblyAdvertisingChartQuery.data || assemblyAdvertisingChartQuery.data.length === 0) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "sales",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "roas",
            yAxisId: "right1",
            color: colors.base.white,
            format: FormatType.Float,
            yAxisHide: true,
        },
        {
            name: "spend",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Currency,
        },
    ]

    const series = assemblyAdvertisingChartQuery.data.map(({ ad_sales, ad_spend, ...rest }) => ({
        ...rest,
        sales: ad_sales,
        spend: ad_spend,
    }));

    return (
        // <MultipleAxisTimeValueLineChart
        //     title={"Advertising Summary"}
        //     labels={[
        //         { name: "Sales", position: "right" },
        //         { name: "ROAS", position: "left", offset: 30 },
        //         { name: "Spend", position: "left" },
        //     ]}
        //     series={[
        //         assemblyAdvertisingChartQuery.data.map(row => [row.date, row.total_ad_sales]),
        //         assemblyAdvertisingChartQuery.data.map(row => [row.date, row.roas]),
        //         assemblyAdvertisingChartQuery.data.map(row => [row.date, row.total_ad_spend]),
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //         echartColors.white,
        //     ]}
        // />
        <TimeSeriesLineChart
            title={"Advertising Insight"}
            labels={labels}
            series={series}
            period={formatValue(startDate, FormatType.LongDate) + " – " + formatValue(endDate, FormatType.LongDate)}
        />
    );
}