import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps} from "class-variance-authority";
import * as React from "react";
import {cn} from "@/utils/cn";
import {Spinner} from "@/components/ui/spinner/spinner";
import {ClassValue} from "clsx";


/*
    grant permission
    user
    |- login
    |- register
    |- logout
    |- setting update
    |- setting cancel
    |- setting delete
    navigation
    |- menu
    |- brand dropdown
    |- expand navigation
    |- navigate backward
    table
    |- dropdown
    |- filter
    |- download
    |- paginator prev, next
    settings
    |- cancel
    |- update
    |- delete
 */
const buttonVariants = cva(
    "flex items-center justify-center",
    {
        variants: {
            variant: {
                default: "",
                // grant permission, user - login, register
                formSubmit: "inline-flex items-center pl-3 pr-4 py-2 gap-x-1.5 bg-Secondary-500 rounded-md text-Grey-950",
                // table
                tablePaginator: "inline-flex justify-center items-center pl-2.5 pr-3.5 gap-x-1.5 h-10 rounded-[10px] text-Grey-500 text-base leading-[20px]",
                tableJumpPage: "inline-flex justify-center items-center w-10 h-10 rounded-full text-Grey-400 text-base leading-[20px] hover:border-[1px] hover:border-Grey-600",
                dropdownMenuItem: "flex flex-col items-start pl-2 pr-2.5 py-2.5 gap-2 flex-1 rounded-md hover:bg-Grey-800 focus:bg-Grey-800",
                filterFooter: "flex items-center justify-center flex-1 px-4 py-3 gap-x-1.5 rounded-md bg-Grey-600 shadow-xs",
                // modal button
                modal: "flex justify-center items-center gap-1 px-3.5 py-2.5 rounded-lg bg-Primary-500 shadow-xs",
                // settings
                settingCancel: "flex justify-center items-center gap-[14px] px-[18px] py-2.5 rounded-md bg-Grey-800 border border-Grey-700 shadow-xs",
                settingUpdate: "flex justify-center items-center gap-2 h-10 px-[18px] rounded-lg bg-gradient-to-bl from-Primary-600 to-Secondary-500",
                // auth
                authForm: "flex justify-center items-center self-stretch gap-1.5 px-4 py-3 rounded-[10px] bg-Secondary-500 shadow-xs outline-none",
                // unused
                icon: "inline-flex rounded-full border border-gray-200 bg-white text-gray-700 shadow-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400",
                rigle: "bg-gradient-to-r from-rigleYellow to-rigleGreen text-black text-lg font-bold font-['ABC Diatype'] leading-snug rounded-xl",
                tableExport: "px-4 bg-[#E3E811] rounded-lg",
                detailLink: "bg-[#38FD33] text-black hover:bg-[#1BDB16]",
                amazonLink: "bg-[#231F20] text-white hover:bg-black",
            },
            size: {
                default: '',
                regular: "text-xs",
                medium: "text-base",
                dropdown: "",
                icon: "size-[46px]",
                tableLink: "px-4 py-3 rounded-[10px] ",
                full: "w-full py-3",
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    isLoading?: boolean;
    icon?: React.ReactNode;
    iconClassName?: ClassValue;
    contentClassName?: ClassValue;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            size,
            asChild = false,
            children,
            isLoading,
            icon,
            iconClassName,
            contentClassName,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                ref={ref}
                className={cn(buttonVariants({variant, size, className}))}
                {...props}
            >
                {isLoading && <Spinner size={"sm"} className={"text-current"} />}
                {!isLoading && icon && <span className={cn("flex-shrink-0", iconClassName)}>{icon}</span>}
                <span className={cn(`pointer-events-none w-full`, contentClassName)}>{children}</span>
            </Comp>
        );
    },
);

export default Button;