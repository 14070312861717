import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";


export const getPurchaseAccess = ({
    brand,
}: {
    brand: string;
}): Promise<"noForecastAccess" | "fullDataAccess"> => {
    return api.get(`/purchase/access`, {
        params: {
            brand,
        }
    })
}

export const getPurchaseAccessQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["purchase", "access", brand],
        queryFn: () => getPurchaseAccess({ brand }),
    })
}

type UsePurchaseAccessQueryOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getPurchaseAccessQueryOptions>;
}

export const usePurchaseAccess = ({
    brand,
    queryConfig,
}: UsePurchaseAccessQueryOptions) => {
    return useQuery({
        ...getPurchaseAccessQueryOptions({ brand }),
        ...queryConfig,
    })
}