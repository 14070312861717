import {useBrand} from "@/app/providers/brand";
import {useIcon} from "@/features/brand/api/get-icon";


export const BrandIcon = ({
    brand,
    size,
}: {
    brand: string;
    size?: number;
}) => {
    const iconQuery = useIcon({
        brand,
    });

    if (iconQuery.isLoading) {
        return <div>Icon...</div>;
    }

    if (!iconQuery.data) return null;

    return (
        <img
            className={size ? `w-[${size}px] h-[${size}px]` : ''}
            src={String(iconQuery.data)}
            alt={"BrandIcon"}
        />
    );
}

export default BrandIcon;