import * as React from "react";
import {createContext, useContext} from "react";

import {Range} from "react-date-range";

import {getLatestDay, getPast30d} from "@/utils/date_retriever";


type DateRangeContextType = {
    dateRange: Range[];
    setDateRange: React.Dispatch<React.SetStateAction<Range[]>>;
}

const DateRangeContext = createContext<DateRangeContextType | undefined>(undefined);

// Hook for context easy use
const useDateRange = (): DateRangeContextType => {
    const context = useContext(DateRangeContext);
    if (!context) {
        throw new Error("useDateRange must be used within a DateRangeProvider");
    }

    return context;
}

// Provider component
const DateRangeProvider = ({
                               children,
                           }: {
    children: React.ReactNode;
}) => {
    const [dateRange, setDateRange] = React.useState<Range[]>(
        [{
            startDate: getPast30d,
            endDate: getLatestDay,
            key: "selection",
        }]
    );

    return (
        <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
            {children}
        </DateRangeContext.Provider>
    );
}


export {
    useDateRange,
    DateRangeProvider,
}