import * as React from "react";
import {dataPosProps, lineHeightFn, logScaleOff, logScaleOn, RechartsProps} from "@/components/ui/chart/charts";
import {Funnel, FunnelChart, ResponsiveContainer} from "recharts";
import {ChartTitle, NoDataGraphic} from "@/components/ui/chart/recharts/recharts";
import FunnelIcon from "@/assets/icons/graph/funnel.svg";
import {colors} from "@/styles/colors";
import {FormatType, formatValue} from "@/utils/formatter";


const getEndPoints = ({
    x,
    y,
    value,
    nextValue,
    upperWidth,
    width,
    height,
    verticalExpandScale,
    horizontalExpandScale,
}: {
    x: number;
    y: number;
    value: number;
    nextValue: number;
    upperWidth: number;
    width: number;
    height: number;
    verticalExpandScale: number;
    horizontalExpandScale: number;
}) => {
    /*
        determined by value, topLeft
        determined by nextValue, topRight
        determined by differences between value and nextValue controlPoint1
     */

    // convert origin vertical funnel graph to horizontal funnel graph
    const bottomPos = width / horizontalExpandScale;
    const expandedLeftPos = y * horizontalExpandScale;
    const expandedRightPos = (y + height) * horizontalExpandScale;

    // x, y reversed, horizontal funnel graph
    const bottomLeft: dataPosProps = { x: expandedLeftPos, y: bottomPos };
    const bottomRight: dataPosProps = { x: expandedRightPos, y: bottomPos };
    const topLeft: dataPosProps = { x: expandedLeftPos, y: bottomPos - value * verticalExpandScale };
    const topRight: dataPosProps = { x: expandedRightPos, y: bottomPos - nextValue * verticalExpandScale };

    return { bottomLeft, bottomRight, topLeft, topRight }
}

const getPath = (props: any) => {
    const { value, nextValue, topLeft, topRight, bottomLeft, bottomRight } = props;

    // xx% from top left, between topLeft and topRight
    const topEndPointFn = (scaleX: number, scaleY: number) => {
        return {
            x: topLeft.x + (topRight.x - topLeft.x) * scaleX,
            y: topLeft.y + (topRight.y - topLeft.y) * scaleY,
        };
    };

    // const topEndPoint = topEndPointFn(0.6, 0.4);

    // evenly divided points on the top line
    const controlPoint1: dataPosProps = { y: topLeft.y - (value - nextValue) * 10, x: topEndPointFn(1/3, 0.5).x };
    const controlPoint2: dataPosProps = { y: topRight.y, x: topEndPointFn(2/3, 0.7).x };

    return (`
        M ${topLeft.x} ${topLeft.y}
        C ${controlPoint1.x} ${controlPoint1.y}, ${controlPoint2.x} ${controlPoint2.y}, ${topRight.x} ${topRight.y}
        L ${bottomRight.x} ${bottomRight.y}
        L ${bottomLeft.x} ${bottomLeft.y}
        Z
    `);
}

const KPIText = ({
    name,
    value,
    padLeft,
}: {
    name: string;
    value: string;
    padLeft: number;
}) => {
    const lineHeight20 = lineHeightFn(20);

    return (
        <>
            <tspan
                x={padLeft}
                dy={lineHeight20.height - lineHeight20.offsetFn(14)}
                fill={colors.grey["400"]}
                fontSize={14}
                fontWeight={400}
            >
                {name}
            </tspan>
            <tspan dy={lineHeight20.offsetFn(14) + 4}>&#8203;</tspan>
            <tspan
                x={padLeft}
                dy={lineHeight20.height - lineHeight20.offsetFn(16)}
                fill={colors.base.white}
                fontSize={16}
                fontWeight={700}
            >
                {value}
            </tspan>
            <tspan dy={lineHeight20.offsetFn(16)}>&#8203;</tspan>
        </>
    );
}

const CustomFunnel = (props: any) => {
    const {x, y, name, value, nextValue, rate, upperWidth, height, parentViewBox} = props;

    const textPad = {left: 16, right: 16, top: 2, bottom: 0};

    const {
        bottomLeft,
        bottomRight,
        topLeft,
        topRight
    } = getEndPoints(
        {
            x,
            y,
            value,
            nextValue,
            upperWidth,
            width: parentViewBox.width,
            height,
            verticalExpandScale: 3,
            horizontalExpandScale: parentViewBox.width / parentViewBox.height,
        }
    );

    return (
        <g>
            <defs>
                {/* Primary Gradient */}
                <linearGradient id={"funnelPrimaryGradient"} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="3%" stopColor={colors.primary["500"]} stopOpacity="1"/>
                    <stop offset="15%" stopColor={colors.primary["500"]} stopOpacity="0.88"/>
                    <stop offset="100%" stopColor={colors.primary["500"]} stopOpacity="0.0"/>
                </linearGradient>
                <linearGradient id={"funnelSecondaryGradient"} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={colors.accent} stopOpacity="1"/>
                    <stop offset="100%" stopColor={colors.accent} stopOpacity="0.0"/>
                </linearGradient>
            </defs>

            {/* Main Funnel */}
            <path
                d={getPath({value, nextValue, bottomLeft, bottomRight, topLeft, topRight})}
                fill={name === "Impressions" ? "url(#funnelPrimaryGradient)" : "url(#funnelSecondaryGradient)"}
                fillOpacity={0.4}
            />

            {/* Supplementary lines */}
            {name === "Impressions" && (
                <line
                    x1={bottomLeft.x}
                    y1={0}
                    x2={bottomLeft.x}
                    y2={x + upperWidth / 2}
                    stroke={colors.base.white}
                    opacity={0.05}
                />
            )}
            <line
                x1={bottomRight.x}
                y1={0}
                x2={bottomRight.x}
                y2={x + upperWidth / 2}
                stroke={colors.base.white}
                opacity={0.05}
            />

            {/* Supplementary texts */}
            <text
                x={bottomLeft.x + textPad.left}
                y={textPad.top}
            >
                <tspan dy={2}>&#8203;</tspan>
                <KPIText
                    name={name}
                    value={formatValue(logScaleOff(value), FormatType.Integer)}
                    padLeft={bottomLeft.x + textPad.left}
                />
                {name !== "Impressions" && (
                    <>
                        <tspan dy={28}>&#8203;</tspan>
                        <KPIText
                            name={name === "Clicks" ? "Click-through Rate" : "Conversion Rate"}
                            value={formatValue(rate, FormatType.Percent)}
                            padLeft={bottomLeft.x + textPad.left}
                        />
                    </>
                )}
            </text>
        </g>
    );
}

const AdsFunnelChart = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & RechartsProps>(
    (
        {
            className,
            children,
            title,
            series,
            noDataGraphic,
            period,
        },
        ref
    ) => {
        return (
            <div className={"relative flex flex-col h-full justify-between flex-1 p-7 bg-Grey-950 rounded-[14px]"}>
                <div className={"flex flex-col gap-y-5 pb-8"}>
                    <ChartTitle icon={FunnelIcon} title={title} period={period}/>
                </div>
                {noDataGraphic}
                <ResponsiveContainer
                    width={"100%"}
                    height={216}
                    className={noDataGraphic ? "blur pointer-events-none select-none" : ""}
                >
                    <FunnelChart>
                        <Funnel
                            data={series.map(({value, nextValue, ...rest}) => ({...rest, value: logScaleOn(value), nextValue: logScaleOn(nextValue)}))}
                            dataKey={"value"}
                            lastShapeType={"rectangle"}
                            shape={<CustomFunnel />}
                        />
                    </FunnelChart>
                </ResponsiveContainer>
            </div>
        );
    }
);

export {
    AdsFunnelChart
};