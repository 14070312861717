import * as React from "react";
import {createContext, useContext, useState} from "react";


type BrandContextType = {
    brand: string;
    setBrand: React.Dispatch<React.SetStateAction<string>>;
}

const BrandContext = createContext<BrandContextType | undefined>(undefined);

const useBrand = (): BrandContextType => {
    const context = useContext(BrandContext);
    if (!context) {
        throw new Error("useBrand must be used within a BrandProvider")
    }

    return context
}

const BrandProvider = ({
                           children,
                       }: {
    children: React.ReactNode
}) => {
    const [brand, setBrand] = useState("");

    return (
        <BrandContext.Provider value={{ brand, setBrand }}>
            {children}
        </BrandContext.Provider>
    );
}


export {
    useBrand,
    BrandProvider,
}