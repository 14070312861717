import * as React from "react";
import {HTMLAttributes} from "react";

import {cva, VariantProps} from "class-variance-authority";

import {cn} from "@/utils/cn";
import {DataEntry} from "@/types";
import {FormatType, formatValue} from "@/utils/formatter";
import TrendPositive from "@/assets/icons/card/trend-positive.svg";
import TrendNegative from "@/assets/icons/card/trend-negative.svg";

/*
    Card
    |- card
    |- header
    |- body
    |- content
    Usage
    |- single item
    |- multiple items
    |- carousel
    |-
 */
const cardVariants = cva(
    "",
    {
        variants: {
            wrapperVariant: {
                default: "",
                single: "flex flex-wrap items-start content-start p-7 gap-2.5 flex-1 rounded-[14px] bg-Grey-950",
                multiple: "flex flex-col items-start self-stretch shrink-0 flex-1",
                carousel_sm: "flex items-center content-center flex-wrap flex-1 gap-3 p-6 rounded-[14px] bg-Grey-950",
                carousel_md: "bg-Grey-950 w-full",
            },
            variant: {
                default: "",
                single: "flex flex-col items-start gap-6 flex-1",
                multiple: "flex flex-col items-start self-stretch",
                carousel_sm: "flex items-start flex-1 gap-4",
                carousel_md: "flex items-start flex-1 gap-1 p-1",
            },
        },
        defaultVariants: {
            wrapperVariant: "default",
            variant: "default",
        },
    },
);

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>>(
    (
        {
            className,
            wrapperVariant,
            variant,
            ...props
        },
        ref,
    ) => (
        <div className={cn(cardVariants({ wrapperVariant }))}>
            <div className={cn(cardVariants({ variant, className }))} {...props}/>
        </div>
    )
);

const headerVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
                single: "flex items-center gap-3",
                multiple: "flex flex-wrap items-start content-start self-stretch gap-2.5 p-7 rounded-t-[14px] bg-Grey-950",
            },
        },
        defaultVariants: {
            variant: "default",
        }
    },
);

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof headerVariants>>(
    (
        {
            className,
            variant,
            ...props
        },
        ref
    ) => (
        <div className={cn(headerVariants({ variant, className }))} {...props} />
    )
);

const bodyVariants = cva(
    "",
    {
        variants: {
            wrapperVariant: {
                default: "",
                single: "w-full",
                multiple: "flex flex-wrap items-start self-stretch gap-2.5 pt-5 pb-7 px-7 rounded-b-[14px] bg-Grey-950",
            },
            variant: {
                default: "",
                multiple: "flex flex-col items-start self-stretch flex-1 gap-7",
            },
        },
        defaultVariants: {
            wrapperVariant: "default",
            variant: "default",
        },
    },
);

const CardBody = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof bodyVariants>>(
    (
        {
            className,
            wrapperVariant,
            variant,
            ...props
        },
        ref
    ) => (
        <div className={cn(bodyVariants({ wrapperVariant }))}>
            <div className={cn(bodyVariants({ variant, className }))} {...props} />
        </div>
    )
);

const contentVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
                single: "flex flex-col items-start self-stretch relative gap-3",
                multiple: "flex flex-col items-start self-stretch relative gap-4",
                carousel_sm: "flex flex-col justify-between items-start self-stretch",
                carousel_md: "flex flex-col justify-between items-start self-stretch flex-1 p-5",
            },
            gap: {
                default: "",
                trend: "mb-7",
            },
        },
        defaultVariants: {
            variant: "default",
            gap: "default",
        },
    },
);

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof contentVariants>>(
    (
        {
            className,
            variant,
            gap,
            ...props
        },
        ref
    ) => (
        <div className={cn(contentVariants({ variant, gap, className }))} {...props} />
    )
);

const CardTrend = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { dataEntry: DataEntry; period?: number; }>(
    (
        {
            className,
            dataEntry,
            period = 30,
            ...props
        },
        ref
    ) => {
        if (!dataEntry.value_last || dataEntry.value_last === 0) {
            return null;
        }
        const percent = (dataEntry.value as number) / dataEntry.value_last - 1;

        return (
            <div className={"flex h-5 items-center absolute left-0 top-[calc(100%+10px)] gap-2"}>
                <div className={"flex justify-center items-center gap-0.5"}>
                    {percent > 0 ?
                        <img src={TrendPositive} alt={"Trend Positive"} />
                        :
                        <img src={TrendNegative} alt={"Trend Negative"} />
                    }
                    <span className={`${percent > 0 ? "text-Green-700" : "text-Red-700"} text-center text-sm`}>
                        {formatValue(Math.abs(percent), FormatType.Percent)}
                    </span>
                </div>
                <span className={"truncated text-Grey-300 text-sm"}>
                    Last vs. Prior {period} Days
                </span>
            </div>
        );
    }
);

export {
    Card,
    CardHeader,
    CardBody,
    CardContent,
    CardTrend,
};