
export const colors = {
    alpha: {
        white5: 'rgba(255, 255, 255, 0.05)',
        black20: 'rgba(0, 0, 0, 0.20)',
        black50: 'rgba(0, 0, 0, 0.50)',
        secondary5: 'rgba(227, 232, 17, 0.05)',
        secondary20: 'rgba(227, 232, 17, 0.20)',
    },
    base: {
        white: "#FFFFFF",
        black: "#000000",
    },
    grey: {
        100: "#E8E8E8",
        200: "#C4C5C5",
        300: "#ADADAD",
        400: "#959696",
        500: "#727373",
        600: "#5B5C5C",
        700: "#434545",
        800: "#252626",
        900: "#1D1F1F",
        950: "#141616",
    },
    primary: {
        500: "#8CF222",
        600: "#38FD33",
    },
    green: {
        100: "#EBFFEB",
        200: "#E1FFE0",
        300: "#C1FEC0",
        400: "#38FD33",
        500: "#32E42E",
        600: "#2DCA29",
        700: "#2ABE26",
        800: "#22981F",
        900: "#197217",
        950: "#145912",
    },
    red: {
        100: "#FEECEB",
        200: "#FDE3E1",
        300: "#FAC5C1",
        400: "#F04438",
        500: "#D83D32",
        600: "#C0362D",
        700: "#B4332A",
        800: "#902922",
        900: "#6C1F19",
        950: "#541814",
    },
    secondary: {
        100: "#F6F8B5",
        200: "#F2F492",
        300: "#ECF060",
        400: "#E9ED41",
        500: "#E3E811",
        600: "#CFD30F",
        700: "#A1A50C",
        800: "#7D8009",
        900: "#5F6107",
        950: "#3C3D00",
    },
    accent: "#C0ED18",
};

export const gradientColors = {
    primarySecondary: `linear-gradient(90deg, ${colors.primary["600"]} 0%, ${colors.secondary["500"]} 100%)`,
    primaryTransparentBlack: `linear-gradient(90deg, ${colors.primary["500"]} 0%, ${colors.base.black}00 100%)`,
    secondaryTransparentBlack: `linear-gradient(90deg, ${colors.base.black}00 0%, ${colors.secondary["500"]} 100%)`,
    primaryTransparent: `linear-gradient(90deg, ${colors.primary["500"]} 3%, ${colors.primary["500"]}E0 15%, ${colors.primary["500"]}00 100%)`,
    secondaryTransparent: `linear-gradient(90deg, ${colors.secondary["500"]} 0%, ${colors.secondary["500"]}00 100%)`,
    AccentTransparent: `linear-gradient(90deg, ${colors.accent} 0%, ${colors.accent}00 100%)`,
};